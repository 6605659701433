import { apiAxios } from "../axios";

import Europess from "../wangZhi";
let Europe = Europess + "/client/";

export default {
  // 获取店铺全部商品
  getShopAllGoods: (data) => apiAxios("post", Europe + "getShopAllGoods", data),
  // 欧美站图片搜索商品
  imageSearchProduct: (data) =>
    apiAxios("post", Europe + "imageSearchProduct", data),
  // 欧美站关键词搜索商品
  EuropeKeywordSearchProduct: (data) =>
    apiAxios("post", Europe + "keywordSearchProduct", data),
  // 图搜获取图片id
  uploadImagePic: (data) =>
    apiAxios("post", "https://api-landingpage.rakumart.cn/api/cross", data),
  // 图片搜索第二版
  imageSearchProductV2: (data) =>
    apiAxios("post", Europe + "imageSearchProductV2", data),
  // 商品详情页面信息
  goodsParticulars: (data) =>
    apiAxios("post", Europe + "goodsParticulars", data),
  // 商品详情页面信息V2
  goodsParticularsV2: (data) =>
    apiAxios("post", Europe + "goodsParticularsV2", data),
  // 获取店铺推荐商品
  getShopRecommendedGoods: (data) =>
    apiAxios("post", Europe + "getShopRecommendedGoods", data),
  // 订单预览(提出前)
  orderPreview: (data) => apiAxios("post", Europe + "orderPreview", data),
  // 订单金额计算
  calculateOrder: (data) => apiAxios("post", Europe + "calculateOrder", data),
  // 商品附加服务列表(option)
  optionList: (data) => apiAxios("post", Europe + "optionList", data),
  // 提出订单&&临时保存
  orderCreate: (data) => apiAxios("post", Europe + "orderCreate", data),
  // 订单列表
  orderList: (data) => apiAxios("post", Europe + "orderList", data),
  // 订单列表V3
  orderListV3: (data) => apiAxios("post", Europe + "orderListV3", data),
  // 获取各状态订单条目数V2
  ordersSumV2: (data) => apiAxios("post", Europe + "ordersSumV2", data),
  // 临时订单硬删除
  orderTampDelete: (data) => apiAxios("post", Europe + "orderTampDelete", data),
  // 正式订单软删除
  orderRecycling: (data) => apiAxios("post", Europe + "orderRecycling", data),
  // 订单详情
  orderDetail: (data) => apiAxios("post", Europe + "orderDetail", data),
  // 订单预览追加商品数据展示
  orderAddGoodsList: (data) =>
    apiAxios("post", Europe + "orderAddGoodsList", data),
  // 订单商品单番金额明细
  orderOneGoodsFee: (data) =>
    apiAxios("post", Europe + "orderOneGoodsFee", data),
  //查询某商品是否已收藏
  favoriteGoodsCheckIn: (data) =>
    apiAxios("post", Europe + "favorite.goodsCheckIn", data),
  // 查询某店铺是否已收藏
  favoriteShopCheckIn: (data) =>
    apiAxios("post", Europe + "favorite.shopCheckIn", data),
  // 正式订单编辑(目前仅支持待付款订单)
  orderEdit: (data) => apiAxios("post", Europe + "orderEdit", data),
  // 订单支付界面数据
  orderPayment: (data) => apiAxios("post", Europe + "orderPayment", data),
  // 余额支付订单
  orderBalancePayment: (data) =>
    apiAxios("post", Europe + "orderBalancePayment", data),
  // 银行汇款支付订单
  orderBankPayment: (data) =>
    apiAxios("post", Europe + "orderBankPayment", data),
  // 获取国际物流支持的运输方式
  internationalLogisticsTransportation: (data) =>
    apiAxios("post", Europe + "internationalLogistics.transportation", data),
  // 配送单支付界面数据
  storagePOrderPayment: (data) =>
    apiAxios("post", Europe + "storage.pOrderPayment", data),
  // 余额支付配送单
  storagePOrderBalancePayment: (data) =>
    apiAxios("post", Europe + "storage.pOrderBalancePayment", data),
  // 银行汇款支付配送单
  storagePOrderBankPayment: (data) =>
    apiAxios("post", Europe + "storage.pOrderBankPayment", data),
  // 订单详情下载,形式发票下载
  downloadOrderDetail: (data) =>
    apiAxios("post", Europe + "download.orderDetail", data),
  // 物流运费模拟计算接口
  orderLogisticsPriceTest: (data) =>
    apiAxios(
      "post",
      Europess + "/api/common/" + "order.logisticsPriceTest",
      data
    ),
  // 生成信用卡在线支付Api
  createPayApi: (data) =>
    apiAxios("post", Europess + "/client/createPayApi", data),
  // 添加独立地址
  addressStore: (data) =>
    apiAxios("post", Europess + "/client/user.addressStore", data),
  // 修改订单地址
  updateOrderAddressId: (data) =>
    apiAxios("post", Europess + "/client/user.updateOrderAddressId", data),
  // 替换商品
  replaceGoods: (data) => apiAxios("post", Europe + "replaceGoods", data),
  // 修改订单物流方式
  updateOrderLogistics: (data) =>
    apiAxios("post", Europe + "updateOrderLogistics", data),
  //获取老版1688商品店铺名
  saleShopName: (data) =>
    apiAxios("post", Europess + "/client/saleShopName", data),
  //多语言搜索导航
  keywordSNQuery: (data) =>
    apiAxios("post", Europess + "/client/keywordSNQuery", data),

  //店铺全部商品搜索
  sellerOfferList: (data) =>
    apiAxios("post", Europess + "/client/sellerOfferList", data),
};
