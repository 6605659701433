import Vue from "vue";
import VueRouter from "vue-router";
import fun from "@/utlis/CommonlyUsedFunctions.js";
import store from "../store/index.js";
import apis from "../api/apis";
import fanyi from "../utlis/language";
import centroDeAyuda from "../views/footList/centro-de-ayuda/unitl/routePathList";

import { Message, MessageBox } from "element-ui";
Vue.use(VueRouter);

const routes = [
  /* 用户页面*/
  {
    path: "/user",
    name: "user",
    component: () => import("../views/UserCenter/index.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      // 用户-首页
      {
        path: "/user/index",
        name: "userIndex",
        component: () =>
          import("../views/UserCenter/views/UserIndex/index.vue"),
        meta: {
          tagTitle: "Panel de control",
        },
      },
      // 用户-订单页面
      {
        path: "/user/order",
        name: "userOrder",
        component: () =>
          import("../views/UserCenter/views/userOrder/index.vue"),
        meta: {
          tagTitle: "Todos los pedidos",
        },
      },

      // 用户-问题产品
      {
        path: "/user/issueOrder",
        name: "issueOrder",
        component: () =>
          import("../views/UserCenter/views/issueOrder/index.vue"),
        meta: {
          tagTitle: "Incidencias",
        },
      },

      // 用户-运输中页面
      {
        path: "/user/wuliuStatus",
        name: "wuliuStatus",

        component: () =>
          import("../views/UserCenter/views/wuliuStatus/index.vue"),
        meta: {
          tagTitle: "Logística y envíos",
        },
      },
      // 用户-仓库页面
      {
        path: "/user/warehouse",
        name: "warehouse",
        component: () =>
          import("../views/UserCenter/views/warehouse/index.vue"),
        meta: {
          tagTitle: "Almacén",
        },
      },

      // 用户-收藏夹产品页面
      {
        path: "/user/collection",
        name: "collection",
        component: () =>
          import("../views/UserCenter/views/collection/index.vue"),
        meta: {
          tagTitle: "Productos favoritos",
        },
      },
      // 用户-收藏夹商店页面
      {
        path: "/user/favoritec",
        name: "favoritec",

        component: () =>
          import("../views/UserCenter/views/favoritec/index.vue"),
        meta: {
          tagTitle: "Tiendas favoritas",
        },
      },
      //用户-发票历史
      {
        path: "/user/invoiceHistory",
        name: "invoiceHistory",

        component: () =>
          import("../views/UserCenter/views/invoiceHistory/index.vue"),
      },
      //用户-发票账单数据
      {
        path: "/user/invoiceBillingData",
        name: "invoiceBillingData",

        component: () =>
          import("../views/UserCenter/views/invoiceBillingData/index.vue"),
      },
      // 用户-收藏夹商品库列表页面
      {
        path: "/user/commodity",
        name: "commodity",
        component: () =>
          import("../views/UserCenter/views/commodity/index.vue"),
        meta: {
          tagTitle: "Biblioteca de productos",
        },
      },
      // 用户-登录信息页面
      {
        path: "/user/userDetail",
        name: "userDetail",
        component: () =>
          import("../views/UserCenter/views/userDetail/index.vue"),
        meta: {
          tagTitle: "Modificar datos personales",
        },
      },
      // 用户-编辑地址页面
      {
        path: "/user/deliverySite",
        name: "deliverySite",

        component: () =>
          import("../views/UserCenter/views/deliverySite/index.vue"),
        meta: {
          tagTitle: "Direcciones de envío",
        },
      },

      // 用户-更改密码页面
      {
        path: "/user/changepassworld",
        name: "changepassworld",
        component: () =>
          import("../views/UserCenter/views/changepassworld/index.vue"),
        meta: {
          tagTitle: "Contraseña y verificación",
        },
      },
      // 用户-优惠券
      {
        path: "/user/coupon",
        name: "coupon",
        component: () => import("../views/UserCenter/views/coupon/index.vue"),
        meta: {
          tagTitle: "Cupones",
        },
      },
      // 用户-国际物流
      {
        path: "/user/internationalLogistics",
        name: "internationalLogistics",

        component: () =>
          import("../views/UserCenter/views/internationalLogistics/idnex.vue"),
      },
      // 用户-通知
      {
        path: "/user/inform",
        name: "inform",

        component: () => import("../views/UserCenter/views/inform/idnex.vue"),
        meta: {
          tagTitle: "Notificaciones",
        },
      },
    ],
  },
  // 首页
  {
    path: "/",
    name: "homepage",
    meta: {
      tagTitle: "Rakumart - Comprar e Importar desde China Fácilmente",
      tagDescription:
        "Ahorra tiempo y costes al importar desde China. Accede al catálogo de miles de proveedores y disfruta de nuestra plataforma de importación todo en 1.",
      buDaiRakumart: true,
    },
    component: () => import("../views/homepage/index.vue"),
  },

  // cookie政策页面
  {
    path: "/politica-de-cookies",
    name: "politica-de-cookies",
    meta: {
      tagTitle: "Rakumart - Política de cookies",
      tagDescription: "Política de cookies de rakumart.es",
      buDaiRakumart: true,
    },
    component: () => import("../views/footList/politica-de-cookies.vue"),
  },
  // 隐私政策页面
  {
    path: "/politica-privacidad",
    name: "politica-privacidad",
    meta: {
      tagTitle: "Rakumart - Política de privacidad",
      tagDescription: "Política de privacidad de rakumart.es",
      buDaiRakumart: true,
    },
    component: () => import("../views/footList/politica-privacidad.vue"),
  },
  // 合同页面
  {
    path: "/condiciones-generales-de-contratacion",
    name: "condiciones-generales-de-contratacion",
    meta: {
      tagTitle: "Rakumart - Condiciones generales",
      tagDescription: "Condiciones generales de rakumart.es",
      buDaiRakumart: true,
    },
    component: () =>
      import("../views/footList/condiciones-generales-de-contratacion.vue"),
  },
  // 法律条款
  {
    path: "/aviso-legal",
    name: "aviso-legal",
    meta: {
      tagTitle: "Rakumart - Aviso legal",
      buDaiRakumart: true,
      tagDescription: "Aviso legal de rakumart.es",
    },
    component: () => import("../views/footList/aviso-legal.vue"),
  },
  // 用户-问题产品详情
  {
    path: "/issueOrderDetail",
    name: "issueOrderDetail",

    component: () =>
      import("../views/UserCenter/views/issueOrder/views/issueOrderDetail.vue"),
    meta: {
      requireAuth: true,
    },
  },

  // 订单页面
  {
    path: "/OrderDetails",
    name: "OrderDetails",
    component: () =>
      import("../views/UserCenter/views/userOrder/views/OrderDetails.vue"),
    meta: {
      requireAuth: true,
      tagTitle: "Pedidos de compra pendientes",
    },
  },
  // 新商品详情
  {
    path: "/ProductDetails",
    name: "ProductDetails",
    meta: {
      tagDescription:
        "Junsheng mochila al aire libre bolsa de ventilador militar mochila de viaje X7 pez espada bolsa táctica montañismo bolsa de camuflaje 40 litros",
    },
    component: () => import("../views/newProductDetail/index.vue"),
  },
  // // 旧商品详情
  // {
  //     path: '/oProductDetails',
  //     name: 'oProductDetails',
  //     meta: {
  //         tagDescription: 'Junsheng mochila al aire libre bolsa de ventilador militar mochila de viaje X7 pez espada bolsa táctica montañismo bolsa de camuflaje 40 litros'
  //     },
  //     component: () => import('../views/order/ProductDetails.vue')
  // },
  // 店铺商品
  {
    path: "/shopGoods",
    name: "shopGoods",
    meta: {
      keepAlive: true,
    },
    component: () => import("../views/productDetail/shopGoods.vue"),
  },
  // 忘记密码页面
  {
    path: "/ForgetThePassword",
    name: "ForgetThePassword",
    component: () => import("../views/login/ForgetThePassword.vue"),
  },
  /* 注册页面*/
  {
    path: "/register",
    name: "register",
    meta: {
      tagTitle: "Register",
    },
    component: () => import("../views/register/index.vue"),
  },
  /* 注册成功*/
  {
    path: "/register-success",
    name: "register-success",
    component: () => import("../views/register/index.vue"),
  },

  /* 登录页面*/
  {
    path: "/login",
    name: "login",
    meta: {
      tagTitle: "Login",
    },
    component: () => import("../views/login/login.vue"),
  },
  /* 购物车列表*/
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/order/cart"),
    meta: {
      requireAuth: true,
      tagTitle: "Carrito",
    },
  },
  /* 订单支付*/
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/payment/payment"),
    meta: {
      tagTitle: "Pantalla de pago Rakumart",
      buDaiRakumart: true,
      requireAuth: true,
    },
  },
  // /* 商品搜索页*/
  // {
  //   path: "/CommoditySearch",
  //   name: "CommoditySearch",
  //   component: () => import("../views/order/CommoditySearch"),
  // },

  /* 商品搜索页*/
  {
    path: "/CommoditySearch",
    name: "CommoditySearch",
    component: () => import("../views/CommoditySearch/index.vue"),
    meta: {
      keepAlive: true,
    },
  },

  /* 物流与运输*/
  {
    path: "/logistica",
    name: "logistica",
    component: () =>
      import(/* webpackChunkName: "foot" */ "../views/footList/logistica"),
    meta: {
      tagTitle: "Logística y envíos",
      tagDescription:
        "Aprovecha todos los recursos que Rakumart pone a tu disposición para cambiar por completo la manera de comprar e importar desde China.",
    },
  },
  /* option*/
  {
    path: "/servicios-adicionales",
    name: "option",

    component: () =>
      import(
        /* webpackChunkName: "foot" */ "../views/footList/servicios-adicionales"
      ),
    meta: {
      tagTitle: "Servicios adicionales",
      tagDescription:
        "En Rakumart también disfrutarás de servicios adicionales como el etiquetado FBA, la fotografía de producto, la personalización ODM, traducción, etc.",
    },
  },
  /* 会员等级*/
  {
    path: "/comisiones-rakumart",
    name: "comisiones-rakumart",
    meta: {
      tagTitle: "Comisiones",
    },
    component: () =>
      import(
        /* webpackChunkName: "foot" */ "../views/footList/comisiones-rakumart"
      ),
  },
  /* 公司推荐*/
  {
    path: "/quienes-somos",
    name: "quienes-somos",
    meta: {
      tagTitle: "¿Quiénes somos?",
      tagDescription:
        "Simplifica tus compras e importaciones desde China y optimiza todos los costes. Nosotros ",
    },
    component: () =>
      import(/* webpackChunkName: "foot" */ "../views/footList/quienes-somos"),
  },
  /*配送单详情*/
  {
    path: "/deliveryDetails",
    name: "deliveryDetails",
    component: () =>
      import(
        "../views/UserCenter/views/deliveryList/views/DeliveryDetails/index.vue"
      ),
    meta: {
      requireAuth: true,
    },
  },
  /*制作的新配送单详情(备份)*/
  {
    path: "/deliveryDetails2",
    name: "deliveryDetails2",
    component: () =>
      import(
        "../views/UserCenter/views/deliveryList/views/NewDeliveryDetails/index.vue"
      ),
    meta: {
      requireAuth: true,
    },
  },
  // 用户指南
  {
    path: "/guia-de-usuario",
    name: "userGuide",
    meta: {
      tagTitle: "Guía rápida de usuario",
      tagDescription:
        "Descubre cómo utilizar la plataforma de Rakumart para realizar importaciones desde China fácilmente.",
    },
    component: () => import("../views/footList/userGuide.vue"),
  },
  // 我们的优势
  {
    path: "/ventajas",
    name: "ventajas",
    component: () => import("../views/footList/ventajas.vue"),
    meta: {
      tagTitle: "Rakumart - Nuestras ventajas harán tu negocio más rentable",
      buDaiRakumart: true,
      tagDescription:
        "Rakumart proporciona una gran ventaja competitiva a nuestros clientes al simplificar las compras e importaciones desde China y optimizar todos los costes.",
    },
  },
  // 问题中心答案列表页面(可以搜索到结果)
  {
    path: "/resultado-de-busqueda",
    name: "resultado-de-busqueda",
    component: () =>
      import("../views/footList/centro-de-ayuda/resultado-de-busqueda.vue"),
  },
  // 问题中心答案列表页面(没有搜索到结果)
  {
    path: "/resultado-busqueda-no-encontrado",
    name: "resultado-busqueda-no-encontrado",
    component: () =>
      import(
        "../views/footList/centro-de-ayuda/resultado-busqueda-no-encontrado.vue"
      ),
  },
  // 对客户的关注
  {
    path: "/atencion-al-cliente",
    name: "Atención al cliente",
    component: () => import("../views/footList/atencion-al-cliente.vue"),
  },
  // 邀请注册
  {
    path: "/invitingRegistration",
    name: "invitingRegistration",
    component: () => import("../views/invitingRegistration/index.vue"),
  },
  // 调查问卷
  {
    path: "/surveyQuestionnaire",
    name: "surveyQuestionnaire",
    component: () => import("../views/surveyQuestionnaire/index.vue"),
    meta: {
      requireAuth: true,
    },
  },
  // 国际物流
  {
    path: "/internationalLogistics",
    name: "internationalLogisticsStaticPage",
    component: () => import("../views/internationalLogistics/index.vue"),
    meta: {},
  },
];
// js获取url路由
let nowRouter =
  window.location.href.indexOf("?") == -1
    ? window.location.href.replace(window.location.origin, "")
    : window.location.href
        .replace(window.location.origin, "")
        .substr(
          0,
          window.location.href.replace(window.location.origin, "").indexOf("?")
        );
// 匹配路由是否是qa里的
let rouadd = centroDeAyuda.find((item) => {
  return nowRouter == "/" + item || nowRouter == "/" + item.router;
});
// 如果是就添加路由
if (rouadd) {
  if (rouadd.router) {
    routes.push({
      path: "/" + rouadd.router,
      name: rouadd.router,
      meta: {
        tagTitle: rouadd.title,
      },
      component: () =>
        import(
          /* webpackChunkName: "centroDeAyudaRouterArr" */ "../views/footList/centro-de-ayuda/centro-de-ayuda.vue"
        ),
    });
  } else {
    routes.push({
      path: "/" + rouadd,
      name: rouadd,
      component: () =>
        import(
          /* webpackChunkName: "centroDeAyudaRouterArr" */ "../views/footList/centro-de-ayuda/centro-de-ayuda.vue"
        ),
    });
  }
}

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/",
});
// 写地图列表时user下的路由不要进去 /CommoditySearch /deliveryDetails /issueOrderDetail /OrderDetails /ProductDetails /shopGoods /register-success /payment /CommoditySearch /deliveryDetails /resultado-de-busqueda /cart

// // // 循环输出所有路由
// let logPath = () => {
//     let data = []
//     routes.forEach(element1 => {
//         data.push(element1.path)
//         if (element1.children && element1.children.length != 0) {
//             element1.children.forEach(element2 => {
//                 data.push(element2.path)
//             });
//         }
//     });
//     return data
// }
// let pcddd = logPath()
// console.log([...pcddd, ...centroDeAyuda]);

let urlOrigin = location.origin;
let openLogin = function(to) {
  store.commit("getactivePage", to.fullPath);
  // store.commit('getshowLoginDialog', true)//打开登录弹窗
  MessageBox.confirm(fanyi("是否前往登录?"), fanyi("提示"), {
    confirmButtonText: fanyi("登录"),
    cancelButtonText: fanyi("取消"),
  })
    .then(() => {
      location.href = urlOrigin + "/login";
    })
    .catch(() => {
      location.href = urlOrigin + "/";
    });
};

router.beforeEach((to, from, next) => {
  setOnlyName(to);
  changetag(to);
  changeDescription(to);
  if (to.query.backendPersonnel) {
    // 后台跳转前台页面操作
    sessionStorage.setItem("backendPersonnel", to.query.backendPersonnel);
    localStorage.setItem(
      "backgroundOperationID",
      to.query.backgroundOperationID
    );

    //后台直接登录客户页面使用
    localStorage.setItem("user_token", to.query.user_token);

    Vue.prototype.$api.EuropegetUserInfo().then((res) => {
      //需要登录并且已经登录了token正常的情况下跳转并更新用户信息
      store.commit("userData", res.data); // 用户信息存储
      sessionStorage.setItem("user_id", res.data.user_id);
      let query = to.query;
      delete query.backendPersonnel;
      delete query.backgroundOperationID;
      delete query.user_token;
      next({
        path: to.path,
        query: query,
      });
    });
  } else if (
    to.matched.some((record) => record.meta.requireAuth) &&
    !(["issueOrderDetail"].includes(to.name) && to.query.token)
  ) {
    // 判断该路由是否需要登录权限
    if (localStorage.getItem("user_token")) {
      //判斷是否拥有token
      Vue.prototype.$api.EuropegetUserInfo().then((res) => {
        // 登录失效
        if (res.code != 0) {
          // 需要登录并且已经登录但是token失效了就清空数据并打开登录弹窗
          store.commit("userData", null);

          localStorage.removeItem("backgroundOperationID");
          sessionStorage.removeItem("backendPersonnel");
          localStorage.removeItem("user_token");
          sessionStorage.removeItem("user_id");

          // 有需要验证的用户中心
          openLogin(to);
          Message.error(fanyi(res.msg));
          return;
        } else {
          //需要登录并且已经登录了token正常的情况下跳转并更新用户信息
          store.commit("userData", res.data); // 用户信息存储
          next();
        }
      });
      saveUserRequest("https://rakumart.es" + to.fullPath);
    } else {
      // 需要登录但是没有登录的情况下打开弹窗
      openLogin(to);
    }
  } else {
    // 不需要登录的路由直接跳转
    saveUserRequest("https://rakumart.es" + to.fullPath);
    next();
  }
  if (!to.query.backendPersonnel) {
    // 判断后台操作的id和本地的token是否一致
    if (sessionStorage.getItem("backendPersonnel")) {
      if (
        localStorage.getItem("backgroundOperationID") &&
        sessionStorage.getItem("user_id") &&
        localStorage.getItem("backgroundOperationID") !=
          sessionStorage.getItem("user_id")
      ) {
        fun.exitOperation();
      }
    }
  }
  if (localStorage.getItem("backgroundOperationID")) {
    if (!sessionStorage.getItem("backendPersonnel")) {
      MessageBox.confirm(
        "当前浏览器有操作客户账号,点击确定退出所有账号,点击取消关闭此页面",
        "提示",
        {
          confirmButtonText: "退出登录",
          cancelButtonText: "取消",
          cancel: () => {
            console.log("quxiao");
            window.close();
          },
        }
      )
        .then(() => {
          localStorage.removeItem("user_token");
          localStorage.removeItem("backgroundOperationID");
          location.reload();
        })
        .catch(() => {
          window.close();
        });
    }
  }
});
// 获取商品名字
let productDetails = (to) => {
  console.log(to);
  apis
    .goodsParticulars({
      goods_id: to.query.goods_id,
      shop_type: 1688,
    })
    .then((res) => {
      if (res.code != 0 || !res.data.goodsInfo) return;
      document.title = "Rakumart-" + res.data.title.substr(0, 50);
    });
};
// 设置页面标识符,因为谷歌在爬取商品详情页面时爬取不到接口返回数据,所以需要加一个唯一的页面标示符以区分页面,否则会提示重定向
function setOnlyName(to) {
  document.getElementsByName("onlyName")[0].innerText = to.fullPath;
}
// 记录用户访问页面次数
function saveUserRequest(url) {
  apis
    .saveUserRequest({
      url: url,
    })
    .then((res) => {
      ////console.log('事件名',res)
      //if(res.data.code !=0) return this.$message.error(res.data.message)
      //接下来的操作
    });
}

function changetag(to) {
  console.log("执行", to.meta.tagTitle);

  if (to.name == "ProductDetails") {
    return;
  }
  // // 假如是搜索页就设置'Rakumart-' + 路由名字 + 搜索关键词
  if (to.name == "CommoditySearch") {
    var reg = /[a-z]/; //判断字符串是否为数字 ，判断正整数用/^[1-9]+[0-9]*]*$/

    if (to.query.keyword) {
      return store.commit(
        "changeTagTitle",
        `Rakumart - Resultados de la búsqueda`
      );
    } else {
      return store.commit(
        "changeTagTitle",
        `Rakumart - Resultados de búsqueda de imágenes`
      );
    }
  }

  if (to.meta.buDaiRakumart) {
    return store.commit("changeTagTitle", to.meta.tagTitle);
  }

  // 假如设置了meta就使用meta
  if (to.meta.tagTitle) {
    store.commit("changeTagTitle", "Rakumart - " + to.meta.tagTitle);
  } else {
    store.commit("changeTagTitle", "Rakumart - " + to.name);
  }
  // 更改标签
  if (to.name == "shopGoods") {
    document.title = "Rakumart - " + to.query.shopName;
  } else {
    document.title = store.state.TagTitle;
  }
}

function changeDescription(to) {
  document.getElementsByName("canonicalLink")[0].href =
    "https://www.rakumart.es" + to.fullPath;

  // 假如设置了meta就使用meta
  if (to.meta.tagDescription) {
    store.commit("changeTagDescription", to.meta.tagDescription);
  } else {
    store.commit("changeTagDescription", "");
  }
  //console.log(store.state.TagDescription);
}
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
export default router;
