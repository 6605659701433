import store from "./../store/index";
let langs = [
  {
    key: "用户名",
    value: "Usuario",
  },
  {
    key: "密码",
    value: "Contraseña",
  },
  {
    key: "忘记密码",
    value: "¿Has olvidado la contraseña?",
  },
  {
    key: "保持登录状态",
    value: "Mantener sesión iniciada",
  },
  {
    key: "咨询",
    value: "Contactar",
  },
  {
    key: "用户",
    value: "Usuario",
  },
  {
    key: "电子邮件",
    value: "Correo electrónico",
  },
  {
    key: "手机",
    value: "Teléfono móvil",
  },
  {
    key: "加入商品库",
    value: "Añadir a la biblioteca de productos",
  },
  {
    key: "请输入邮箱/手机号",
    value: "Dirección de e-mail o número de teléfono",
  },
  {
    key: "记住密码",
    value: "Guardar contraseña",
  },
  {
    key: "新用户?",
    value: "¿Nuevo usuario?",
  },
  {
    key: "新会员注册",
    value: "¿Nuevo usuario? Regístrate",
  },
  {
    key: "请输入账号",
    value: "Nombre de usuario",
  },
  {
    key: "请输入密码",
    value: "Contraseña",
  },
  {
    key: "账户名或者密码输入错误",
    value: "Nombre de cuenta o contraseña incorrecta",
  },
  {
    key: "请输入你的账户名称",
    value: "Nombre de cuenta",
  },
  {
    key: "手机号码",
    value: "Teléfono móvil",
  },
  {
    key: "电子邮箱",
    value: "Correo electrónico",
  },
  {
    key: "其他认证方式",
    value: "Otros métodos de indentificación",
  },
  {
    key: "请输入手机号",
    value: "Número de teléfono móvil",
  },
  {
    key: "验证码获取",
    value: "Obtener el código de verificación",
  },
  {
    key: "必填参数缺失",
    value: "Falta información",
  },
  {
    key: "城市名",
    value: "Nombre de ciudad",
  },
  {
    key: "国家名",
    value: "Nombre del país",
  },
  {
    key: "发送",
    value: "Enviar",
  },
  {
    key: "事件列表",
    value: "Listado de incidencias",
  },
  {
    key: "请在60秒后重新发送验证码",
    value: "Vuelve a enviar el código de verificación después de 60 segundos",
  },
  {
    key: "填写个人信息",
    value: "Información personal",
  },
  {
    key: "个人信息确认",
    value: "Confirmación",
  },
  {
    key: "完成注册",
    value: "Registro completo",
  },
  {
    key: "账户注册（需要信息）",
    value: "Registro de la cuenta (información requerida)",
  },

  {
    key: "登录名",
    value: "Nombre de usuario",
  },
  {
    key: "姓氏",
    value: "Apellidos",
  },
  {
    key: "请输入姓氏",
    value: "Introduce tus apellidos",
  },
  {
    key: "名字",
    value: "Nombre",
  },
  {
    key: "请输入名字",
    value: "Introduce tu nombre",
  },
  {
    key: "密码",
    value: "Contraseña",
  },

  {
    key: "两次输入密码不一致!",
    value: "¡Dos entradas de contraseña inconsistentes!",
  },
  {
    key: "电子邮件",
    value: "Correo electrónico",
  },
  {
    key: "请输入正确的邮箱地址",
    value: "Introduce una dirección de e-mail válida",
  },
  {
    key: "前缀",
    value: "Prefijo",
  },

  {
    key: "输入您的电话号码",
    value: "Introduce tu teléfono",
  },
  {
    key: "验证码",
    value: "Código de verificación",
  },

  {
    key: "请输入手机号",
    value: "Número de teléfono móvil",
  },
  {
    key: "手机号为空",
    value: "El número de teléfono móvil está vacío",
  },
  {
    key: "NIF / DNI",
    value: "NIF/DNI",
  },
  {
    key: "请输入NIF",
    value: "Introduce NIF",
  },
  {
    key: "请输入NIF / DNI",
    value: "Introduce NIF/DNI",
  },
  {
    key: "发送验证码",
    value: "Envíar código SMS",
  },
  {
    key: "请在60秒后重新发送验证码",
    value: "Vuelve a enviar el código de verificación después de 60 segundos",
  },
  {
    key: "RAKUMART的用户协议和隐私政策",
    value:
      "He leído y acepto el acuerdo de usuario y política de privacidad de Rakumart.",
  },
  {
    key: "同意以上协议，进入下一步",
    value: "Siguiente paso",
  },
  {
    key: "如何注册/登录？",
    value: "¿Cómo me registro/inicio sesión?",
  },
  {
    key: "密码请控制在6位以上16位以内",
    value: "La contraseña debe tener entre 6 y 16 caracteres",
  },
  {
    key: "手机号为空",
    value: "El número de teléfono móvil está vacío",
  },

  {
    key: "验证码错误",
    value: "Error en el código de verificación",
  },
  {
    key: "注册完成",
    value: "Registro completo",
  },
  {
    key: "注册成功完成",
    value: "Registro completado con éxito",
  },
  {
    key: "正在进行的订单",
    value: "Pedidos en curso",
  },
  {
    key: "去登录",
    value: "Iniciar sesión",
  },
  {
    key: "关键词",
    value: "Palabra clave",
  },
  {
    key: "搜索结果有问题？",
    value: "¿Tienes problemas con los resultados de la búsqueda?",
  },

  {
    key: "没有找到商品，请换一张图片试试",
    value:
      "No se ha encontrado ningún artículo, por favor inténtalo con otra imagen",
  },
  {
    key: "图片上传中",
    value: "Cargando",
  },
  {
    key: "登录失效",
    value: "Debe iniciar sesión",
  },
  {
    key: "意见反馈",
    value: "Comentarios",
  },
  {
    key: "退出登录",
    value: "Cerrar sesión",
  },

  {
    key: "法律通知",
    value: "Aviso legal",
  },
  {
    key: "注册",
    value: "Registrarse",
  },
  {
    key: "购物车",
    value: "Carrito",
  },
  {
    key: "为您推荐",
    value: "Recomendaciones",
  },

  {
    key: "新手导航",
    value: "Guía del usuario",
  },
  {
    key: "常见问题",
    value: "Preguntas frecuentes",
  },
  {
    key: "我们是谁",
    value: "¿Quiénes somos?",
  },
  {
    key: "会员等级",
    value: "Comisión",
  },
  {
    key:
      "我们根据订单总额收取佣金。对于每个金额，都有特定百分比的佣金。请参阅下表，了解您的订单将对应多少百分比。",
    value:
      'Cobramos una comisión dependiendo del importe del pedido. Consulta la tabla siguiente para ver qué porcentaje corresponde a tu pedido."Se determinará la comisión dependiendo de la cantidad de transacciones que hayas generado. Consulte la tabla siguiente para obtener más detalles.\n\nPor ejemplo, si has enviado más de 50.000 euros, la tasa de comisión cambiará al 9% para los siguientes pedidos".',
  },
  {
    key: "手续费比例",
    value: "Comisión actual",
  },
  {
    key: "采购商品金额（欧元）",
    value: "Importe de la compra (€)",
  },
  {
    key: "国际运费说明",
    value: "Gastos de envío internacionales",
  },
  {
    key: "不含运费的产品总成本",
    value: "Coste total de los productos sin envío",
  },
  {
    key: "产品成本",
    value: "Coste en producto",
  },
  {
    key: "option说明界面",
    value: "Servicios adicionales",
  },
  {
    key: "中国物流",
    value: "Logística en China",
  },
  {
    key: "物流和运输",
    value: "Logísitca y envíos",
  },
  {
    key: "已驳回",
    value: "rechazado",
  },
  {
    key: "查看相似",
    value: "Ver similares",
  },
  {
    key: "所有类别",
    value: "Todas las categorías",
  },
  {
    key: "特色类别",
    value: "Categorías destacadas",
  },
  {
    key: "查看更多推荐",
    value: "Todas tus recomendaciones",
  },
  {
    key: "已售",
    value: "Vendido",
  },
  {
    key: "件",
    value: "Piezas",
  },
  {
    key: "会员服务",
    value: "Servicio de socio",
  },
  {
    key: "等待财务确认",
    value: "Confirmando el monto",
  },
  {
    key: "会员等级",
    value: "Clase de socio",
  },
  {
    key: "关于费用",
    value: "Sobre las tarifas",
  },
  {
    key: "首页",
    value: "Inicio",
  },
  {
    key: "加入购物车",
    value: "Añadir al carrito",
  },
  {
    key: "总计",
    value: "Total",
  },
  {
    key: "在库数",
    value: "Unidades disponibles",
  },

  {
    key: "购物车添加成功",
    value: "Añadido al carrito",
  },
  {
    key: "店铺信息",
    value: "Proveedor",
  },
  {
    key: "店铺人气商品",
    value: "Productos destacados",
  },
  {
    key: "收藏商品",
    value: "Productos favoritos",
  },
  {
    key: "收藏店铺",
    value: "Seguir proveedor",
  },
  {
    key: "进入店铺",
    value: "Entra en la tienda",
  },
  {
    key: "用户名",
    value: "Usuario",
  },
  {
    key: "密码",
    value: "Contraseña",
  },

  {
    key: "保持登录状态",
    value: "Mantener sesión iniciada",
  },
  {
    key: "咨询",
    value: "Contactar",
  },
  {
    key: "用户",
    value: "Usuario",
  },
  {
    key: "电子邮件",
    value: "Correo electrónico",
  },

  {
    key: "请输入邮箱/手机号",
    value: "Dirección de e-mail o número de teléfono",
  },
  {
    key: "记住密码",
    value: "Guardar contraseña",
  },
  {
    key: "请输入账号",
    value: "Nombre de usuario",
  },
  {
    key: "请输入密码",
    value: "Contraseña",
  },
  {
    key: "账户名或者密码输入错误",
    value: "Nombre de cuenta o contraseña incorrecta",
  },
  {
    key: "请输入你的账户名称",
    value: "Nombre de cuenta",
  },

  {
    key: "电子邮箱",
    value: "Correo electrónico",
  },
  {
    key: "其他认证方式",
    value: "Otros métodos de indentificación",
  },
  {
    key: "请输入手机号",
    value: "Número de teléfono móvil",
  },
  {
    key: "验证码获取",
    value: "Obtener el código de verificación",
  },
  {
    key: "必填参数缺失",
    value: "Falta información",
  },
  {
    key: "请在60秒后重新发送验证码",
    value: "Vuelve a enviar el código de verificación después de 60 segundos",
  },
  {
    key: "填写个人信息",
    value: "Completa tus datos personales",
  },
  {
    key: "个人信息确认",
    value: "Confirma tus datos personales",
  },
  {
    key: "完成注册",
    value: "Completa tu registro",
  },
  {
    key: "账户注册（需要信息）",
    value: "Registro de la cuenta (información requerida)",
  },
  {
    key: "国家",
    value: "País",
  },
  {
    key: "登录名",
    value: "Nombre de usuario",
  },
  {
    key: "姓氏",
    value: "Apellidos",
  },
  {
    key: "请输入姓氏",
    value: "Introduce tus apellidos",
  },
  {
    key: "名字",
    value: "Nombre",
  },
  {
    key: "请输入名字",
    value: "Introduce tu nombre",
  },
  {
    key: "密码",
    value: "Contraseña",
  },
  {
    key: "密码确认",
    value: "Confirma la contraseña",
  },
  {
    key: "两次输入密码不一致!",
    value: "¡Dos entradas de contraseña inconsistentes!",
  },
  {
    key: "电子邮件",
    value: "Correo electrónico",
  },
  {
    key: "请输入正确的邮箱地址",
    value: "Introduceunadireccióndee-mailválida",
  },

  {
    key: "验证码",
    value: "Código de verificación",
  },
  {
    key: "输入验证码",
    value: "Introduce el código de verificación",
  },
  {
    key: "获得代码",
    value: "Obtener código",
  },
  {
    key: "请输入手机号",
    value: "Número de teléfono móvil",
  },
  {
    key: "手机号为空",
    value: "El número de teléfono móvil está vacío",
  },
  {
    key: "NIF / DNI",
    value: "NIF/DNI",
  },
  {
    key: "请输入NIF / DNI",
    value: "Introduce NIF/DNI",
  },
  {
    key: "发送验证码",
    value: "Obtener el código de verificación",
  },
  {
    key: "请在60秒后重新发送验证码",
    value: "Vuelve a enviar el código de verificación después de 60 segundos",
  },
  {
    key: "用户协议和隐私政策",
    value: "Política de privacidad",
  },
  {
    key: "同意以上协议，进入下一步",
    value: "Acepta el acuerdo de privacidad y continua",
  },
  {
    key: "如何注册/登录？",
    value: "¿Cómo me registro/inicio sesión?",
  },
  {
    key: "密码请控制在6位以上16位以内",
    value: "La contraseña debe tener entre 6 y 16 caracteres",
  },
  {
    key: "手机号为空",
    value: "El número de teléfono móvil está vacío",
  },
  {
    key: "已被使用",
    value: "Ya está en uso",
  },
  {
    key: "验证码错误",
    value: "Error en el código de verificación",
  },

  {
    key: "关键词",
    value: "Palabra clave",
  },
  {
    key: "搜索结果有问题？",
    value: "¿Tienes problemas con los resultados de la búsqueda?",
  },
  {
    key: "没有找到商品，请换一个关键词试试",
    value:
      "No se ha encontrado ningún artículo, por favor inténtalo con otra palabra clave o prueba a realizar la búsqueda por imagen.",
  },
  {
    key: "没有找到商品，请换一张图片试试",
    value:
      "No se ha encontrado ningún artículo, por favor inténtalo con otra imagen",
  },
  {
    key: "最佳选择",
    value: "MEJOR OPCIÓN",
  },
  {
    key: "此订单不提供运输",
    value: "Transporte no disponible para este pedido",
  },
  {
    key: "图片上传中",
    value: "Cargando",
  },
  {
    key: "登录失效",
    value: "Debe iniciar sesión",
  },
  {
    key: "个人中心",
    value: "Mi cuenta",
  },
  {
    key: "意见反馈",
    value: "Comentarios",
  },
  {
    key: "退出登录",
    value: "Cerrar sesión",
  },
  {
    key: "登录",
    value: "Iniciar sesión",
  },

  {
    key: "购物车",
    value: "Carrito",
  },
  {
    key: "为您推荐",
    value: "Recomendaciones",
  },
  {
    key: "请输入您想搜索的产品关键字",
    value: "Palabras clave, URL en 1688 o la imagen del producto que buscas",
  },
  {
    key: "新手导航",
    value: "Guía del usuario",
  },
  {
    key: "常见问题",
    value: "Preguntas frecuentes",
  },
  {
    key: "会员等级",
    value: "Comisión",
  },
  {
    key:
      "我们根据订单总额收取佣金。对于每个金额，都有特定百分比的佣金。请参阅下表，了解您的订单将对应多少百分比。",
    value:
      'Cobramos una comisión dependiendo del importe del pedido. Consulta la tabla siguiente para ver qué porcentaje corresponde a tu pedido."Se determinará la comisión dependiendo de la cantidad de transacciones que hayas generado. Consulte la tabla siguiente para obtener más detalles.\n\nPor ejemplo, si has enviado más de 50.000 euros, la tasa de comisión cambiará al 9% para los siguientes pedidos".',
  },
  {
    key: "采购商品金额（欧元）",
    value: "Importe de la compra (€)",
  },
  {
    key: "option说明界面",
    value: "Servicios adicionales",
  },
  {
    key: "查看相似",
    value: "Ver similares",
  },
  {
    key: "件",
    value: "Piezas",
  },
  {
    key: "会员服务",
    value: "Servicio de socio",
  },
  {
    key: "会员等级",
    value: "Clase de socio",
  },
  {
    key: "关于费用",
    value: "Sobre las tarifas",
  },
  {
    key: "附加服务说明",
    value: "Servicios adicionales",
  },
  {
    key: "首页",
    value: "Inicio",
  },
  {
    key: "加入购物车",
    value: "Añadir al carrito",
  },
  {
    key: "总计",
    value: "Total",
  },
  {
    key: "在库数",
    value: "Unidades disponibles",
  },

  {
    key: "购物车添加成功",
    value: "Añadido al carrito",
  },
  {
    key: "店铺人气商品",
    value: "Productos destacados",
  },
  {
    key: "收藏商品",
    value: "Productos favoritos",
  },
  {
    key: "进入店铺",
    value: "Entra en la tienda",
  },

  {
    key: "移出收藏夹",
    value: "Quitar de favoritos",
  },
  {
    key: "取消追踪",
    value: "Dejar de seguir",
  },
  {
    key: "商品详情",
    value: "Detalles del producto",
  },
  {
    key: "商品推荐",
    value: "Productos destacados",
  },
  {
    key: "购物车",
    value: "Carrito",
  },

  {
    key: "产品信息",
    value: "Información del producto",
  },
  {
    key: "属性",
    value: "Variantes",
  },
  {
    key: "数量",
    value: "Cantidad",
  },
  {
    key: "总价",
    value: "Precio",
  },
  {
    key: "选定的产品",
    value: "Productos seleccionados",
  },
  {
    key: "产品及运输",
    value: "Productos y envío",
  },
  {
    key: "只有产品",
    value: "Solo productos",
  },

  {
    key: "结算",
    value: "Empezar nuevo pedido",
  },

  {
    key: "将该商品将移出购物车",
    value: "Eliminar los artículos del carrito de compra",
  },
  {
    key: "确认",
    value: "Confirmar",
  },
  {
    key: "取消",
    value: "Cancelar",
  },
  {
    key: "是否将选中的商品移除购物车",
    value: "Eliminar los artículos del carrito de compra",
  },

  {
    key: "未选择操作的商品",
    value: "Elementos no seleccionados",
  },
  {
    key: "购物车商品删除成功",
    value: "Producto eliminado",
  },
  {
    key: "保存成功",
    value: "Guardado con éxito",
  },

  {
    key: "去购物",
    value: "Tienda",
  },
  {
    key: "人气商品",
    value: "Productos más vendidos",
  },
  {
    key: "更改头像",
    value: "Foto de perfil",
  },
  {
    key: "头像上传中",
    value: "Guardar foto de perfil",
  },
  {
    key: "张",
    value: "Disponibles",
  },
  {
    key: "用户名",
    value: "Nombre de usuario",
  },
  {
    key: "账户余额",
    value: "Saldo de la cuenta",
  },
  {
    key: "重新加载",
    value: "Recargar",
  },
  {
    key: "手续费",
    value: "Comisión",
  },
  {
    key: "委员会",
    value: "Comisiones",
  },
  {
    key: "未提出订单",
    value: "Pedido no realizadoPedido guardado",
  },
  {
    key: "上市申请",
    value: "Solicitar cotización",
  },
  {
    key: "待发货",
    value: "Pendiente de envío",
  },
  {
    key: "仓库",
    value: "Almacén",
  },
  {
    key: "运费报价中",
    value: "Cotización de los gastos de envío",
  },
  {
    key: "待定",
    value: "Pendiente",
  },
  {
    key: "运费待付款",
    value: "Pago de flete pendiente",
  },
  {
    key: "国际派送中",
    value: "Envío internacional",
  },
  {
    key: "我的券包",
    value: "Mis Cupones",
  },
  {
    key: "使用记录",
    value: "Historial",
  },
  {
    key: "请选择",
    value: "Selección",
  },
  {
    key: "请输入内容",
    value: "Escribe para buscar",
  },
  {
    key: "券编码",
    value: "Código",
  },
  {
    key: "券类别",
    value: "Categoría",
  },
  {
    key: "修改要求",
    value: "Modificar pedido",
  },
  {
    key: "额度/可使用次数",
    value: "Cantidad disponible",
  },
  {
    key: "有效日期",
    value: "Fecha de caducidad",
  },
  {
    key: "订单日期",
    value: "Fecha del pedido",
  },
  {
    key: "使用日期",
    value: "Fecha de uso",
  },
  {
    key: "使用状态",
    value: "Estado",
  },
  {
    key: "行动",
    value: "Operaciones",
  },
  {
    key: "订单内容",
    value: "Detalles del pedido",
  },
  {
    key: "订单号",
    value: "Número de pedido",
  },
  {
    key: "主页",
    value: "Página principal",
  },
  {
    key: "订单管理",
    value: "Gestión de pedidos",
  },
  {
    key: "运输中的订单",
    value: "Pedidos en tránsito",
  },
  {
    key: "问题产品",
    value: "Incidencias",
  },
  {
    key: "中国国内物流状态",
    value: "Estado de la logística nacional en China",
  },
  {
    key: "仓库",
    value: "Almacén",
  },
  {
    key: "附加的服务",
    value: "Serv. adicionales",
  },

  {
    key: "发货委托",
    value: "Envíos",
  },
  {
    key: "国际物流状态",
    value: "Seguimiento",
  },
  {
    key: "售后",
    value: "Post-venta",
  },
  {
    key: "账户余额管理",
    value: "Gestión del saldo de la cuenta",
  },
  {
    key: "充值",
    value: "Recargar",
  },
  {
    key: "支付账单",
    value: "Pagar",
  },
  {
    key: "收藏",
    value: "Añadir a favoritos",
  },
  {
    key: "产品",
    value: "Productos",
  },

  {
    key: "去购物",
    value: "Tienda",
  },
  {
    key: "人气商品",
    value: "Productos más vendidos",
  },
  {
    key: "更改头像",
    value: "Foto de perfil",
  },
  {
    key: "头像上传中",
    value: "Guardar foto de perfil",
  },
  {
    key: "张",
    value: "Disponibles",
  },
  {
    key: "用户名",
    value: "Nombre de usuario",
  },
  {
    key: "账户余额",
    value: "Saldo de la cuenta",
  },
  {
    key: "重新加载",
    value: "Recargar",
  },
  {
    key: "运输中",
    value: "En tránsito",
  },
  {
    key: "欧盟内部交付",
    value: "Entrega dentro de la UE",
  },
  {
    key: "仓库",
    value: "Almacén",
  },
  {
    key: "运费报价中",
    value: "Cotización de los gastos de envío",
  },
  {
    key: "运费待付款",
    value: "Pago de flete pendiente",
  },
  {
    key: "国际派送中",
    value: "Envío internacional",
  },
  {
    key: "我的券包",
    value: "Mis Cupones",
  },
  {
    key: "使用记录",
    value: "Historial",
  },
  {
    key: "请选择",
    value: "Selección",
  },
  {
    key: "请输入内容",
    value: "Escribe para buscar",
  },
  {
    key: "券编码",
    value: "Código",
  },
  {
    key: "券类别",
    value: "Categoría",
  },
  {
    key: "额度/可使用次数",
    value: "Cantidad disponible",
  },
  {
    key: "有效日期",
    value: "Fecha de caducidad",
  },
  {
    key: "使用日期",
    value: "Fecha de uso",
  },
  {
    key: "使用状态",
    value: "Estado",
  },
  {
    key: "订单内容",
    value: "Detalles del pedido",
  },
  {
    key: "订单号",
    value: "Número de pedido",
  },
  {
    key: "主页",
    value: "Página principal",
  },
  {
    key: "订单管理",
    value: "Gestión de pedidos",
  },
  {
    key: "中国国内物流状态",
    value: "Estado de la logística nacional en China",
  },
  {
    key: "仓库",
    value: "Almacén",
  },
  {
    key: "发货委托",
    value: "Envíos",
  },
  {
    key: "国际物流状态",
    value: "Seguimiento",
  },
  {
    key: "售后",
    value: "Post-venta",
  },
  {
    key: "账户余额管理",
    value: "Gestión del saldo de la cuenta",
  },
  {
    key: "充值",
    value: "Recargar",
  },
  {
    key: "支付账单",
    value: "Pagar",
  },
  {
    key: "收藏",
    value: "Añadir a favoritos",
  },
  {
    key: "产品",
    value: "Productos favoritos",
  },

  {
    key: "商品库列表",
    value: "Biblioteca de productos básicos",
  },
  {
    key: "个人信息管理",
    value: "Gestión de la información personal",
  },
  {
    key: "修改个人信息",
    value: "Modificar datos personales",
  },
  {
    key: "我的收货地址",
    value: "Mi dirección de envío",
  },
  {
    key: "支付页面收货地址",
    value: "Dirección de envío",
  },
  {
    key: "员工账户",
    value: "Cuenta personal",
  },
  {
    key: "更改密码",
    value: "Cambiar la contraseña",
  },
  {
    key: "预购订单",
    value: "Hacer un pedido",
  },
  {
    key: "全部订单",
    value: "Todas las categorías",
  },
  {
    key: "报价中",
    value: "Pendiente cotización",
  },
  {
    key: "等待报价",
    value: "Pendiente cotización",
  },
  {
    key: "等待付款",
    value: "Continuar con el pago",
  },
  {
    key: "正在购买",
    value: "Proceso de compra",
  },
  {
    key: "购买完成",
    value: "Compra completada",
  },
  {
    key: "已取消",
    value: "Cancelado",
  },
  {
    key: "已取消(订单状态)",
    value: "Cancelado",
  },
  {
    key: "请输入关键词搜索",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "开始日期",
    value: "Fecha de inicio",
  },
  {
    key: "至",
    value: "Para",
  },
  {
    key: "结束日期",
    value: "Fecha de finalización",
  },
  {
    key: "搜索",
    value: "Buscar",
  },

  {
    key: "导出excel",
    value: "Exportar en excel",
  },
  {
    key: "检品中",
    value: "Control y manipulación",
  },
  {
    key: "检品中订单",
    value: "Control de calidad y manipulación",
  },
  {
    key: "上架完了订单",
    value: "Mercancia preparada en almacén",
  },
  {
    key: "在RAKUMART仓库中",
    value: "Almacén Rakumart",
  },
  {
    key: "订单状态",
    value: "Estado del pedido",
  },
  {
    key: "操作",
    value: "Eliminar",
  },
  {
    key: "前往xx页",
    value: "Ir a la página xx",
  },
  {
    key: "提出日期",
    value: "Fecha realizada",
  },
  {
    key: "交货订单状态",
    value: "Estado del pedido de entrega",
  },
  {
    key: "注意：如果您想更改交货订单，请与我们联系。",
    value:
      "Aviso: Si desea modificar la pedido de entrega, por favor contáctenos.",
  },
  {
    key: "交货订单",
    value: "Pedido de entrega",
  },
  {
    key: "拟议的时间",
    value: "Tiempo propuesto",
  },
  {
    key: "付款日期",
    value: "Fecha de pago",
  },
  {
    key: "购买完成日期",
    value: "Fecha de finalización de la compra",
  },
  {
    key: "订单状态",
    value: "Estado del pedido",
  },
  {
    key: "正在购买",
    value: "proceso de compra",
  },
  {
    key: "购买完成日期",
    value: "Fecha de finalización de la compra",
  },
  {
    key: "报价中",
    value: "en proceso",
  },

  {
    key: "等待处理",
    value: "Pedido en proceso",
  },
  {
    key: "处理中",
    value: "Procesando",
  },
  {
    key: "上架完了",
    value: "En stock",
  },
  {
    key: "历史问题",
    value: "Historial",
  },
  {
    key: "请输入关键词搜索",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "输入跟踪号码。",
    value: "Ingrese el número de seguimiento.",
  },
  {
    key: "输入ID 1688/ product link",
    value: "Introduzca el ID de 1688/enlace del producto",
  },
  {
    key: "明白",
    value: "Claro",
  },
  {
    key: "联系负责人",
    value: "Contacta",
  },
  {
    key: "问题提出日期",
    value: "Fecha de emisión",
  },
  {
    key: "问题条数",
    value: "Número de preguntas",
  },
  {
    key: "问题商品数量",
    value: "Cantidad de artículos",
  },
  {
    key: "暂无数据",
    value: "No hay datos",
  },
  {
    key: "立即支付",
    value: "Pague ahora mismo",
  },
  {
    key: "问题产品明细",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "问题照片",
    value: "Defecto de la foto",
  },
  {
    key: "问题详情",
    value: "Detalles del problema",
  },
  {
    key: "处理意见",
    value: "Comentario",
  },
  {
    key: "查看照片",
    value: "Ver foto",
  },
  {
    key: "统一回复",
    value: "Respuesta unificada",
  },
  {
    key: "单价（元）",
    value: "Precio unitario (RMB)",
  },
  {
    key: "请输入处理意见",
    value: "Por favor，escribe los comentarios",
  },
  {
    key: "必填参数缺失",
    value: "Falta información",
  },
  {
    key: "全部",
    value: "Todo",
  },
  {
    key: "等待发货",
    value: "A la espera de la entrega",
  },

  {
    key: "联系负责人",
    value: "Contacta",
  },
  {
    key: "确认签收",
    value: "Confirmar entrega",
  },
  {
    key: "移出收藏夹成功",
    value: "Eliminar de favoritos con éxito",
  },
  {
    key: "问题提出日期",
    value: "Fecha de emisión",
  },
  {
    key: "问题条数",
    value: "Número de preguntas",
  },
  {
    key: "问题商品数量",
    value: "Cantidad de artículos",
  },
  {
    key: "暂无数据",
    value: "No hay datos",
  },
  {
    key: "立即支付",
    value: "Pague ahora mismo",
  },
  {
    key: "问题产品明细",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "问题照片",
    value: "Defecto de la foto",
  },
  {
    key: "处理意见",
    value: "Comentario",
  },
  {
    key: "查看照片",
    value: "Ver foto",
  },
  {
    key: "统一回复",
    value: "Respuesta unificada",
  },
  {
    key: "单价（元）",
    value: "Precio unitario (RMB)",
  },
  {
    key: "请输入处理意见",
    value: "Por favor，escribe los comentarios",
  },
  {
    key: "必填参数缺失",
    value: "Falta información",
  },
  {
    key: "全部",
    value: "Todo",
  },
  {
    key: "等待发货",
    value: "A la espera de la entrega",
  },

  {
    key: "请输入关键词搜索",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "照片",
    value: "Foto",
  },

  {
    key: "单价(出入金)",
    value: "Precio por unidad",
  },
  {
    key: "小计",
    value: "Subtotal",
  },
  {
    key: "国内运费",
    value: "Envío nacional",
  },

  {
    key: "备注栏",
    value: "Columna de observaciones",
  },
  {
    key: "问题图像",
    value: "Imagen de defectos",
  },
  {
    key: "评论",
    value: "Observaciones",
  },
  {
    key: "状态",
    value: "Estado",
  },

  {
    key: "国内派送中",
    value: "Entrega en curso",
  },
  {
    key: "此订单无备注",
    value: "No hay comentarios para este pedido",
  },
  {
    key: "回到首页",
    value: "Volver a inicio",
  },
  {
    key: "可提出",
    value: "Disponible",
  },
  {
    key: "装箱中",
    value: "Cargando",
  },
  {
    key: "请输入关键词搜索",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "提出配送单",
    value: "Enviar un albarán de entrega",
  },
  {
    key: "照片",
    value: "Foto",
  },

  {
    key: "单价（元）",
    value: "Precio unitario (€)",
  },
  {
    key: "国内运费",
    value: "Transporte nacional",
  },
  {
    key: "单位",
    value: "Unidades",
  },
  {
    key: "购买数量",
    value: "Número de compras",
  },
  {
    key: "可发货数量",
    value: "Cantidad disponible",
  },

  {
    key: "临时保存",
    value: "Guardar temporalmente",
  },
  {
    key: "全部配送单",
    value: "Todos los pedidos enviados",
  },
  {
    key: "报价中",
    value: "Presupuesto en curso",
  },
  {
    key: "待报价",
    value: "esperando la cotización",
  },
  {
    key: "待报价订单",
    value: "Orden a cotizar",
  },
  {
    key: "等待付款(订单列表)",
    value: "Pendiente de pago",
  },
  {
    key: "待发货",
    value: "Pendiente de entrega",
  },
  {
    key: "请输入关键词搜索",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "重量（Kg）",
    value: "Peso (Kg)",
  },
  {
    key: "已报价",
    value: "Ha sido cotizado",
  },
  {
    key: "国际物流费用",
    value: "Costes logísticos internacionales",
  },
  {
    key: "西班牙国内配送中",
    value: "Entrega en España",
  },
  {
    key: "国际运输中",
    value: "Envío internacional",
  },
  {
    key: "已签收2",
    value: "Entregado",
  },
  {
    key: "已清关",
    value: "Despacho de aduana",
  },
  {
    key: "已签收",
    value: "Recibido",
  },
  {
    key: "请输入关键词搜索",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "配送单号",
    value: "Número de entrega",
  },
  {
    key: "发货时间",
    value: "tiempo de entrega",
  },
  {
    key: "通关方式",
    value: "Despacho de aduanas",
  },
  {
    key: "国际运输方式",
    value: "Opciones de transporte internacional",
  },
  {
    key: "箱数",
    value: "Número de cajas",
  },
  {
    key: "体积",
    value: "Volumen",
  },

  {
    key: "去提现",
    value: "Retirar saldo",
  },
  {
    key: "剩余可用点数",
    value: "Saldo disponible",
  },
  {
    key: "已充值待确认点数",
    value: "Saldo pendiente",
  },
  {
    key: "提现中",
    value: "Retiro en curso",
  },
  {
    key: "充值",
    value: "Recarga",
  },
  {
    key: "公司名称",
    value: "Nombre de la empresa",
  },
  {
    key: "你的商业网站(非强制性)",
    value: "Sitio web de tu negocio (no obligatorio)",
  },
  {
    key: "公司地址",
    value: "Dirección de la empresa",
  },
  {
    key: "公司营业执照",
    value: "Licencia comercial",
  },
  {
    key: "银行账户",
    value: "Cuenta bancaria（IBAN）",
  },
  {
    key: "SWIFT账户",
    value: "Cuenta SWIFT",
  },
  {
    key: "汇款日期",
    value: "Fecha del pago",
  },
  {
    key: "汇款日期不能为空",
    value: "La fecha de envío no puede estar vacía",
  },
  {
    key: "选择日期",
    value: "Selecciona la fecha",
  },
  {
    key: "汇款人",
    value: "Remitido por",
  },
  {
    key: "汇款人不能为空",
    value: "El remitente no puede estar vacío",
  },
  {
    key: "汇款金额",
    value: "Importe del pago",
  },
  {
    key: "汇款金额不能为空",
    value: "El importe no puede estar vacío",
  },

  {
    key: "备注不能为空",
    value: "Los comentarios no pueden estar vacíos",
  },
  {
    key: "汇款截图",
    value: "Captura de pantalla de los pagos",
  },
  {
    key: "图片上传中",
    value: "Subir imagen",
  },
  {
    key: "支付",
    value: "Pagar ahora",
  },
  {
    key: "提现申请信息",
    value: "Información sobre la retirada de fondos",
  },
  {
    key: "当前账户余额",
    value: "Saldo en cuenta",
  },
  {
    key: "户名",
    value: "Nombre de usuario",
  },
  {
    key: "请输入户名",
    value: "Introduce nombre de usuario",
  },
  {
    key: "银行账户",
    value: "Cuenta bancaria",
  },
  {
    key: "提现金额",
    value: "Importe de la retirada de fondos",
  },
  {
    key: "提现申请原因",
    value: "Motivo de la retirada de fondos",
  },
  {
    key: "确认提交",
    value: "confirmar envío",
  },
  {
    key: "请输入公司名称",
    value: "Introduce el nombre de tu empresa o negocio",
  },
  {
    key: "请输入公司网址",
    value: "Introduce la url donde vendas tus productos",
  },

  {
    key: "请输入银行账户",
    value: "Por favor, introduzca su cuenta bancaria",
  },
  {
    key: "请输入提现金额",
    value: "Por favor, introduzca el importe de la retirada de fondos",
  },
  {
    key: "请输入提现申请原因",
    value: "Por favor, introduzca el motivo de la retirada de fondos",
  },
  {
    key: "请您核对以下信息是否正确",
    value: "Comprueba que la información es correcta",
  },

  {
    key: "汇款取款历史",
    value: "Historial de retirada de fondos",
  },
  {
    key: "日期（财务确认日期）",
    value: "Fecha (fecha de confirmación financiera)",
  },

  {
    key: "交易类型",
    value: "Tipo de transacción",
  },
  {
    key: "支付方式",
    value: "Forma de pago",
  },
  {
    key: "发票号",
    value: "Número de factura",
  },
  {
    key: "总金额",
    value: "Importe total",
  },
  {
    key: "明细",
    value: "Detalles",
  },
  {
    key: "收支",
    value: "Ingreso y pago",
  },
  {
    key: "账户余额",
    value: "Saldo de la cuenta",
  },
  {
    key: "配送单",
    value: "Nota de entrega",
  },
  {
    key: "提现记录",
    value: "Registros de retirada de fondos",
  },
  {
    key: "此配送单已正式提出，请勿重复操作",
    value:
      "Esta orden de entrega ha sido enviada oficialmente, por favor no repita la operación",
  },
  {
    key: "发起时间",
    value: "Hora de inicio",
  },
  {
    key: "流水号",
    value: "Número de serie",
  },
  {
    key: "收款户名",
    value: "Nombre de la cuenta del beneficiario",
  },
  {
    key: "收款户号",
    value: "número de cuenta del beneficiario",
  },
  {
    key: "提现金额",
    value: "Importe de la retirada de fondos",
  },
  {
    key: "处理状态",
    value: "Estado de tramitación",
  },

  {
    key: "待审核",
    value: "Pendiente de revisión",
  },
  {
    key: "查看凭证",
    value: "Ver credenciales",
  },
  {
    key: "人工调整",
    value: "Ajustar a mano",
  },
  {
    key: "下载",
    value: "Descargar",
  },
  {
    key: "关闭",
    value: "Cerrar",
  },
  {
    key: "已被停用",
    value: "Desactivado",
  },
  {
    key: "请输入关键词搜索",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "是否删除选中的商品",
    value: "Eliminar el producto seleccionado",
  },
  {
    key: "是否删除选中的配送单？",
    value: "Si eliminar la orden de entrega seleccionada",
  },
  {
    key: "请至少选择一项",
    value: "Seleccionar al menos uno",
  },
  {
    key: "请输入关键词搜索",
    value: "Introduzca una palabra clave para la búsqueda",
  },
  {
    key: "商品编号",
    value: "Número de artículo",
  },
  {
    key: "选择",
    value: "Seleccionar",
  },
  {
    key: "照片",
    value: "Foto",
  },
  {
    key: "商品链接",
    value: "Enlace al producto",
  },
  {
    key: "英文产品名称",
    value: "Nombre del producto en español",
  },
  {
    key: "产品属性",
    value: "Atributos del producto",
  },
  {
    key: "单价",
    value: "Precio unitario",
  },
  {
    key: "商品状态",
    value: "Estado del producto",
  },

  {
    key: "正常",
    value: "Normal",
  },
  {
    key: "请输入备注",
    value: "Por favor, introduce un comentario",
  },
  {
    key: "添加",
    value: "Añadir",
  },

  {
    key: "店铺名称",
    value: "Tienda",
  },
  {
    key: "选中移出",
    value: "Eliminar",
  },
  {
    key: "取消替换",
    value: "Cancelar sustitución",
  },
  {
    key: "是否删除此店铺",
    value: "¿Está seguro que desea eliminar esta tienda?",
  },
  {
    key: "提示",
    value: "Atención",
  },

  {
    key: "取消",
    value: "Cancelar",
  },
  {
    key: "必填参数缺失",
    value: "Falta información",
  },

  {
    key: "请至少选择一个商品",
    value: "Selecciona al menos un artículo",
  },
  {
    key: "搜索",
    value: "buscar",
  },
  {
    key: "导出excel",
    value: "Exportar en Excel",
  },
  {
    key: "提出订单",
    value: "Solicitar cotización",
  },
  {
    key: "商品库提出订单",
    value: "Realizar un pedido",
  },
  {
    key: "商品编号",
    value: "referencia de producto",
  },
  {
    key: "照片",
    value: "Foto",
  },
  {
    key: "商品链接",
    value: "Enlace del producto",
  },

  {
    key: "商品备注详情",
    value: "detalles del producto",
  },
  {
    key: "请输入备注信息",
    value: "comentarios",
  },
  {
    key: "添加",
    value: "añadir",
  },
  {
    key: "商品库添加商品",
    value: "Añadir productos a la lista de desos",
  },
  {
    key: "商品图片",
    value: "Imagen del producto",
  },
  {
    key: "商品链接",
    value: "Enlace del producto",
  },
  {
    key: "商品属性",
    value: "Descripción del producto",
  },

  {
    key: "商品状态",
    value: "Estado del producto",
  },
  {
    key: "正常",
    value: "Normal",
  },
  {
    key: "添加一行",
    value: "Añadir una línea",
  },
  {
    key: "请输入商品链接",
    value: "Introduce el enlace del producto",
  },
  {
    key: "保存",
    value: "Guardar",
  },
  {
    key: "保存中",
    value: "Guardando",
  },

  {
    key: "取消",
    value: "Cancelar",
  },
  {
    key: "修改地址",
    value: "Modificar la dirección",
  },
  {
    key: "当前默认地址",
    value: "Dirección predeterminada",
  },
  {
    key: "同步到进口商",
    value: "Sincronización con el importador",
  },
  {
    key: "保持正确",
    value: "Guardado correctamente",
  },
  {
    key: "编辑",
    value: "Editar",
  },
  {
    key: "这是你的控制面板",
    value: "este es tu panel de control",
  },
  {
    key: "你好",
    value: "Hola",
  },
  {
    key: "新消息",
    value: "Nuevas notificaciones",
  },
  {
    key: "临时保存的订单",
    value: "Guardados temporalmente",
  },
  {
    key: "所有待处理的订单",
    value: "Todos los pedidos en curso",
  },
  {
    key: "从",
    value: "Desde",
  },
  {
    key: "起",
    value: "piezas",
  },
  {
    key: "等待工厂发货的订单",
    value: "Recepción pendiente desde fábrica",
  },
  {
    key: "货物到达RAKUMART中国仓库",
    value: "Mercancia preparada en almacén",
  },
  {
    key: "检品",
    value: "Control de calidad y manipulación",
  },
  {
    key: "国际运费报价",
    value: "Flete pendiente de cotización",
  },
  {
    key: "国际运费待支付",
    value: "Envío internacional pendiente de pago",
  },
  {
    key: "待装仓",
    value: "En espera de recogida para envío",
  },
  {
    key: "中国发往西班牙的订单",
    value: "En transito hacia España",
  },
  {
    key: "货物到达RAKUMART西班牙仓库",
    value: "En almacén de Rakumart España",
  },
  {
    key: "西班牙派送中",
    value: "Envío nacional en curso",
  },
  {
    key: "西班牙派送",
    value: "Envío nacional",
  },
  {
    key: "国际追踪号码",
    value: "Nº de seguimiento internacional",
  },
  {
    key: "上架完了订单数",
    value: "Pedidos en almacén",
  },
  {
    key: "需要付款的订单",
    value: "Pedidos pendiente de pago",
  },
  {
    key: "需要付款的配送单",
    value: "Envío internacional pendiente de pago",
  },
  {
    key: "已收货",
    value: "Recibidos",
  },
  {
    key: "国际运费待报价",
    value: "Flete pendiente de cotización",
  },
  {
    key: "到达RAKUMART物流中心",
    value: "Centro logístico Rakumart España",
  },
  {
    key: "订单待处理",
    value: "Pendiente cotizar",
  },
  {
    key: "订单到达RAKUMART仓库",
    value: "Almacén Rakumart",
  },
  {
    key: "订单运输中",
    value: "Logística y envíos",
  },
  {
    key: "已完成订单",
    value: "Pedidos finalizados",
  },
  {
    key: "发票",
    value: "Facturación",
  },
  {
    key: "历史发票",
    value: "Historial de facturas",
  },
  {
    key: "发票信息",
    value: "Información de facturación",
  },

  {
    key: "店铺收藏",
    value: "Tiendas favoritas",
  },
  {
    key: "发货地址",
    value: "Direcciones de envío",
  },
  {
    key: "密码修改与验证",
    value: "Contraseña y verificación",
  },
  {
    key: "账户信息摘要",
    value: "Resumen de tu cuenta",
  },
  {
    key: "已发出的订单",
    value: "Pedidos entregados",
  },
  {
    key: "手续费是如何计算的？",
    value: "¿Cómo funcionan las comisiones?",
  },
  {
    key: "优惠券",
    value: "Cupones",
  },
  {
    key: "重点订单信息",
    value: "Alertas de tus pedidos",
  },
  {
    key: "已到达RAKUMART仓库的订单",
    value: "Pedidos recepcionados en Warehouse",
  },
  {
    key: "管理",
    value: "Gestionar",
  },
  {
    key: "订单进程",
    value: "Acceso directo al estado de tus pedidos",
  },
  {
    key: "待支付国际运费的订单",
    value: "Envío internacional pendiente de pago",
  },
  {
    key: "西班牙运输中的订单",
    value: "Envío internacional en curso",
  },
  {
    key: "所有订单",
    value: "Todos",
  },
  {
    key: "待收货",
    value: "En espera de recepción",
  },
  {
    key: "临时保存的订单",
    value: "Pedidos guardados",
  },
  {
    key: "待处理的订单",
    value: "Pendiente de pago",
  },
  {
    key: "收件人",
    value: "Destinatario",
  },
  {
    key: "临时保存的",
    value: "Conservada temporalmente",
  },
  {
    key: "出货完成",
    value: "Finalización del envío",
  },
  {
    key: "进口商",
    value: "Importador",
  },
  {
    key: "邮编",
    value: "Código postal",
  },
  {
    key: "省份",
    value: "Provincia",
  },
  {
    key: "输入你的省份",
    value: "Introduce tu provincia",
  },
  {
    key: "联系人",
    value: "Persona de Contacto",
  },
  {
    key: "手机",
    value: "Teléfono móvil",
  },

  {
    key: "电子邮件",
    value: "Correo electrónico",
  },
  {
    key: "新增地址",
    value: "Añadir una dirección",
  },
  {
    key: "编辑地址",
    value: "Editar dirección",
  },
  {
    key: "是否默认",
    value: "Por defecto",
  },
  {
    key: "确认关闭",
    value: "Confirmar cerrar",
  },
  {
    key: "是否删除当前地址",
    value: "Eliminar la dirección actual",
  },
  {
    key: "不保存更改就退出?",
    value: "¿Salir sin guardar los cambios?",
  },
  {
    key: "删除取消",
    value: "Eliminar Cancelar",
  },
  {
    key: "操作成功",
    value: "Práctica exitosa",
  },
  {
    key: "移除成功",
    value: "Eliminado correctamente",
  },
  {
    key: "重置",
    value: "Reiniciar",
  },
  {
    key: "是否默认",
    value: "Por defecto",
  },
  {
    key: "新增",
    value: "Nuevo",
  },
  {
    key: "格式不正确",
    value: "Formato incorrecto /Por favor completa la información",
  },
  {
    key: "同步到收件人",
    value: "Sincronizar con el destinatario",
  },
  {
    key: "请选择认证方式",
    value: "Seleccione un método de verificación",
  },
  {
    key: "请选择认证方式",
    value: "Seleccione un método de verificación",
  },
  {
    key: "恢复或更改密码",
    value: "Recuperar o cambiar contraseña",
  },
  {
    key: "电子邮件链接到您的帐户",
    value: "Correo electrónico vinculado a tu cuenta",
  },
  {
    key: "你的Rakumart账户的手机号码",
    value: "Número de teléfono móvil de tu cuenta Rakumart",
  },
  {
    key: "为了保护您的账户，Rakumart希望验证您是试图恢复或更改密码的人",
    value:
      "Para proteger tu cuenta, Rakumart quiere verificar que eres tú quien está intentando recuperar o cambiar la contraseña",
  },
  {
    key: "手机号码",
    value: "Teléfono móvil",
  },
  {
    key: "电子邮箱",
    value: "Correo electrónico",
  },
  {
    key: "请选择认证方式",
    value: "Seleccione un método de verificación",
  },
  {
    key: "密码验证",
    value: "Verifica tu contraseña",
  },
  {
    key: "身份确认",
    value: "Identificación",
  },
  {
    key: "请输入登录密码",
    value: "Introduce tu contraseña",
  },
  {
    key: "请输入旧登录密码",
    value: "Introduce tu contraseña anterior / actual",
  },
  {
    key: "密码错误",
    value: "La contraseña no es válida",
  },
  {
    key: "其他认证方式",
    value: "Otros métodos de indentificación",
  },
  {
    key: "密码请控制在6位以上16位以内",
    value: "La contraseña debe tener entre 6 y 16 caracteres",
  },
  {
    key: "登录密码修改",
    value: "Modifica tu contraseña",
  },
  {
    key: "新密码",
    value: "Nueva contraseña",
  },
  {
    key: "您已经有了新密码，请重新登录。",
    value: "Ya tienes tu nueva contraseña, vuelve a iniciar sesión.",
  },
  {
    key: "密码修改成功",
    value: "Contraseña cambiada con éxito",
  },
  {
    key: "购买数量不能超过在库数量",
    value: "La cantidad de compra no puede superar la de biblioteca",
  },
  {
    key: "再次确认密码",
    value: "Confirma tu contraseña",
  },
  {
    key: "请再次输入密码",
    value: "Vuelve a introducir tu contraseña",
  },
  {
    key: "返回主页",
    value: "Volver a la página principal",
  },
  {
    key: "更改成功",
    value: "Se ha realizado el cambio correctamente",
  },
  {
    key: "登录密码更改成功",
    value: "La contraseña se ha cambiado correctamente",
  },
  {
    key: "密码修改成功，请重新登录",
    value:
      "La modificación de la contraseña se ha realizado correctamente, vuelve a iniciar sesión",
  },
  {
    key: "手机号码",
    value: "Teléfono móvil",
  },
  {
    key: "当前未登录",
    value: "Todavía no ha iniciado sesión",
  },
  {
    key: "其他认证方式",
    value: "Otros métodos de indentificación",
  },

  {
    key: "取消",
    value: "Cancelar",
  },
  {
    key: "修改地址",
    value: "Modificar la dirección",
  },
  {
    key: "当前默认地址",
    value: "Dirección predeterminada",
  },
  {
    key: "同步到进口商",
    value: "Sincronización con el importador",
  },
  {
    key: "保持正确",
    value: "Guardado correctamente",
  },
  {
    key: "编辑",
    value: "Editar",
  },
  {
    key: "这是你的控制面板",
    value: "este es tu panel de control",
  },
  {
    key: "你好",
    value: "Hola",
  },
  {
    key: "新消息",
    value: "Nuevas notificaciones",
  },
  {
    key: "所有待处理的订单",
    value: "Todos los pedidos en curso",
  },
  {
    key: "等待工厂发货的订单",
    value: "Recepción pendiente desde fábrica",
  },
  {
    key: "货物到达RAKUMART中国仓库",
    value: "Mercancia preparada en almacén",
  },
  {
    key: "检品",
    value: "Control de calidad y manipulación",
  },
  {
    key: "国际运费报价",
    value: "Flete pendiente de cotización",
  },
  {
    key: "国际运费待支付",
    value: "Envío internacional pendiente de pago",
  },
  {
    key: "待装仓",
    value: "En espera de recogida para envío",
  },
  {
    key: "中国发往西班牙的订单",
    value: "En transito hacia España",
  },
  {
    key: "货物到达RAKUMART西班牙仓库",
    value: "En almacén de Rakumart España",
  },
  {
    key: "西班牙派送中",
    value: "Envío nacional en curso",
  },
  {
    key: "上架完了订单数",
    value: "Pedidos en almacén",
  },
  {
    key: "需要付款的配送单",
    value: "Envío internacional pendiente de pago",
  },
  {
    key: "未付款订单",
    value: "Pedidos pendiente de pago",
  },
  {
    key: "已收货",
    value: "Recibidos",
  },
  {
    key: "国际运费待报价",
    value: "Flete pendiente de cotización",
  },
  {
    key: "到达RAKUMART物流中心",
    value: "Centro logístrico Rakumart España",
  },
  {
    key: "订单待处理",
    value: "Pendiente cotizar",
  },
  {
    key: "订单到达RAKUMART仓库",
    value: "Almacén Rakumart",
  },
  {
    key: "订单运输中",
    value: "Logística y envíos",
  },
  {
    key: "已完成订单",
    value: "Pedidos finalizados",
  },
  {
    key: "发票",
    value: "Facturación",
  },
  {
    key: "发票信息",
    value: "Información de facturación",
  },
  {
    key: "业务类型",
    value: "Tipo de negocio",
  },
  {
    key: "你的主要业务领域",
    value: "Sector principal de tu negocio",
  },
  {
    key: "店铺收藏",
    value: "Tiendas favoritas",
  },
  {
    key: "发货地址",
    value: "Direcciones de envío",
  },
  {
    key: "密码修改与验证",
    value: "Contraseña y verificación",
  },
  {
    key: "账户信息摘要",
    value: "Resumen de tu cuenta",
  },
  {
    key: "已发出的订单",
    value: "Pedidos entregados",
  },
  {
    key: "手续费是如何计算的？",
    value: "¿Cómo funcionan las comisiones?",
  },
  {
    key: "优惠券",
    value: "Cupones",
  },
  {
    key: "券",
    value: "CUPÓN",
  },
  {
    key: "重点订单信息",
    value: "Alertas de tus pedidos",
  },
  {
    key: "已到达RAKUMART仓库的订单",
    value: "Pedidos recepcionados en Warehouse",
  },
  {
    key: "管理",
    value: "Gestionar",
  },
  {
    key: "订单进程",
    value: "Acceso directo al estado de tus pedidos",
  },
  {
    key: "待支付国际运费的订单",
    value: "Envío internacional pendiente de pago",
  },
  {
    key: "西班牙运输中的订单",
    value: "Envío internacional en curso",
  },
  {
    key: "已取消的订单",
    value: "Cancelados",
  },
  {
    key: "所有订单",
    value: "Todos",
  },
  {
    key: "待收货",
    value: "En espera de recepción",
  },
  {
    key: "临时保存的订单",
    value: "Pedidos guardados",
  },
  {
    key: "待处理的订单",
    value: "Pendiente de pago",
  },

  {
    key: "临时保存的",
    value: "Conservada temporalmente",
  },
  {
    key: "出货完成",
    value: "Finalización del envío",
  },
  {
    key: "进口商",
    value: "Importador",
  },
  {
    key: "邮编",
    value: "Código postal",
  },
  {
    key: "省份",
    value: "Provincia",
  },
  {
    key: "联系人",
    value: "Persona de Contacto",
  },
  {
    key: "手机",
    value: "Teléfono móvil",
  },
  {
    key: "电话",
    value: "Teléfono",
  },
  {
    key: "电子邮件",
    value: "Correo electrónico",
  },
  {
    key: "新增地址",
    value: "Añadir una dirección",
  },
  {
    key: "编辑地址",
    value: "Editar dirección",
  },
  {
    key: "是否默认",
    value: "Por defecto",
  },
  {
    key: "确认关闭",
    value: "Confirmar cerrar",
  },
  {
    key: "是否删除当前地址",
    value: "Eliminar la dirección actual",
  },
  {
    key: "不保存更改就退出?",
    value: "¿Salir sin guardar los cambios?",
  },
  {
    key: "删除取消",
    value: "Eliminar Cancelar",
  },
  {
    key: "操作成功",
    value: "Práctica exitosa",
  },
  {
    key: "移除成功",
    value: "Eliminado correctamente",
  },
  {
    key: "重置",
    value: "Reiniciar",
  },
  {
    key: "是否默认",
    value: "Por defecto",
  },
  {
    key: "新增",
    value: "Nuevo",
  },
  {
    key: "格式不正确",
    value: "Formato incorrecto /Por favor completa la información",
  },
  {
    key: "同步到收件人",
    value: "Sincronizar con el destinatario",
  },
  {
    key: "请选择认证方式",
    value: "Seleccione un método de verificación",
  },
  {
    key: "密码验证",
    value: "Verifica tu contraseña",
  },
  {
    key: "手机号码",
    value: "Teléfono móvil",
  },
  {
    key: "电子邮箱",
    value: "Correo electrónico",
  },
  {
    key: "请选择认证方式",
    value: "Seleccione un método de verificación",
  },
  {
    key: "密码验证",
    value: "Verifica tu contraseña",
  },
  {
    key: "身份确认",
    value: "Identificación",
  },
  {
    key: "请输入登录密码",
    value: "Introduce tu contraseña",
  },
  {
    key: "请输入旧登录密码",
    value: "Introduce tu contraseña anterior / actual",
  },
  {
    key: "下一步",
    value: "Siguiente paso",
  },
  {
    key: "密码错误",
    value: "La contraseña no es válida",
  },
  {
    key: "其他认证方式",
    value: "Otros métodos de indentificación",
  },
  {
    key: "密码请控制在6位以上16位以内",
    value: "La contraseña debe tener entre 6 y 16 caracteres",
  },
  {
    key: "登录密码修改",
    value: "Modifica tu contraseña",
  },
  {
    key: "新密码",
    value: "Nueva contraseña",
  },
  {
    key: "购买数量不能超过在库数量",
    value: "La cantidad de compra no puede superar la de biblioteca",
  },
  {
    key: "再次确认密码",
    value: "Confirma tu contraseña",
  },
  {
    key: "请再次输入密码",
    value: "Vuelve a introducir tu contraseña",
  },
  {
    key: "返回主页",
    value: "Volver a la página principal",
  },
  {
    key: "更改成功",
    value: "Se ha realizado el cambio correctamente",
  },
  {
    key: "登录密码更改成功",
    value: "La contraseña se ha cambiado correctamente",
  },
  {
    key: "密码修改成功，请重新登录",
    value:
      "La modificación de la contraseña se ha realizado correctamente, vuelve a iniciar sesión",
  },
  {
    key: "手机号码",
    value: "Teléfono móvil",
  },
  {
    key: "当前未登录",
    value: "Todavía no ha iniciado sesión",
  },
  {
    key: "其他认证方式",
    value: "Otros métodos de indentificación",
  },

  {
    key: "验证码获取",
    value: "Obtener el código de verificación",
  },
  {
    key: "必填参数缺失",
    value: "Falta información",
  },
  {
    key: "请在60秒后重新发送验证码",
    value: "Vuelve a enviar el código de verificación después de 60 segundos",
  },
  {
    key: "邮箱",
    value: "Correo electrónico",
  },
  {
    key: "其他认证方式",
    value: "Otros métodos de indentificación",
  },
  {
    key: "必填参数缺失",
    value: "Falta información",
  },
  {
    key: "请在60秒后重新发送验证码",
    value: "Vuelve a enviar el código de verificación después de 60 segundos",
  },
  {
    key: "时间",
    value: "Fecha",
  },

  {
    key: "全选",
    value: "Seleccionar todo",
  },
  {
    key: "产品信息",
    value: "Información del producto",
  },
  {
    key: "详情",
    value: "Detalles",
  },
  {
    key: "看到细节",
    value: "Ver detalles",
  },

  {
    key: "总价",
    value: "Precio total",
  },
  {
    key: "如果程序是由Rakumart执行的，则将收取“SKU标签”服务费用。",
    value:
      "Si el procedimiento es realizado por Rakumart, se aplicará el cargo del servicio de “Etiquetado SKU”",
  },
  {
    key: "亚马逊FBA的标签是免费的，只要程序是由制造商负责。",
    value:
      "Etiquetado para Amazon FBA sin coste siempre que el procedimiento sea a cargo del fabricante.",
  },
  {
    key: "标签为亚马逊FBA",
    value: "Etiquetado Amazon FBA",
  },
  {
    key: "亚马逊FBA套装包装",
    value: "Empaquetado conjunto Amazon FBA",
  },
  {
    key: "为亚马逊FBA免费打包产品，只要程序由制造商负责。",
    value:
      "Empaquetado conjunto de productos para Amazon FBA sin coste siempre que el procedimiento sea a cargo del fabricante. ",
  },
  {
    key: "如果该程序是由Rakumart执行的，则将收取“捆绑”服务费用。",
    value:
      "Si el procedimiento es realizado por Rakumart, se aplicará el cargo del servicio de “Empaquetar conjunto”.",
  },
  {
    key: "备注",
    value: "Comentarios",
  },
  {
    key: "店铺名/店铺",
    value: "Tienda",
  },
  {
    key: "店铺",
    value: "Proveedor",
  },
  {
    key: "选择附加服务",
    value: "Elige servicios adicionales",
  },
  {
    key: "备注栏",
    value: "Columna de comentarios",
  },
  {
    key: "从购物车中导入更多产品",
    value: "Importar más productos del carrito",
  },
  {
    key: "追加商品",
    value: "Añadir",
  },
  {
    key: "删除",
    value: "Suprimir",
  },
  {
    key: "客户提现出金",
    value: "Cliente retira efectivo",
  },
  {
    key: "客户提出现金",
    value: "Cliente retira efectivo",
  },
  {
    key: "全部费用",
    value: "Todas las tarifas",
  },
  {
    key: "总运费",
    value: "Coste total del envío",
  },
  {
    key: "体积m³",
    value: "Volumen m3 aprox",
  },
  {
    key: "立方米体积",
    value: "Volumen m³",
  },
  {
    key: "附加服务费",
    value: "Coste del servicio adicional",
  },
  {
    key: "其他费用",
    value: "Otros cargos",
  },

  {
    key: "选择国际运输公司",
    value: "Elige una empresa de transporte internacional",
  },
  {
    key: "请选择您的物流方式，不同的物流方式价格不同",
    value:
      "Escoje el método de envío, los precios varían entre los distintos tipos de envío",
  },
  {
    key: "特殊要求备注",
    value: "Observaciones de requisitos especiales",
  },
  {
    key: "对该订单有特殊要求的，可以在这边备注",
    value: "Requisitos especiales",
  },
  {
    key: "备注后会同步显示给业务员",
    value: "Los comentarios se mostrarán al vendedor simultáneamente",
  },
  {
    key: "附加服务选项",
    value: "Opciones de servicio adicionales",
  },

  {
    key: "预计费用",
    value: "Coste aproximado",
  },
  {
    key: "重置",
    value: "Reiniciar",
  },
  {
    key: "保存",
    value: "Guardar",
  },
  {
    key: "合计约为（元）",
    value: "Total aproximado",
  },
  {
    key: "内容",
    value: "Contenido",
  },
  {
    key: "人民币",
    value: "RMB",
  },
  {
    key: "确定",
    value: "Confirmar",
  },
  {
    key: "取消",
    value: "Cancelar",
  },
  {
    key: "收件工资",
    value: "Cobro recibido",
  },
  {
    key: "唛头",
    value: "Etiqueta",
  },
  {
    key: "邮编",
    value: "Codigo postal",
  },
  {
    key: "联系我们",
    value: "Contacto",
  },
  {
    key: "附加服务选择",
    value: "Servicios adicionales",
  },
  {
    key: "该订单已正式提出，勿重复提出",
    value: "El pedido ha sido procesado correctamente",
  },
  {
    key: "购物车为空",
    value: "El carrito está vacío",
  },
  {
    key: "去购物",
    value: "Tienda",
  },
  {
    key: "收藏夹",
    value: "Favoritos",
  },
  {
    key: "追加",
    value: "Añadir",
  },
  {
    key: "产品",
    value: "Información del producto",
  },
  {
    key: "调查在库数量",
    value: "Disponibles",
  },
  {
    key: "预估到手费用",
    value: "Gasto estimado",
  },
  {
    key: "特殊备注要求",
    value: "comentarios adicionales",
  },
  {
    key: "使用优惠券",
    value: "Usar un cupón",
  },
  {
    key: "执行券",
    value: "Aplicar cupón",
  },
  {
    key: "全部费用明细",
    value: "Detalles del coste completo",
  },

  {
    key: "修改订单",
    value: "Cambios en el pedido",
  },
  {
    key: "查看全部",
    value: "Ver todo",
  },
  {
    key: "业务给客户的回复",
    value: "Respuesta para los clientes",
  },
  {
    key: "RAKUMART订单价值",
    value: "Coste del pedido RAKUMART",
  },
  {
    key: "对该订单有特殊要求的，可以在这边备注",
    value: "Comentarios adicionales",
  },
  {
    key: "备注后会同步显示给业务员",
    value: "Los comentarios se mostrarán al vendedor simultáneamente",
  },
  {
    key: "重新提出",
    value: "Replantear",
  },
  {
    key: "调查单价",
    value: "Investigar el precio por unidad",
  },
  {
    key: "调查在库数量",
    value: "Investigar unidades disponibles",
  },

  {
    key: "预估到手费用",
    value: "Gasto estimado",
  },
  {
    key: "关闭",
    value: "Cerrar",
  },
  {
    key: "选择优惠券或代金券",
    value: "Elige un vale o cupón",
  },

  {
    key: "代金券",
    value: "VALE",
  },

  {
    key: "编号",
    value: "Etiqueta",
  },
  {
    key: "免手续费、无佣金",
    value: "Exento de costes por gestión y comisión",
  },
  {
    key: "已使用优惠",
    value: "Preferencia usada",
  },
  {
    key: "余额支付",
    value: "pagar con saldo de la cuenta",
  },

  {
    key: "支付成功",
    value: "Pago exitoso",
  },
  {
    key: "是否使用余额支付",
    value: "Si usar el saldo para pagar",
  },
  {
    key: "已取消支付",
    value: "Pago cancelado",
  },
  {
    key: "汇款人不能为空",
    value: "El remitente no puede estar vacío",
  },
  {
    key: "汇款金额不能为空",
    value: "El importe no puede estar vacío",
  },
  {
    key: "备注不能为空",
    value: "Los comentarios no pueden estar vacíos",
  },
  {
    key: "图片上传中",
    value: "Subir imagen",
  },
  {
    key: "请上传图片",
    value: "suba la imagen por favor",
  },
  {
    key: "是否使用银行卡支付",
    value: "Confirmación de la presentación del documento de envío de remesas",
  },
  {
    key: "财务部门将确认您提交的付款。财务确认后，充值金额将反映在账户余额中",
    value:
      "El Departamento de Finanzas confirmará su pago. Después del reconocimiento financiero, la cantidad recargada se reflejará en el saldo de la cuenta",
  },
  {
    key: "付款流水号",
    value: "Número de serie del pago",
  },
  {
    key: "回到个人中心",
    value: "Volver al Centro personal",
  },
  {
    key: "汇款金额不能小于最低汇款金额",
    value:
      "La cantidad de remesas debe ser mayor que la cantidad actual insuficiente",
  },

  {
    key: "RAKUMART订单价值",
    value: "Coste del pedido RAKUMART",
  },
  {
    key: "中国总成本",
    value: "Coste total en China",
  },
  {
    key: "产品价值",
    value: "Valor del producto",
  },

  {
    key: "附加服务",
    value: "Servicios adicionales",
  },

  {
    key: "中国国内运费",
    value: "Coste transporte interno de China",
  },
  {
    key: "关税",
    value: "Arancel",
  },
  {
    key: "费率",
    value: "tarifas",
  },
  {
    key: "预计成本",
    value: "coste aproximado",
  },
  {
    key: "西班牙物流成本",
    value: "coste logistico en España",
  },
  {
    key: "税金",
    value: "impuesto",
  },

  {
    key: "运货方式",
    value: "Método de envío",
  },
  {
    key: "目的地",
    value: "destino",
  },
  {
    key: "航运标志",
    value: "borrar",
  },
  {
    key: "表格下载",
    value: "Descárgate el formulario",
  },
  {
    key: "形式发票",
    value: "Factura de proforma",
  },
  {
    key: "销售合同",
    value: "Contrato de venta",
  },
  {
    key: "全选/取消",
    value: "Seleccionar todo/Cancelar",
  },

  {
    key: "总价",
    value: "Precio total",
  },
  {
    key: "优惠金额",
    value: "Descuento",
  },
  {
    key: "实际支付金额",
    value: "Total pago",
  },
  {
    key: "支付金额",
    value: "Importe del Pago",
  },
  {
    key: "估计产品进口详情",
    value: "Detalles estimados de importación de producto",
  },
  {
    key: "费用明细",
    value: "Detalles del pago",
  },
  {
    key: "部署",
    value: "Desplegar",
  },
  {
    key: "导入的详细信息",
    value: "Detalles de la importación",
  },
  {
    key: "体积",
    value: "Volumen",
  },
  {
    key: "重量",
    value: "peso",
  },
  {
    key: "单番合计",
    value: "total",
  },
  {
    key: "内容",
    value: "contenido",
  },
  {
    key: "订单",
    value: "Pedido",
  },
  {
    key: "国内物流状态",
    value: "Estado de transporte de China",
  },
  {
    key: "账户",
    value: "Cuenta",
  },
  {
    key: "前往登录",
    value: "Registrarse",
  },
  {
    key: "问题商品",
    value: "Defecto de producto",
  },
  {
    key: "关于公司",
    value: "Sobre empresa",
  },
  {
    key: "公司介绍",
    value: "Presentación de empresa",
  },
  {
    key: "会员服务条款",
    value: "Condiciones de servicio para afiliados",
  },
  {
    key: "隐私政策",
    value: "Política de privacidad",
  },
  {
    key: "表格",
    value: "Formulario",
  },
  {
    key: "用户指南",
    value: "Guía de usuario",
  },
  {
    key: "常见问题解答",
    value: "Solución de problemas frecuentes ",
  },
  {
    key: "国际运费",
    value: "Flete internacional",
  },
  {
    key: "物流与运输",
    value: "Logística y envíos",
  },
  {
    key: "已付款待财务确认",
    value: "Pagado pendiente de confirmación financiera",
  },
  {
    key: "加载中",
    value: "Cargando",
  },
  {
    key: "共",
    value: "Total",
  },
  {
    key: "衣服",
    value: "Ropa",
  },
  {
    key: "商品种类",
    value: "Tipo de producto",
  },
  {
    key: "数量总计",
    value: "Cantidad total",
  },
  {
    key: "海上运输",
    value: "Transporte marítimo",
  },
  {
    key: "操作菜单",
    value: "Opciones",
  },
  {
    key: "全部的费用",
    value: "Gasto total",
  },
  {
    key: "暂无优惠券",
    value: "Sin cupón",
  },
  {
    key: "欧元",
    value: "Euro",
  },
  {
    key: "无佣金",
    value: "Sin comisión",
  },
  {
    key: "商品价值",
    value: "Valor del producto",
  },
  {
    key: "国际物流预估",
    value: "Transporte internacional estimado",
  },
  {
    key: "西班牙物流预估",
    value: "Transporte estimado de España",
  },
  {
    key: "运输产品的总成本",
    value: "Coste total de los productos con envío",
  },
  {
    key: "增值税预估",
    value: "IVA estimado",
  },
  {
    key: "西班牙预估",
    value: "Estimacinones de España",
  },
  {
    key: "全部费用预估",
    value: "Gasto total estimado",
  },
  {
    key: "负责人回复",
    value: "Respuesta del responsable",
  },
  {
    key: "国际船运A",
    value: "Transporte Marítimo Internacional A",
  },
  {
    key: "问题图片",
    value: "Imagen problemática",
  },
  {
    key: "问题商品明细",
    value: "Detalle del producto problemático ",
  },
  {
    key: "添加选中商品",
    value: "Añadir los productos elegidos",
  },
  {
    key: "可提货数量",
    value: "Cantidad de mercancías disponible",
  },
  {
    key: "名称",
    value: "Nombre",
  },
  {
    key: "已提货数量",
    value: "Cantidaddemercancíasrecogidas",
  },
  {
    key: "计费数据",
    value: "Dato de facturación",
  },
  {
    key: "提示：配送单信息如有修改，请联系您的负责人员",
    value:
      "Atención: si corrige la información de transporte de pedido, contacte con su corresponsable por favor",
  },
  {
    key: "提出数",
    value: "Cantidad",
  },

  {
    key: "收货地址",
    value: "Dirección de entrega",
  },
  {
    key: "收件公司",
    value: "Empresa receptora",
  },
  {
    key: "请输入收件公司",
    value: "Introduzca la empresa receptora",
  },
  {
    key: "请输入邮编",
    value: "Introduzca el código postal",
  },
  {
    key: "请输入地址",
    value: "Introduzca la dirección",
  },
  {
    key: "请输入联系人",
    value: "Introduzca un contacto",
  },
  {
    key: "请输入手机",
    value: "Introduzca el Número de móvil",
  },
  {
    key: "请输入电话",
    value: "Introduzca el Número de teléfono",
  },
  {
    key: "请输入电子邮箱",
    value: "Introduzca el correo electrónico",
  },
  {
    key: "请输入CNPJ",
    value: "Introduzca el CNPJ",
  },
  {
    key: "收货地址详情",
    value: "Detalles de la dirección de recepción",
  },
  {
    key: "RAKUMART代通关",
    value: "Entregar por RAKUMART",
  },
  {
    key: "个人通关",
    value: "Entregar personal",
  },
  {
    key: "西班牙物流费用",
    value: "Gastos logísticos en España",
  },
  {
    key: "增值税",
    value: "IVA",
  },
  {
    key: "提示文字",
    value: "Parablas recordatorio",
  },
  {
    key: "提交",
    value: "Presentar",
  },
  {
    key: "汇款图片不能为空",
    value: "La imagen de envío no puede estar vacía",
  },
  {
    key: "待处理",
    value: "Por tratar",
  },
  {
    key: "日期",
    value: "Fecha",
  },
  {
    key: "余额",
    value: "Saldo",
  },
  {
    key: "修改姓氏",
    value: "Modificar el apellido",
  },
  {
    key: "确认修改",
    value: "Confirmar cambios",
  },
  {
    key: "修改唛头",
    value: "Modificación de la marca",
  },
  {
    key: "修改名字",
    value: "Modificar nombre",
  },
  {
    key: "修改NIF",
    value: "Modificar NIF",
  },
  {
    key: "基本信息",
    value: "Información básica",
  },
  {
    key: "修改",
    value: "Modificar",
  },
  {
    key: "邮箱地址",
    value: "Dirección de correo electrónico",
  },
  {
    key: "不能为空",
    value: "No puede estar vacío",
  },
  {
    key: "预计订单金额",
    value: "Cantidad estimada de pedido",
  },
  {
    key: "付款后账户剩余",
    value: "Saldo de la cuenta después del pago",
  },
  {
    key: "请上传支付截图",
    value: "Por favor, suba la captura de pantalla de pago",
  },
  {
    key: "请输入邮箱",
    value: "Introduce tu correo electrónico",
  },
  {
    key: "请选择国家",
    value: "Seleccionar un país",
  },
  {
    key: "请填写确认码",
    value: "Rellene el Código de confirmación por favor",
  },
  {
    key: "需要信息",
    value: "Información de registro necesaria",
  },

  {
    key: "西班牙",
    value: "España",
  },
  {
    key: "请输入验证码",
    value: "Por favor,introduzca el Código de verificación",
  },
  {
    key: "使用条款和隐私政策还没有被同意。",
    value:
      "Las condiciones de uso y la política de privacidad no han sido acordadas",
  },
  {
    key: "请输入登录名",
    value: "Por favor,introduzca el nombre de usuario ",
  },
  {
    key: "返回重新编辑",
    value: "Volver a editar",
  },
  {
    key: "暂无库存",
    value: "Falta de stock",
  },
  {
    key: "秒后可重新发送验证码",
    value: "El Código de verificación se puede volver a enviar en segundos",
  },
  {
    key: "物流单号",
    value: "Número de seguimiento",
  },
  {
    key: "费用(€)",
    value: "Gastos(€)",
  },
  {
    key: "箱号",
    value: "Número de caja",
  },
  {
    key: "实际重量",
    value: "Peso",
  },
  {
    key: "长",
    value: "Largo",
  },
  {
    key: "宽",
    value: "Ancho",
  },

  {
    key: "当前箱号",
    value: "Número de caja actual",
  },
  {
    key: "番号",
    value: "número del producto mercancía",
  },
  {
    key: "图片",
    value: "fotografía",
  },
  {
    key: "该商品本箱装箱数",
    value: "Número de cajas de este Productos",
  },
  {
    key: "已付款待确认",
    value: "Pago pendiente de confirmación",
  },
  {
    key: "前往付款",
    value: "Pagar ahora",
  },
  {
    key: "请选择预设收货地址",
    value: "Por favor, seleccione la dirección de recepción predeterminada",
  },
  {
    key: "操作失败",
    value: "Operación fallida",
  },
  {
    key: "欢迎光临Rakumart",
    value: "Bienvenidos a RAKUMART",
  },
  {
    key: "邮箱、手机号或密码输入错误",
    value: "Buzón de correo, número de teléfono móvil o contraseña incorrecta",
  },
  {
    key: "销量",
    value: "volumen de la venta",
  },
  {
    key: "是否登录",
    value: "Inicia sesión",
  },
  {
    key: "Rakumart是一个批发进口平台，不提供零售服务。",
    value:
      "Rakumart es una plataforma de importación al por mayor, no se proporcionan servicios de venta al detalle.",
  },
  {
    key: "与代理交谈",
    value: "Hablar con un agente",
  },
  {
    key: "去商店",
    value: "Ver la tienda",
  },
  {
    key: "是否前往登录?",
    value:
      "No has iniciado sesión, por favor inicia sesión primero para hablar con un agente.",
  },
  {
    key: "是否提交充值信息？",
    value:
      "¿Estás seguro de retirar el importe de la cuenta a la tarjeta bancaria?",
  },
  {
    key: "请选择预设进口商",
    value: "Por favor, seleccione el importador predeterminado",
  },
  {
    key: "该商品因库存不足或其他原因已经找不到，请查看其他商品！",
    value:
      "¡Este artículo ya no se puede encontrar debido a la escasez de existencias u otras razones. Por favor, compruebe otros artículos!",
  },
  {
    key: "返回首页",
    value: "Volver a la página principal",
  },
  {
    key: "是否确认删除？",
    value: "¿Está seguro de borrar?",
  },
  {
    key: "已使用优惠券",
    value: "Cupón ya utilizado",
  },
  {
    key: "已使用代金券",
    value: "Voucher ya utilizado",
  },
  {
    key: "清关时间",
    value: "Tiempo de despacho de aduanas",
  },
  {
    key: "西班牙物流发货时间",
    value: "Tiempo de entrega logístico en España",
  },
  {
    key: "西班牙物流",
    value: "Logística en España",
  },
  {
    key: "rakumart代通关",
    value: "Por RAKUMART en lugar de despacho por la aduana",
  },
  {
    key: "未达到使用条件",
    value: "Condiciones de uso no satisfechas",
  },
  {
    key: "已删除",
    value: "Borrado",
  },
  {
    key: "已删除(订单状态)",
    value: "Borrado",
  },
  {
    key: "已使用",
    value: "Usado",
  },
  {
    key: "装箱信息",
    value: "Información de embalaje",
  },
  {
    key: "电子邮箱修改",
    value: "Modificación del correo electrónico",
  },
  {
    key: "手机号修改",
    value: "Modificación del número de teléfono móvil",
  },
  {
    key: "手机号输入错误",
    value: "Error al introducir el número de teléfono móvil",
  },
  {
    key: "全部费用",
    value: "Todas las tarifas",
  },
  {
    key: "产品价值",
    value: "Valor del producto",
  },

  {
    key: "附加服务费",
    value: "Coste del servicio adicional",
  },

  {
    key: "选择国际运输公司",
    value: "Elige una empresa de transporte internacional",
  },
  {
    key: "请选择您的物流方式，不同的物流方式价格不同",
    value:
      "Escoje el método de envío, los precios varían entre los distintos tipos de envío",
  },
  {
    key: "特殊要求备注",
    value: "Observaciones de requisitos especiales",
  },
  {
    key: "对该订单有特殊要求的，可以在这边备注",
    value: "Requisitos especiales",
  },
  {
    key: "备注后会同步显示给业务员",
    value: "Los comentarios se mostrarán al vendedor simultáneamente",
  },
  {
    key: "附加服务选项",
    value: "Opciones de servicio adicionales",
  },

  {
    key: "预计费用",
    value: "Coste aproximado",
  },
  {
    key: "重置",
    value: "Reiniciar",
  },
  {
    key: "保存",
    value: "Guardar",
  },
  {
    key: "合计约为（元）",
    value: "Total aproximado (RMB)",
  },
  {
    key: "内容",
    value: "Contenido",
  },
  {
    key: "人民币",
    value: "RMB",
  },
  {
    key: "确定",
    value: "Confirmar",
  },
  {
    key: "取消",
    value: "Cancelar",
  },
  {
    key: "收件工资",
    value: "Cobro recibido",
  },
  {
    key: "唛头",
    value: "Etiqueta",
  },
  {
    key: "邮编",
    value: "Codigo postal",
  },
  {
    key: "地址",
    value: "Dirección",
  },
  {
    key: "联系人",
    value: "Contacto",
  },
  {
    key: "附加服务选择",
    value: "Servicios adicionales",
  },
  {
    key: "该订单已正式提出，勿重复提出",
    value: "El pedido ha sido procesado correctamente",
  },
  {
    key: "购物车为空",
    value: "El carrito está vacío",
  },
  {
    key: "去购物",
    value: "Tienda",
  },
  {
    key: "收藏夹",
    value: "Favoritos",
  },
  {
    key: "追加",
    value: "Añadir",
  },
  {
    key: "产品",
    value: "Información del producto",
  },
  {
    key: "调查在库数量",
    value: "Investigar unidades disponibles",
  },
  {
    key: "预估到手费用",
    value: "Gasto estimado",
  },
  {
    key: "特殊备注要求",
    value: "comentarios adicionales",
  },
  {
    key: "付款",
    value: "Proceder al pago",
  },
  {
    key: "全部费用明细",
    value: "Detalles del coste completo",
  },
  {
    key: "查看全部",
    value: "Ver todo",
  },
  {
    key: "业务给客户的回复",
    value: "Respuesta para los clientes",
  },
  {
    key: "RAKUMART订单价值",
    value: "Coste del pedido RAKUMART",
  },
  {
    key: "对该订单有特殊要求的，可以在这边备注",
    value: "Comentarios adicionales",
  },
  {
    key: "备注后会同步显示给业务员",
    value: "Los comentarios se mostrarán al vendedor simultáneamente",
  },
  {
    key: "重新提出",
    value: "Replantear",
  },
  {
    key: "调查单价",
    value: "Investigar el precio por unidad",
  },
  {
    key: "调查在库数量",
    value: "Investigar unidades disponibles",
  },
  {
    key: "单番中国所有费用",
    value: "Coste total productos",
  },
  {
    key: "预计订单总运费:",
    value: "Coste total estimado del pedido con envío",
  },
  {
    key: "预估到手费用",
    value: "Gasto estimado",
  },
  {
    key: "关闭",
    value: "Cerrar",
  },

  {
    key:
      "温馨提示：每笔订单只能使用一张同类型的优惠券，不同类型的优惠券可以多张同时使用",
    value:
      "Recordatorio: solo se pueden usar en un mismo pedido, cupones de distinto tipo.",
  },

  {
    key: "编号",
    value: "Etiqueta",
  },
  {
    key: "免手续费、无佣金",
    value: "Exento de costes por gestión y comisión",
  },
  {
    key: "已使用优惠",
    value: "Preferencia usada",
  },
  {
    key: "余额支付",
    value: "pagar con saldo de la cuenta",
  },

  {
    key: "支付成功",
    value: "Pago exitoso",
  },
  {
    key: "是否使用余额支付",
    value: "Si usar el saldo para pagar",
  },
  {
    key: "已取消支付",
    value: "Pago cancelado",
  },
  {
    key: "汇款人不能为空",
    value: "El remitente no puede estar vacío",
  },
  {
    key: "汇款金额不能为空",
    value: "El importe no puede estar vacío",
  },
  {
    key: "备注不能为空",
    value: "Los comentarios no pueden estar vacíos",
  },
  {
    key: "图片上传中",
    value: "Subir imagen",
  },
  {
    key: "财务部门将确认您提交的付款。财务确认后，充值金额将反映在账户余额中",
    value:
      "El Departamento de Finanzas confirmará su pago. Después del reconocimiento financiero, la cantidad recargada se reflejará en el saldo de la cuenta",
  },
  {
    key: "付款流水号",
    value: "Número de serie del pago",
  },
  {
    key: "回到个人中心",
    value: "Volver al Centro personal",
  },
  {
    key: "汇款金额不能小于订单金额",
    value:
      "La cantidad de remesas debe ser mayor que la cantidad actual insuficiente",
  },

  {
    key: "RAKUMART订单价值",
    value: "Coste del pedido RAKUMART",
  },
  {
    key: "中国总成本",
    value: "Coste total en China",
  },
  {
    key: "产品价值",
    value: "Valor del producto",
  },

  {
    key: "附加服务",
    value: "Servicios adicionales",
  },

  {
    key: "中国国内运费",
    value: "Coste transporte interno de China",
  },
  {
    key: "关税",
    value: "arancel",
  },
  {
    key: "费率",
    value: "tarifas",
  },
  {
    key: "预计成本",
    value: "coste aproximado",
  },
  {
    key: "西班牙物流成本",
    value: "coste logistico en España",
  },
  {
    key: "税金",
    value: "impuesto",
  },

  {
    key: "总成本",
    value: "Coste total",
  },
  {
    key: "目的地",
    value: "destino",
  },
  {
    key: "航运标志",
    value: "borrar",
  },
  {
    key: "表格下载",
    value: "Descárgate el formulario",
  },
  {
    key: "形式发票",
    value: "Factura de proforma",
  },
  {
    key: "销售合同",
    value: "Contrato de venta",
  },
  {
    key:
      "*根据期间限定打折价格和批量价格变化的情况下，有在RAKUMART不正确显示的情况，不过，因为估价完成时被修正，请直接提交估价。",
    value: "Contrato de venta",
  },
  {
    key: "总体积",
    value: "Vol. m³ aprox",
  },
  {
    key: "总价",
    value: "Precio total",
  },
  {
    key: "优惠金额",
    value: "Descuento",
  },
  {
    key: "实际支付金额",
    value: "Total pago",
  },
  {
    key: "支付金额",
    value: "Importe del pagp",
  },
  {
    key: "费用明细",
    value: "Detalles del pago",
  },
  {
    key: "体积",
    value: "Volumen",
  },
  {
    key: "重量",
    value: "peso",
  },
  {
    key: "单番合计",
    value: "total",
  },
  {
    key: "内容",
    value: "contenido",
  },
  {
    key: "订单",
    value: "Pedido",
  },
  {
    key: "国内物流状态",
    value: "Estado de transporte de China",
  },
  {
    key: "账户",
    value: "Cuenta",
  },
  {
    key: "个人资料",
    value: "Datos personales",
  },

  {
    key: "问题商品",
    value: "Defecto de producto",
  },
  {
    key: "初学者指南",
    value: "Guía del usuario de regístrate gratis",
  },
  {
    key: "关于公司",
    value: "Sobre empresa",
  },
  {
    key: "公司介绍",
    value: "Presentación de empresa",
  },
  {
    key: "会员服务条款",
    value: "Condiciones de servicio para afiliados",
  },
  {
    key: "隐私政策",
    value: "Política de privacidad",
  },
  {
    key: "表格",
    value: "Formulario",
  },
  {
    key: "用户指南",
    value: "Guía de usuario",
  },
  {
    key: "常见问题解答",
    value: "Solución de problemas frecuentes ",
  },

  {
    key: "加载中",
    value: "Cargando",
  },
  {
    key: "共",
    value: "Total",
  },
  {
    key: "个产品",
    value: "Productos",
  },
  {
    key: "衣服",
    value: "Ropa",
  },
  {
    key: "商品种类",
    value: "Tipo de producto",
  },
  {
    key: "数量总计",
    value: "Cantidad total",
  },
  {
    key: "商品库",
    value: "Biblioteca de productos",
  },
  {
    key: "海上运输",
    value: "Transporte marítimo",
  },
  {
    key: "操作菜单",
    value: "Menú",
  },
  {
    key: "全部的费用",
    value: "Gasto total",
  },
  {
    key: "暂无优惠券",
    value: "Sin cupón",
  },
  {
    key: "欧元",
    value: "Euro",
  },
  {
    key: "无佣金",
    value: "Sin comisión",
  },
  {
    key: "中国所有费用",
    value: "Gasto total de China",
  },
  {
    key: "商品价值",
    value: "Valor del producto",
  },
  {
    key: "国际物流预估",
    value: "Transporte internacional estimado",
  },
  {
    key: "西班牙物流预估",
    value: "Transporte estimado de España",
  },
  {
    key: "反倾销税预估",
    value: "Aranceles estimado",
  },
  {
    key: "增值税预估",
    value: "IVA estimado",
  },
  {
    key: "预计总成本",
    value: "Coste total",
  },
  {
    key: "西班牙预估",
    value: "Estimacinones de España",
  },
  {
    key: "全部费用预估",
    value: "Gasto total estimado",
  },
  {
    key: "负责人回复",
    value: "Respuesta del responsable",
  },
  {
    key: "国际船运A",
    value: "Transporte Marítimo Internacional A",
  },
  {
    key: "问题图片",
    value: "Imagen problemática",
  },
  {
    key: "问题商品明细",
    value: "Detalle del producto problemático ",
  },
  {
    key: "添加选中商品",
    value: "Añadir los productos elegidos",
  },
  {
    key: "可提货数量",
    value: "Cantidad de mercancías disponible",
  },
  {
    key: "名称",
    value: "Nombre",
  },
  {
    key: "已提货数量",
    value: "Cantidaddemercancíasrecogidas",
  },
  {
    key: "计费数据",
    value: "Dato de facturación",
  },
  {
    key: "提示：配送单信息如有修改，请联系您的负责人员",
    value:
      "Atención: si corrige la información de transporte de pedido, contacte con su corresponsable por favor",
  },
  {
    key: "费用总计",
    value: "Gasto total",
  },

  {
    key: "收件公司",
    value: "Empresa receptora",
  },
  {
    key: "请输入收件公司",
    value: "Introduzca la empresa receptora",
  },
  {
    key: "请输入邮编",
    value: "Introduzca el código postal",
  },
  {
    key: "请输入地址",
    value: "Introduzca la dirección",
  },
  {
    key: "请输入联系人",
    value: "Introduzca un contacto",
  },
  {
    key: "请输入手机",
    value: "Introduzca el Número de móvil",
  },
  {
    key: "请输入电话",
    value: "Introduzca el Número de teléfono",
  },
  {
    key: "请输入电子邮箱",
    value: "Introduzca el correo electrónico",
  },
  {
    key: "请输入CNPJ",
    value: "Introduzca el CNPJ",
  },
  {
    key: "收货地址详情",
    value: "Detalles de la dirección de recepción",
  },

  {
    key: "个人通关",
    value: "Entregar personal",
  },
  {
    key: "西班牙物流费用",
    value: "Gastos logísticos en España",
  },
  {
    key: "增值税",
    value: "IVA",
  },
  // 夏冬说把反倾销税改成关税的翻译
  {
    key: "反倾销税",
    value: "Aranceles",
  },
  {
    key: "如地段价格显示不正确，报价完成后会更正。",
    value:
      "Si el precio del lote no se muestra correctamente se corregirá una vez completada la cotización.",
  },
  {
    key: "提示文字",
    value: "Parablas recordatorio",
  },
  {
    key: "部署更多",
    value: "Desplegar más",
  },
  {
    key: "提交",
    value: "Presentar",
  },
  {
    key: "汇款图片不能为空",
    value: "La imagen de envío no puede estar vacía",
  },
  {
    key: "待处理",
    value: "Por tratar",
  },
  {
    key: "日期",
    value: "Fecha",
  },
  {
    key: "余额",
    value: "Saldo",
  },
  {
    key: "修改姓氏",
    value: "Modificar el apellido",
  },
  {
    key: "确认修改",
    value: "Confirmar cambios",
  },
  {
    key: "修改唛头",
    value: "Modificación de la marca",
  },
  {
    key: "修改名字",
    value: "Modificar nombre",
  },
  {
    key: "修改NIF",
    value: "Modificar NIF",
  },
  {
    key: "基本信息",
    value: "Información básica",
  },
  {
    key: "修改",
    value: "Modificar",
  },
  {
    key: "邮箱地址",
    value: "Dirección de correo electrónico",
  },
  {
    key: "确认关闭?",
    value: "¿Confirmar apagado?",
  },
  {
    key: "信息未保存，是否确认退出",
    value: "¿Salir sin guardar los cambios?",
  },
  {
    key: "不能为空",
    value: "No puede estar vacío",
  },
  {
    key: "预计订单金额",
    value: "Cantidad estimada de pedido",
  },
  {
    key: "付款后账户剩余",
    value: "Saldo de la cuenta después del pago",
  },
  {
    key: "请上传支付截图",
    value: "Por favor, suba la captura de pantalla de pago",
  },

  {
    key: "请选择国家",
    value: "Seleccionar un país",
  },
  {
    key: "请填写确认码",
    value: "Rellene el Código de confirmación por favor",
  },
  {
    key: "需要信息",
    value: "Necesidad de información",
  },

  {
    key: "西班牙",
    value: "España",
  },
  {
    key: "请输入验证码",
    value: "Por favor,introduzca el Código de verificación",
  },
  {
    key: "使用条款和隐私政策还没有被同意。",
    value:
      "Las condiciones de uso y la política de privacidad no han sido acordadas",
  },
  {
    key: "请输入登录名",
    value: "Por favor,introduzca el nombre de usuario ",
  },
  {
    key: "返回重新编辑",
    value: "Volver a editar",
  },
  {
    key: "暂无库存",
    value: "Falta de stock",
  },
  {
    key: "秒后可重新发送验证码",
    value: "El Código de verificación se puede volver a enviar en segundos",
  },
  {
    key: "发送方式",
    value: "Método de transporte",
  },
  {
    key: "费用(€)",
    value: "Coste (€)",
  },
  {
    key: "箱号",
    value: "Número de caja",
  },
  {
    key: "实际重量",
    value: "Peso real",
  },

  {
    key: "高",
    value: "Alto",
  },
  {
    key: "当前箱号",
    value: "Número de caja actual",
  },
  {
    key: "番号",
    value: "número del producto mercancía",
  },
  {
    key: "图片",
    value: "fotografía",
  },
  {
    key: "该商品本箱装箱数",
    value: "Número de cajas de este Productos",
  },
  {
    key: "已付款待确认",
    value: "Pago pendiente de confirmación",
  },
  {
    key: "请选择预设收货地址",
    value: "Por favor, seleccione la dirección de recepción predeterminada",
  },
  {
    key: "操作失败",
    value: "Operación fallida",
  },

  {
    key: "邮箱、手机号或密码输入错误",
    value: "Buzón de correo, número de teléfono móvil o contraseña incorrecta",
  },
  {
    key: "销量",
    value: "volumen de la venta",
  },

  {
    key: "是否提交充值信息？",
    value: "¿Enviar información de recarga?",
  },
  {
    key: "请选择预设进口商",
    value: "Por favor, seleccione el importador predeterminado",
  },
  {
    key: "该商品因库存不足或其他原因已经找不到，请查看其他商品！",
    value:
      "¡Este artículo ya no se puede encontrar debido a la escasez de existencias u otras razones. Por favor, compruebe otros artículos!",
  },
  {
    key: "返回首页",
    value: "Volver a la página principal",
  },
  {
    key: "是否确认删除？",
    value: "¿Está seguro de borrar?",
  },
  {
    key: "已使用优惠券",
    value: "Cupón ya utilizado",
  },
  {
    key: "已使用代金券",
    value: "Voucher ya utilizado",
  },
  {
    key: "清关时间",
    value: "Tiempo de despacho de aduanas",
  },
  {
    key: "西班牙物流发货时间",
    value: "Tiempo de entrega logístico en España",
  },
  {
    key: "西班牙物流",
    value: "Logística en España",
  },
  {
    key: "rakumart代通关",
    value: "Por RAKUMART en lugar de despacho por la aduana",
  },
  {
    key: "未达到使用条件",
    value: "Condiciones de uso no satisfechas",
  },
  {
    key: "已删除",
    value: "Borrado",
  },
  {
    key: "已使用",
    value: "Usado",
  },
  {
    key: "摄影",
    value: "Fotografía",
  },
  {
    key: "摄影服务",
    value: "Haz clic para más detalles",
  },
  {
    key: "贴条形码",
    value: "Etiquetado con código de barras",
  },
  {
    key: "配套",
    value: "Empaquetar conjunto",
  },
  {
    key: "取布标",
    value: "Fotografía",
  },
  {
    key: "摄影",
    value: "Quitar etiqueta de la tela",
  },
  {
    key: "缝布标",
    value: "Coser etiqueta de tela",
  },
  {
    key: "挂吊牌",
    value: "Colocar etiquetas colgantes",
  },
  {
    key: "取吊牌",
    value: "Quitar etiquetas colgantes",
  },
  {
    key: "更换OPP袋",
    value: "cambiar bolsa de plástico, PP",
  },
  {
    key: "气泡膜包装",
    value: "proteger con bolsa de burbujas, PP",
  },
  {
    key: "单面印刷",
    value: "Hoja informativa impreso a una cara",
  },
  {
    key: "双面印刷",
    value: "Hoja informativa impreso a doble cara",
  },
  {
    key: "金额变动明细",
    value: "Detalles de cambio de cantidad",
  },
  {
    key: "元/个",
    value: "/unidad",
  },
  {
    key: "元/张",
    value: "/por hoja",
  },
  {
    key: "点击查看详情",
    value: "Haz clic para más detalles",
  },

  {
    key: "包括装OPP袋或者换OPP袋",
    value: "Incluye cambio de la bolsa de plástico, PP",
  },
  {
    key: "4种商品以上配套手续费另行商谈",
    value:
      "Los gastos de manipulación para más de 4 artículos se cotizan por separado",
  },
  {
    key: "请参照右侧图片",
    value: "Consulta la imagen de la derecha",
  },
  {
    key: "默认为A4纸张，其它类型纸张或小册子印刷请另行商谈",
    value:
      "Por defecto es papel DIN A4, otros tipos de papel o la impresión de folletos están disponibles bajo petición.",
  },
  {
    key: "默认为A4纸张，其它类型纸张或小册子印刷请另行商谈",
    value:
      "Por defecto es papel DIN A4, otros tipos de papel o la impresión de folletos están disponibles bajo petición.",
  },
  {
    key: "默认到货商品检查项目",
    value: "Comprobaciones a la llegada de los productos",
  },
  {
    key: "产品是否按订单发货",
    value: "Comprobación entrada del pedido",
  },
  {
    key: "外观有无损坏",
    value: "Daños visibles",
  },
  {
    key: "产品页面与产品是否存在较大差异？",
    value:
      "Si hay alguna diferencia importante entre la página del producto y el producto",
  },
  {
    key:
      "我们将针对到货的商品主要针对以上三点进行检查，问题的商品，我们将向客户反馈。",
    value:
      "Comprobaremos la llegada de la mercancía en los tres puntos principales mencionados.Cualquier ligera variación del producto será consultada con el cliente.",
  },
  {
    key: "大件商品请另行商谈",
    value: "Los artículos voluminosos, se cotizan por separado",
  },
  {
    key: "操作内容",
    value: "Contenido de práctica",
  },
  {
    key:
      "系统将根据您在本网站上累计出货的商品金额来决定手续费等级。不同的已出货的商品金额，对应着不同的会员等级。详情请参考下面的表格明细。",
    value:
      'Cobramos una comisión dependiendo del importe del pedido. Consulta la tabla siguiente para ver qué porcentaje corresponde a tu pedido.   "Se determinará la comisión dependiendo de la cantidad de transacciones que hayas generado.  Consulte la tabla siguiente para obtener más detalles.',
  },
  {
    key:
      "例如：您累计出货的商品金额超过50,000欧元，您之后再下的订单手续费比例将变为9％。",
    value:
      'Por ejemplo, si has enviado más de 50.000 euros, la tasa de comisión cambiará al 9% para los siguientes pedidos"',
  },
  {
    key: "采购商品金额",
    value: "Importe de la compra (€)",
  },
  {
    key: "签收时间",
    value: "Tiempo de firma",
  },
  {
    key: "海关",
    value: "Despacho de aduanas",
  },
  {
    key: "已通关",
    value: "Despacho de aduanas",
  },
  {
    key: "商品调整",
    value: "Ajuste del costo de los productos",
  },
  {
    key: "已收货并检品完成",
    value: "Mercancías recibidas y inspeccionadas completadas",
  },
  {
    key: "人工调整",
    value: "Ajustar a mano",
  },
  {
    key: "已打款",
    value: "pago terminado",
  },
  {
    key: "邮箱更改成功",
    value: "Cambio de correo electrónico exitoso",
  },
  {
    key: "是否同步到进口商",
    value: "Sincronización con el importador",
  },
  {
    key: "是否同步到收件人",
    value: "Si copiarlo al destinatario",
  },
  {
    key: "点击选择附加服务",
    value: "Hacer clic para seleccionar servicios adicionales",
  },
  {
    key: "您可以在这边添加商品备注",
    value: "Aquí puedes añadir notas sobre tu producto.",
  },
  {
    key: "该订单状态不可支付",
    value: "Pedido pagado correctamente",
  },
  {
    key: "该订单已成功支付",
    value: "Pedido pagado correctamente",
  },
  {
    key: "该配送单状态不可支付",
    value: "El pedido ha pagado",
  },

  {
    key: "为编辑",
    value: "1",
  },
  {
    key: "为编辑",
    value: "1",
  },
  {
    key: "为编辑",
    value: "1",
  },
  {
    key: "为编辑",
    value: "1",
  },
  {
    key: "为编辑",
    value: "1",
  },
  {
    key: "自动签收时间",
    value: "1Timepo de firma automática",
  },
  {
    key: "访问",
    value: "Acceder",
  },
  {
    key: "同意",
    value: "acceder",
  },
  {
    key: "可用",
    value: "Disponible",
  },
  {
    key: "过期",
    value: "Caduco",
  },
  {
    key: "已过期",
    value: "Caduco",
  },
  {
    key: "有效期",
    value: "Período de validez",
  },
  {
    key: "今日到期",
    value: "Caduca hoy",
  },
  {
    key: "订单金额",
    value: "El importe de pedido",
  },
  {
    key: "店铺加载中",
    value: "La tienda se está cargando",
  },
  {
    key: "请输入6-16位包含大小写字母和数字的字符串",
    value:
      "Introduzca una cadena de 6 - 16 bits que contenga letras  mayúsculas y minúsculas y números",
  },
  {
    key: "提出配送单请将地址填写完整",
    value: "Rellena la dirección para enviar un albarán de entrega",
  },
  {
    key: "提出数不能超过可提出数",
    value: "El número mencionado no puede exceder el disponible",
  },
  {
    key: "此店铺暂无商品",
    value: "No se pueden mostrar los productos de este proveedor",
  },
  {
    key: "选择通关方式",
    value: "Elección del método de despacho de Aduanas",
  },
  {
    key: "商品调整",
    value: "Ajuste de mercancías",
  },
  {
    key: "立即生成",
    value: "Generar inmediatamente",
  },
  {
    key: "点击选择附加服务",
    value: "Hacer clic para seleccionar servicios adicionales",
  },
  {
    key: "您可以在这边添加商品备注",
    value: "Aquí usted puede añadir notas de producto",
  },
  {
    key: "未查找到该账号，请核对后再尝试或联系客服处理",
    value:
      "Esta cuenta no fue encontrada, por favor verifique antes de intentarlo o Póngase en contacto con el Servicio de atención al cliente.",
  },
  {
    key: "发票上传",
    value: "Subida de facturas",
  },
  {
    key: "已发货",
    value: "Enviado",
  },
  {
    key: "付款成功",
    value: "Pago realizado correctamente",
  },
  {
    key: "描述",
    value: "Descripción",
  },
  {
    key: "RAKUMART商品采购服务的描述",
    value: "Descripción del Servicio de adquisición de bienes RAKUMART",
  },
  {
    key: "通用操作程序的描述",
    value: "Descripción de los procedimientos operativos comunes",
  },
  {
    key: "客户个人中心的功能描述",
    value: "Descripción de la función del Centro personal del cliente",
  },
  {
    key: "账户余额不足",
    value: "Saldo insuficiente de la cuenta",
  },
  {
    key: "客户提现",
    value: "Retirada del cliente",
  },
  {
    key: "西班牙物流单号",
    value: "Número de orden logístico en España",
  },
  {
    key: "发送时间",
    value: "Tiempo de envío",
  },
  {
    key: "接待时间",
    value: "Hora de recepción",
  },
  {
    key: "西班牙物流方式",
    value: "Modo logístico en España",
  },
  {
    key: "是否取消选中的订单",
    value: "¿Si cancelar el pedido seleccionado?",
  },
  {
    key: "签收",
    value: "Firmar",
  },
  {
    key: "是否签收选中的商品？",
    value: "¿Si firma por el artículo seleccionado?",
  },
  {
    key: "签收成功",
    value: "Firmado con éxito",
  },
  {
    key: "手机号更改成功",
    value: "Número de teléfono cambiado con éxito",
  },
  {
    key: "加入商品库成功",
    value: "Añadido a la biblioteca de productos",
  },
  {
    key: "最低汇款金额",
    value: "Importe mínimo de las remesas",
  },
  {
    key: "商品库为空",
    value: "Almacén está vacío",
  },
  {
    key: "已取消签收",
    value: "Recibido cancelado",
  },
  {
    key: "查看更多",
    value: "Ver más",
  },

  {
    key: "注册帐户",
    value: "Registro de cuentas",
  },
  {
    key: "搜索产品",
    value: "Búsqueda de productos",
  },
  {
    key: "如何下订单",
    value: "Cómo hacer un pedido",
  },
  {
    key: "如何提交交付请求",
    value: "Cómo presentar una solicitud de entrega",
  },
  {
    key: "订单付款",
    value: "Pago de pedidos",
  },
  {
    key: "修改订单和交货订单",
    value: "Modificación de órdenes y órdenes de entrega",
  },
  {
    key: "发票生成失败，请检查您是否有默认地址，如无法解决，请直接联系负责人",
    value:
      "Falla la generación de la factura, examine si tiene una dirección válida, si no se puede resolver, comuníquese directamente con la persona a cargo",
  },
  {
    key: "主页",
    value: "Página principal",
  },
  {
    key: "订单管理",
    value: "Gestión de pedidos ",
  },
  {
    key: "资本项目",
    value: "Cuenta de capital ",
  },
  {
    key: "最喜欢的",
    value: "Favoritos",
  },
  {
    key: "个人数据",
    value: "Datos personal ",
  },
  {
    key: "国家运输",
    value: "Transporte Nacional",
  },
  {
    key: "与费用支付有关的问题",
    value: "Cuestiones relacionadas con el pago de tasas",
  },
  {
    key: "货物运输的清关问题",
    value: "Problemas en el despacho aduanero del transporte de mercancías",
  },
  {
    key: "关于商店和产品",
    value: "Sobre la tienda y los productos",
  },
  {
    key: "货物的售后加工",
    value: "Procesamiento posventa de bienes",
  },
  {
    key: "规则协议",
    value: "Reglas convencionales",
  },
  {
    key: "西班牙国内物流",
    value: "Logística en España",
  },
  {
    key: "国际物流",
    value: "Logística internacional",
  },
  {
    key: "全部通知",
    value: "Todas las notificaciones",
  },
  {
    key: "已读",
    value: "Leído",
  },
  {
    key: "未读",
    value: "No leído",
  },
  {
    key: "通知",
    value: "Notificación",
  },
  {
    key: "是否确认删除选中的通知",
    value: "Si eliminar la notificación seleccionada",
  },
  {
    key: "免手续费-1次",
    value: "Comisión gratuita-1 vez",
  },
  {
    key: "删除(购物车)",
    value: "Eliminar",
  },
  {
    key: "删除提示",
    value: "¿Seguro que quieres hacer esto?",
  },
  {
    key: "提交失败",
    value: "Error de envío",
  },
  {
    key: "提现成功",
    value: "Retiro exitoso",
  },
  {
    key: "国际运费模拟",
    value: "Simulación de los gastos internacionales",
  },
  {
    key: "估计物流成本",
    value: "Costo logístico estimado：",
  },
  {
    key: "估计重量(KG)",
    value: "Peso estimado(KG):",
  },
  {
    key: "预计商品总体积（m³）",
    value: "Volumen total estimado de mercancías (m³)",
  },
  {
    key: "重新输入",
    value: "Reingresar",
  },
  {
    key: "计算",
    value: "Calcular",
  },
  {
    key: "预计国际运费:",
    value: "Costo estimado de envío internacional：",
  },
  {
    key: "预计总物流成本:",
    value: "Costo logístico total estimado:",
  },
  {
    key: "税号/身份证号",
    value: "NIF / DNI",
  },
  {
    key: "在西班牙的估计运费:",
    value: "Costo estimado de envío en España：",
  },
  {
    key: "提出数量不能小于1",
    value: "La cantidad no puede ser menor que 1",
  },
  {
    key: "公司",
    value: "Empresa",
  },

  {
    key: "法律条件",
    value: "Condiciones legales",
  },
  {
    key: "Cookie 政策",
    value: "Política de cookies",
  },
  {
    key: "一般条件",
    value: "Condiciones generales",
  },
  {
    key: "用户们",
    value: "Usuarios",
  },
  {
    key: "支持",
    value: "Soporte",
  },
  {
    key: "免费注册",
    value: "Regístrate gratis",
  },
  {
    key: "订单跟踪",
    value: "Seguimiento de pedidos",
  },
  {
    key: "信息和费率",
    value: "Información y tarifas",
  },
  {
    key: "费用和佣金",
    value: "Tarifas y comisiones",
  },
  {
    key: "退货和退款",
    value: "Devoluciones y reembolsos",
  },
  {
    key: "会员服务(页脚)",
    value: "Servicios para miembros",
  },
  {
    key: "摄影服务(页脚)",
    value: "Servicio de fotografía",
  },
  {
    key: "其他",
    value: "Otro",
  },
  {
    key: "提交失败",
    value: "Práctica fracasada",
  },
  {
    key: "成为Rakumart的会员",
    value: "Hazte miembro de Rakumart",
  },
  {
    key: "搜索并支付产品的第一次付款",
    value: "Busca y realiza el primer pago de los productos",
  },
  {
    key: "质量控制和储存在我们的中国仓库",
    value: "Control de calidad y almacenaje en nuestro Warehouse de China",
  },
  {
    key: "第二次支付运费和国际航运",
    value: "Segundo pago para el flete y envío internacional",
  },
  {
    key: "遵循运输过程并接收您的产品",
    value: "Sigue el proceso de envío y recibe tus productos",
  },
  {
    key: "要继续，请将产品添加到购物车",
    value: "Para continuar añade productos al carrito",
  },
  {
    key: "现在入住",
    value: "Regístrate ahora",
  },
  {
    key: "选择业务类型",
    value: "Selecciona el tipo de negocio",
  },
  {
    key: "选择行业",
    value: "Selecciona el sector",
  },
  {
    key: "给你的建议",
    value: "Recomendaciones para ti",
  },
  {
    key: "查看更多类别",
    value: "Ver más categorías",
  },
  {
    key: "您在订单中要求的服务或特殊要求",
    value: "Servicios o requitos especiales que has solicitado en tu pedido",
  },
  {
    key: "如果您有任何问题，请通过RakuChat与专家代理联系。",
    value:
      "Si tienes cualquier duda contacta por RakuChat con un agente experto.",
  },
  {
    key: "特殊要求的意见和备注",
    value: "¿Necesitas servicios o requisitos especiales en tus productos?",
  },
  {
    key: "如果您需要更改运输信息或运输方式，请与代理联系。",
    value:
      "Si necesitas cambiar la información de envío o método de transporte contacta con un agente.",
  },
  {
    key: "服务或特殊要求",
    value: "Servicios o requisitos especiales",
  },
  {
    key: "写下你的产品需要什么额外的服务或特殊要求",
    value:
      "Escribe que servicios adicionales o requisitos especiales necesitan tus productos",
  },
  {
    key: "例如:-定制包装，-产品定制…",
    value: "ej: - packaging personalizado , - personalización de producto…",
  },
  {
    key: "一位经验丰富的代理将与您联系以确定细节",
    value:
      "Un agente experto se pondrá en contacto contigo para concretar los detalles",
  },
  {
    key: "此字段为必填项",
    value: "Este campo es obligatorio",
  },
  {
    key: "产品支出总额",
    value: "Total a pagar",
  },
  {
    key: "产品费用细目",
    value: "Desglose de los costes",
  },

  {
    key: "佣金",
    value: "Comisión",
  },
  {
    key: "额外服务",
    value: "Servicios adicionales",
  },
  {
    key: "运费",
    value: "Coste del envío",
  },
  {
    key: "进口总成本的成本细目。",
    value: "Desglose de costes del total de gastos de importación.",
  },
  {
    key: "估计运费",
    value: "Coste del envío estimado",
  },
  {
    key: "预计交货时间",
    value: "Tiempo de transporte estimado",
  },
  {
    key: "估计总进口成本(包括物流和税收)的成本细目",
    value:
      "Desglose de costes del total estimado  de gastos de importación (incluyendo logística e impuestos)",
  },
  {
    key: "估计国际运输",
    value: "Transporte internacional estimado",
  },
  {
    key: "国际运输",
    value: "Transporte internacional",
  },
  {
    key: "对西班牙的估计",
    value: "Estimacinones de España",
  },
  {
    key: "西班牙的运费",
    value: "Transporte en España",
  },
  {
    key: "反倾销关税",
    value: "Arancel antidumping",
  },
  {
    key: "估计反倾销税",
    value: "Aranceles estimado",
  },
  {
    key: "估计增值税",
    value: "IVA estimado",
  },
  {
    key: "估计总开支",
    value: "Gasto de envío total estimado",
  },
  {
    key: "总运费",
    value: "Gasto de envío total",
  },
  {
    key: "为什么现在估计装运?",
    value: "¿Por qué ahora el envío es estimado?",
  },
  {
    key: "订单金额必须大于",
    value: "El importe del pedido debe ser superior a ",
  },
  {
    key: "有效期",
    value: "Período de validez",
  },
  {
    key: "最佳选择",
    value: "MEJOR OPCIÓN",
  },
  {
    key: "运费什么时候付款?",
    value: "¿Por qué ahora el envío es estimado?",
  },
  {
    key: "选择装运的运输类型",
    value: "Selecciona el tipo de transporte del envío",
  },
  {
    key: "一个订单最多添加60个商品",
    value: "Añadir hasta 60 artículos a un pedido",
  },
  {
    key:
      "为了支付你的产品，在我们给你一个估计的运输报价之前，我们必须知道你的订单中包含的项目是否受到进出口限制，工厂的可用性，尺寸，产品的确切重量，等等。",
    value:
      "Para poder pagar tus productos, antes debemos ofrecerte una cotización estimada de envío, para ello debemos conocer si los artículos incluidos en tu pedido están sujetos a restricciones de exportación/importación, la disponibilidad en fábrica, las dimensiones, el peso exacto de los productos, etc.",
  },
  {
    key:
      "谢谢你。您的订单细节已正确确认。在不到24小时内，您将收到一封电子邮件，详细说明可能的运输方式的估计运输费用。",
    value:
      "Gracias. Los detalles de tu pedido se han confirmado correctamente.En menos de 24 horas recibirás un email con los detalles de los costes estimados de envío para los métodos de transporte posibles.",
  },
  {
    key: "看到产品",
    value: "Ver producto",
  },
  {
    key: "总价格",
    value: "Precio total",
  },
  {
    key: "报价单位数量",
    value: "Cantidad de unidades a cotizar",
  },
  {
    key: "更换产品",
    value: "Reemplazar producto",
  },
  {
    key: "已理解",
    value: "Entendido",
  },
  {
    key: "产品不可用",
    value: "Producto no disponible",
  },
  {
    key: "替代产品",
    value: "Producto de reemplazo",
  },
  {
    key: "正在进行的报价",
    value: "Cotización en marcha",
  },
  {
    key: "Únete 和 Rakumart",
    value: "Únete a Rakumart",
  },
  {
    key: "开始享受从中国进口Rakumart的好处吧。",
    value:
      "Empieza a disfrutar de las ventajas de importar desde China con Rakumart.",
  },
  {
    key: "发现所有的好处",
    value: "Descubre todas las ventajas",
  },
  {
    key: "注册或登入",
    value: "Registrarse o iniciar sesión",
  },
  {
    key: "谢谢你。您的请求细节已成功确认。",
    value:
      "Gracias. Los detalles de tu solicitud se han confirmado correctamente.",
  },
  {
    key: "邮件箱",
    value: "Email",
  },
  {
    key: "省份2",
    value: "Pais",
  },
  {
    key:
      "在不到24小时内，您将收到一封电子邮件，其中包含产品付款的详细信息和可能的运输方式的估计运费。",
    value:
      "En menos de 24 horas recibirás un email con los detalles para el pago de productos y los costes estimados de envío para los métodos de transporte posibles.",
  },
  {
    key:
      "为了提供一个估计的运输报价，我们必须知道产品的尺寸和确切的重量。这就是为什么我们联系制造商，并计算您的订单的估计运输成本。",
    value:
      "Para poder pagar tus productos, antes debemos ofrecerte una cotización estimada de envío, para ello debemos conocer si los artículos incluidos en tu pedido están sujetos a restricciones de exportación/importación, la disponibilidad en fábrica, las dimensiones,el peso exacto de los productos, etc.",
  },
  {
    key: "订单摘要",
    value: "Resumen del pedido",
  },
  {
    key: "产品",
    value: "Productos",
  },
  {
    key: "购买金额",
    value: "Cantidad de compra",
  },
  {
    key: "如何运作",
    value: "¿Cómo funciona?",
  },
  {
    key: "我们联系制造商",
    value: "Contactamos con el fabricante",
  },
  {
    key: "我们联系制造商要求必要的信息，并能够估计产品的运输成本。",
    value:
      "Contactamos con el fabricante para solicitar la información necesária y poder estimar los costes de envío de los productos.",
  },
  {
    key: "你会在24小时内收到信息",
    value: "Recibes la información en menos de 24 horas",
  },
  {
    key: "我们通知您所有类型的估计费用 海运(空运、海运和陆运)。",
    value:
      "Te informamos de la disponibilidad de los productos y los costes de envío (aereo, ferrocarril, marítimo y terrestre).",
  },
  {
    key: "产品的付款",
    value: "Pago de los productos",
  },
  {
    key: "一旦知道估计的运输成本，您可以正式购买您的订单的产品。",
    value:
      "Una vez conocido el estimado de costes de envío podrás formalizar la compra de los productos de tu pedido.",
  },
  {
    key: "为什么装运是估计的?",
    value: "¿Cuándo se paga el envío?",
  },
  {
    key: "你有什么问题吗?",
    value: "¿Tienes alguna duda?",
  },
  {
    key: "电子邮箱为",
    value: "Escríbenos a",
  },
  {
    key: "使用聊天与我们沟通。",
    value: "Utiliza el chat para comunicarte con nosotros.",
  },
  {
    key: "通过Whastapp给我们写信。",
    value: "Escríbenos por Whastapp.",
  },
  {
    key: "银行转帐",
    value: "Transferencia bancaria",
  },
  {
    key: "把你的订单转到我们的银行账户。",
    value: "Transfiere el dinero de tu pedido a nuestra cuenta bancaria.",
  },
  {
    key: "订单正在处理中",
    value: "La orden se procesa",
  },
  {
    key: "请允许1至2个工作日处理订单。",
    value: "Espera entre 1 y 2 días laborables para que se procese la orden.",
  },
  {
    key: "我们进行购买",
    value: "Efectuamos la compra",
  },
  {
    key: "我们购买您的产品并将其送到我们的仓库。",
    value:
      "Realizamos la compra de tus productos y la enviamos a nuestro almacén.",
  },
  {
    key: "产品付款",
    value: "Pago de productos",
  },
  {
    key: "Envio仓库",
    value: "Envío a almacén Rakumart",
  },
  {
    key: "进口商联系人",
    value: "Datos de facturación",
  },
  {
    key: "付款方式",
    value: "Método de pago",
  },
  {
    key: "银行转帐",
    value: "Transferencia bancaria",
  },

  {
    key: "信用卡",
    value: "Tarjeta de crédito",
  },
  {
    key: "接收银行信息",
    value: "Información del Banco Destinatario",
  },
  {
    key:
      "使用以下银行信息通过您的网上银行或移动应用程序进行转账。一旦订单被处理，就会进行购买。",
    value:
      "Utiliza los siguientes datos bancarios para realizar la transferencia mediante tu banca en línea o aplicación móvil. Una vez la orden sea procesada se efectuará la compra.",
  },
  {
    key: "使用订单号作为付款概念/参考:",
    value: "Usa el número de pedido como concepto/referencia de pago:",
  },
  {
    key: "如果您想购买和发送此产品的样品，请联系专家代理。",
    value:
      "Si deseas comprar y envíar muestras de este producto contacta con un agente experto.",
  },
  {
    key: "与代理联系",
    value: "Contacta con un agente",
  },
  {
    key:
      "这一细分包括产品的质量控制、合同规定的额外服务，以及在适当情况下合同规定的其他费用。",
    value:
      "En este desglose se incluye el control de calidad de los productos, los servicios adicionales contratados y si es el caso otras tarifas contratadas.",
  },
  {
    key:
      "为了提供最好的进口报价，一旦产品到达我们的仓库，就会计算出准确的运费和其他费用报价，所以现在你只支付产品。",
    value:
      "Para poder ofrecer la mejor oferta posible de importación, la cotización exacta del flete y otros gastos son calculados una vez el producto llega a nuestros almacenes, por eso, ahora solo pagarás los productos.",
  },
  {
    key: "由于货物的分组和其他数据，我们可以提供更好的装运的最终报价。",
    value:
      "Gracias al grupaje de mercancias y otros datos, podemos ofrecer una mejor cotización final para el envío.",
  },
  {
    key: "订单号",
    value: "Número de pedido ",
  },
  {
    key: "收件人名称",
    value: "Nombre del destinatario",
  },
  {
    key: "银行账户",
    value: "Cuenta bancaria",
  },
  {
    key: "SWIFT帐户",
    value: "Cuenta SWIFT",
  },
  {
    key: "银行名称",
    value: "Nombre del banco",
  },
  {
    key: "公司地址cz",
    value: "Dirección empresa",
  },
  {
    key: "进行付款",
    value: "Confirmar transferencia",
  },
  {
    key: "银行卡进行付款",
    value: "Realizar el pago",
  },
  {
    key: "Redsys Santander支付网关",
    value: "Pasarela de pago Redsys Santander",
  },
  {
    key: "Redsys",
    value: "Tarjeta de crédito",
  },
  {
    key: "您将被重定向到Redsys Santander支付网关",
    value: "Serás redirigido/a a la pasarela de pago de Redsys Santander",
  },
  {
    key: "优势",
    value: "Ventajas",
  },
  {
    key: "如何工作",
    value: "Cómo funciona",
  },
  {
    key: "感谢您的注册!现在你可以开始享受Rakumart提供的所有好处了。",
    value:
      "¡Gracias por registrarte! Ahora ya puedes empezar a disfrutar de todas las ventajas que te ofrece Rakumart.",
  },
  {
    key: "我知道并同意本网站的隐私政策条款和条件。",
    value:
      "Conozco y estoy de acuerdo con los términos de la Política de Privacidad y Condiciones de esta web.",
  },
  {
    key: "订单成功完成",
    value: "Pedido Realizado Correctamente",
  },
  {
    key: "控制面板",
    value: "Ver Panel de Control",
  },
  {
    key: "面板控制",
    value: "Panel de control",
  },
  {
    key: "修改数据",
    value: "Modificar datos",
  },
  {
    key: "接下来是什么?",
    value: "¿Qué es lo siguiente?",
  },
  {
    key: "我们从工厂接收你们的产品",
    value: "Recepcionamos tus productos desde fábrica",
  },
  {
    key: "质量控制和处理",
    value: "Control de Calidad y manipulación",
  },
  {
    key: "购买您的产品的过程已经开始。",
    value: "El proceso de compra de tus productos ya ha empezado.",
  },
  {
    key: "我们期待在我们的仓库收到你们的产品。",
    value: "Esperamos a recibir tus productos en nuestro almacén.",
  },
  {
    key: "我们收到产品并开始执行质量控制和额外服务(如有需要)。",
    value:
      "Recepcionamos los productos y procedemos a realizar el <br /> control de calidad y los servicios adicionales si requiere.",
  },
  {
    key: "运费和运输报价",
    value: "Cotización de flete y envío",
  },
  {
    key: "我们计算最好的运费报价，并在5-7天内装运你的货物。",
    value:
      "Calculamos la mejor cotización para el flete y envío de tus mercancías en un plazo de 5-7 días.",
  },
  {
    key: "运费的支付",
    value: "Pago del envío",
  },
  {
    key: "付款后，您的货物将被送到我们在巴塞罗那的仓库和您的送货地址。",
    value:
      "Realiza el pago del envío y tu mercancía se pondra en camino hacia nuestros almacenes en España y a tu dirección de envío. ",
  },
  {
    key: "修改收货地址",
    value: "Modificar dirección de envío",
  },

  {
    key: "城市",
    value: "Ciudad",
  },
  {
    key: "公司(可选)",
    value: "Empresa (opcional)",
  },
  {
    key: "支付地址",
    value: "Dirección postal",
  },
  {
    key: "全名",
    value: "Nombre completo",
  },
  {
    key: "国际航运的最终报价",
    value: "Cotización final de envío internacional",
  },
  {
    key: "进口总成本细目(包括物流和税收)",
    value:
      "Desglose de costes del total de gastos de importación (incluyendo logística e impuestos)",
  },
  {
    key: "应付总额",
    value: "Total a pagar",
  },
  {
    key: "国际航运付款",
    value: "Pago de envío internacional",
  },
  {
    key: "进口",
    value: "Importación",
  },
  {
    key: "请联系客服修改配送单地址",
    value:
      "Póngase en contacto con servicio al cliente para modificar la dirección de entrega",
  },
  {
    key: "替换商品",
    value: "Artículo de reemplazo",
  },
  {
    key: "库存不足商品",
    value: "Artículos agotados",
  },
  {
    key: "Raku消息",
    value: "RakuChat",
  },
  {
    key: "库存数量不足",
    value: "No hay suficiente stock",
  },
  {
    key: "可选择替换的商品",
    value: "Elementos que pueden ser reemplazados",
  },
  {
    key: "Rakumart，我们帮助您从中国进口",
    value: "Rakumart, te ayudamos a importar desde China",
  },
  {
    key:
      "Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。",
    value:
      "Rakumart te permite disfrutar de un fácil acceso a servicios de importación desde China ‘todo en uno’ que van desde la compra en fábrica, la inspección de calidad, servicios adicionales y el envío internacional.",
  },
  {
    key: "全面、便捷的中国进口管理。",
    value: "Gestión completa y fácil de las importaciones desde China.",
  },
  {
    key: "专注于你的事业",
    value: "Enfócate en tu negocio",
  },
  {
    key: "我们为您进口并优化所有物流成本",
    value: "Importamos por ti y optimizamos todos los costes logísticos",
  },
  {
    key: "完整的工厂物流",
    value: "Logística completa desde fábrica",
  },
  {
    key: "后勤服务",
    value: "Servicio logístico",
  },
  {
    key: "公平和有价值的佣金为您的业务",
    value: "Comisiones justas y de gran valor para tu negocio",
  },
  {
    key: "多消费，少佣金",
    value: "Más consumo, menos comisión",
  },
  {
    key: "事务",
    value: "Comisiones",
  },
  {
    key: "为所有产品需求提供额外服务",
    value: "Servicios adicionales para todas las necesidades de producto",
  },
  {
    key: "服务和整体解决方案",
    value: "Servicios y  soluciones integrales",
  },
  {
    key: "临时保存的",
    value: "Guardado temporal",
  },
  {
    key: "出货完成",
    value: "Han sido sacados del almacén.",
  },
  {
    key: "购买中",
    value: "En compra",
  },
  {
    key: "提出数量不能大于可提出数",
    value: "La cantidad recogida no puede exceder de la cantidad máxima",
  },
  {
    key: "请在以下字段填写您的信息。",
    value: "Rellena los siguientes campos con tu información. ",
  },
  {
    key: "所有字段都是必需的。",
    value: "Todos los campos son obligatorios.",
  },
  {
    key: "创建新帐户",
    value: "Crear cuenta",
  },
  {
    key: "已经有账户了?登录",
    value: "¿Ya tienes cuenta? Inicia sesión",
  },
  {
    key: "请至少选择一项物流方式",
    value: "Seleccione al menos un método de envío",
  },
  {
    key: "商品标题",
    value: "Titulo del producto",
  },
  {
    key: "商品规格",
    value: "Especificaciones del producto",
  },
  {
    key: "颜色",
    value: "color",
  },
  {
    key: "选择该商品",
    value: "seleccione este elemento",
  },
  {
    key: "回到顶部",
    value: "Volver al inicio",
  },
  {
    key: "你的最爱",
    value: "Tus favoritos",
  },
  {
    key: "资料库",
    value: "Tu biblioteca ",
  },
  {
    key: "请输入CIF/NIF",
    value: "Introtuce tu CIF/NIF ",
  },
  {
    key: "数据获取错误",
    value: "error de obtención de datos",
  },
  {
    key: "跟踪号码",
    value: "Número de entrega",
  },
  {
    key: "注意:同一订单只能使用同一类型的优惠券。",
    value:
      "Atención: sólo se puede utilizar un mismo tipo de cupón en el mismo pedido.",
  },
  {
    key: "我是公司的",
    value: "Soy empresa",
  },
  {
    key: "我是自主的",
    value: "Soy autónomo",
  },
  {
    key: "我是特别的",
    value: "Soy particular",
  },
  {
    key: "家庭和装饰部门",
    value: "Sector hogar y decoración",
  },
  {
    key: "体育部门",
    value: "Sector deportes",
  },
  {
    key: "健身行业",
    value: "Sector fitness",
  },
  {
    key: "儿童部门",
    value: "Sector infantil",
  },
  {
    key: "办公室和文具部门",
    value: "Sector oficina y papelería",
  },
  {
    key: "酒店业",
    value: "Sector hostelería",
  },
  {
    key: "宠物/动物部门",
    value: "Sector mascotas/animales",
  },
  {
    key: "化妆品行业",
    value: "Sector cosmética",
  },
  {
    key: "技术部门",
    value: "Sector tecnología",
  },
  {
    key: "美容/美发部门",
    value: "Sector belleza / peluquería",
  },
  {
    key: "工具和专业部门",
    value: "Sector herramientas y profesional",
  },
  {
    key: "汽车行业",
    value: "Sector automóviles",
  },
  {
    key: "时尚与配饰行业",
    value: "Sector moda y accesorios",
  },
  {
    key: "安全部门",
    value: "Sector seguridad",
  },
  {
    key: "多产品部门或集市",
    value: "Sector multi-producto o bazar",
  },
  {
    key: "其他部门",
    value: "Otro sector",
  },
  {
    key: "批量回复",
    value: "Listado de indicendias",
  },
  {
    key: "形象",
    value: "Imagen",
  },
  {
    key: "帮助",
    value: "Ayuda",
  },
  {
    key: "帮助中心",
    value: "Centro de ayuda",
  },
  {
    key: "快速用户指南",
    value: "Guía rápida de usuario",
  },
  {
    key: "保修及售后服务",
    value: "Garantías y posventa",
  },
  {
    key: "对客户的关注",
    value: "Atención al cliente",
  },
  {
    key: "以前",
    value: "anteriores",
  },
  {
    key: "以下",
    value: "siguientes",
  },
  {
    key: "看来我们找不到你要找的东西。",
    value: "Parece que no podemos encontrar lo que estás buscando.",
  },
  {
    key: "你的搜索结果是",
    value: "Resultados de la búsqueda de",
  },
  {
    key: "关于订单数量的重要通知",
    value: "Aviso importante sobre el volumen del pedido",
  },
  {
    key: "这个订单的数量很少，请注意：",
    value: "El volumen de este pedido es bajo, por favor ten en cuenta:",
  },
  {
    key: "我们不进行零售销售。",
    value: "No realizamos venta minorista",
  },
  {
    key: "Rakumart是一个批发进口平台，不提供零售销售服务。",
    value:
      "Rakumart es una plataforma de importación al por mayor, no se proporcionan servicios de venta al detalle.",
  },
  { key: "最小运输量", value: "Volumen mínimo de transporte" },
  {
    key: "对于需要达到最低运输量的运输方式，订单总额必须达到或超过最低限制：",
    value:
      "El total del pedido debe igualar o superar el volumen mínimo para los métodos de transporte que lo requieran:",
  },
  {
    key: "（陆运：21kg / 海运：1m³ / 铁路运输：21kg）",
    value:
      "(Transporte terrestre: 21kg / Transporte marítimo: 1m³ / Transporte ferroviario: 21kg)",
  },
  { key: "进口成本", value: "Coste de la importación" },
  {
    key:
      "少量进口货物或未达到最低运输量的进口货物可能会增加总成本，导致该操作不具盈利性。",
    value:
      "Las importaciones de pocas unidades o que no superen el volumen mínimo, pueden repercutir en el coste total y no rentabilizar la operación.",
  },
  { key: "理解了", value: "Entendido" },
  { key: "至少选择一个产品", value: "Seleciona al menos un producto" },
  { key: "非常好！正在进行报价。", value: "¡Perfecto! Cotización en marcha" },
  {
    key:
      "谢谢。您的请求细节已经确认无误。在不到24小时内，您将收到一封电子邮件，其中包括订单产品的报价和支付细节。您还将收到可能的运输方法的预估运费报价。",
    value:
      "Gracias. Los detalles de tu solicitud se han confirmado correctamente. En menos de 24 horas recibirás un email con la cotización y los detalles para el pago de los productos del pedido. También recibirás la cotización estimada de envío para los métodos de transporte posibles.",
  },
  {
    key: "预估的运费报价。提示信息。可用的运输方式。取决于订单的特征。",
    value:
      "La cotización estimada de envío te permite tener una idea muy aproximada del coste de envío antes de realizar el pago de los productos. Los métodos de transporte elegibles dependerán de las características de tu pedido (dimensiones, peso exacto de los productos, etc.).",
  },
  { key: "下一步操作", value: "Siguientes pasos" },
  {
    key: "我们联系制造商要求必要的信息和报价的订单。",
    value:
      "Contactamos con el fabricante para solicitar la información necesaria y poder cotizar el pedido.",
  },
  { key: "什么时候付款?", value: "¿Cuando se paga el envío?" },
  {
    key: "专业买家和公司免费注册",
    value: "Registro gratuito para compradores profesionales y empresas",
  },
  {
    key: "在以下字段中填写您的业务或公司信息:",
    value:
      "Rellena los siguientes campos con la información de tu negocio o empresa:",
  },
  {
    key: "我们建议您花几分钟时间了解更多关于Rakumart的信息:",
    value:
      "Te recomendamos que dediques unos minutos a conocer un poco más Rakumart:",
  },
  {
    key: "Rakumart是做什么的?",
    value: "¿Qué hace Rakumart?",
  },
  {
    key: "Rakumart是如何运作的?",
    value: "¿Cómo funciona Rakumart?",
  },
  {
    key: "Rakumart的优势是什么?",
    value: "¿Qué ventajas ofrece Rakumart?",
  },
  {
    key: "估计总进口费用的成本细目。",
    value: "Desglose de costes del total estimado de gastos de importación.",
  },
  {
    key: "总产量",
    value: "Total productos",
  },
  {
    key: "等值附加费",
    value: "Recargo de equivalencia",
  },
  {
    key: "如果你是在特别等同附加费制度下纳税的，请在购买发票上的方框内打勾。",
    value:
      "Si tributas bajo el régimen especial de recargo de equivalencia, marca la casilla para se contemple en la factura de compra.",
  },
  {
    key: "产品成本细目",
    value: "Desglose de los costes de producto",
  },
  {
    key: "所有产品的总价",
    value: "Total de los productos",
  },
  {
    key: "产品付款及运输",
    value: "Pago de productos y envío",
  },
  {
    key: "产品付款及运输",
    value:
      "Selecciona esta opción para realizar el pago de los productos y el envío.",
  },
  {
    key: "选择此选项支付产品和运输。",
    value:
      "Selecciona esta opción para realizar el pago de los productos y el envío.",
  },
  {
    key: "我们向工厂下订单，一旦质量控制通过，货物就开始国际运输。",
    value:
      "Realizamos el pedido a fábrica y una vez superado el control de calidad, se procede al envío internacional de la mercancía.",
  },
  {
    key: "仅支付产品",
    value: "Pago solo de productos",
  },
  {
    key: "如果您只想支付产品的成本，请选择此选项。",
    value:
      "Seleccione esta opción si desea abonar únicamente el costo de los productos.",
  },
  {
    key:
      "运费报价不包括在内，一旦货物进入我们的仓库，您可以通过自己的货运代理管理运输。",
    value:
      "La cotización del envío no se incluirá, permitiéndote gestionar el transporte a través de tu propio transitario una vez la mercancía esté en nuestros almacenes.",
  },
  {
    key: "你也可以统一不同订单的货物，或者在另一个时间发货。",
    value:
      "También puedes unificar mercancías de distintos pedidos o realizar el envío en otro momento.",
  },
  {
    key: "产品总价",
    value: "Total productos",
  },
  {
    key: "运费总价",
    value: "Total envío",
  },
  {
    key: "产品的支出总额",
    value: "Gasto total del producto",
  },
  {
    key: "一旦订单报价，您可以通过银行转账或信用卡付款正式购买。",
    value:
      "Una vez cotizado el pedido podrás formalizar la compra mediante pago por trasferencia bancaria o tarjeta de crédito.",
  },
  {
    key: "订单付款",
    value: "Pago del pedido",
  },
  {
    key:
      "谢谢你。您的请求细节已成功确认。在不到24小时内，您将收到报价和订单付款细节的信息。您还将收到可能的运输方式的运输报价。",
    value:
      "Gracias.  Los detalles de tu solicitud se han confirmado correctamente.  En menos de 24 horas recibirás la información con la cotización y los detalles para el pago del pedido.  También recibirás la cotización de envio para los métodos de transporte posibles.",
  },
  {
    key:
      "符合条件的运输方式将取决于您的订单的特点(尺寸，产品的确切重量等)。如果订单没有超过任何运输方式的最小数量，它将出现在0。",
    value:
      "Los métodos de transporte elegibles dependerán de las características de tu pedido (dimensiones, peso exacto de los productos, etc.). Si el pedido no supera los volúmenes mínimos para algún método de transporte, este aparecerá a 0.",
  },
  {
    key: "谢谢，付款成功。很快你就会收到一封电子邮件，里面有确认和详细信息。",
    value:
      "Gracias, el pago se ha realizado correctamente. En breve recibirás un correo electrónico con la confirmación y los detalles.",
  },
  {
    key: "个人所得税",
    value: "Cuota R.E",
  },
  {
    key: "付款详情",
    value: "Detalles de pago",
  },
  {
    key: "选择或添加送货地址",
    value: "Selecciona o añade la dirección de entrega",
  },
  {
    key: "添加收货地址",
    value: "Añadir dirección de envío",
  },
  {
    key: "要求或增加额外服务",
    value: "Solicita requisitos o añade servicios adicionales",
  },
  {
    key: "专家代理将与您联系，审查您的申请，并在必要时澄清细节。",
    value:
      "Un agente experto se pondrá en contacto contigo para revisar tu solicitud y concretar los detalles si es necesario.",
  },
  {
    key: "需求类型",
    value: "Tipo de requisito",
  },
  {
    key: "你必须选择一个关于等价附加费的选项",
    value: "Debes elegir una opción sobre el Recargo de Equivalencia",
  },
  {
    key: "请选择具体的附加服务类型",
    value: "¡Seleccione un tipo específico de servicio complementario!",
  },
  {
    key: "图形验证码错误",
    value: "Código de verificación errónea",
  },
  {
    key: "刷新",
    value: "Actualizar",
  },
  {
    key: "图形验证码缺失",
    value: "Por favor, ingrese el código de verificación",
  },
  {
    key: "按人口分列的城市",
    value: "Población/Ciudad",
  },
  {
    key: "人口/城市",
    value: "Población/Ciudad",
  },
  {
    key: "输入您的人口/城市",
    value: "Introduce tu población/ciudad",
  },
  {
    key: "输入您公司或企业所在的城市/人口",
    value: "Introduce Ciudad/Población de tu empresa o negocio",
  },
  {
    key: "输入您的公司或企业所在的国家",
    value: "Introduce el País de tu empresa o negocio",
  },
  {
    key: "Rakumart经验",
    value: "Experiencia Rakumart",
  },
  {
    key: "这个简短的调查将帮助我们个性化您与我们的体验。",
    value:
      "Esta breve encuesta nos servirá para personalizar tu experiencia con nosotros",
  },
  {
    key: "商品的用途是什么?",
    value: "¿Cuál es la finalidad de la mercancía?",
  },
  {
    key: "个人/私人使用",
    value: "De uso personal / Propia",
  },
  {
    key: "分销和销售",
    value: "Distribución y venta",
  },
  {
    key: "继续",
    value: "Continuar",
  },
  {
    key: "什么样的销售你提供吗?",
    value: "¿Qué tipo de venta ofreces?",
  },
  {
    key: "批发商",
    value: "Mayorista",
  },
  {
    key: "零售商",
    value: "Minorista",
  },
  {
    key: "你的主要销售渠道是什么?",
    value: "¿Cuál es tu canal de venta prioritario?",
  },
  {
    key: "您可以选择多个选项",
    value: "Puedes seleccionar varias opciones",
  },
  {
    key: "电子商务",
    value: "E-Commerce",
  },
  {
    key: "亚马逊",
    value: "Amazon",
  },
  {
    key: "实体店铺",
    value: "Tienda física",
  },
  {
    key: "其他平台",
    value: "Otras plataformas",
  },
  {
    key: "你每年的进口量是多少?",
    value: "¿Cuál es tu volumen de compra anual en importaciones?",
  },
  {
    key: "你从事进口业务多长时间了？",
    value: "¿Cuánto tiempo llevas importando?",
  },
  {
    key: "不到一年",
    value: "Menos de un año",
  },
  {
    key: "超过一年",
    value: "Más de un año",
  },
  {
    key: "回退",
    value: "Atrás",
  },
  {
    key: "验证帐户",
    value: "Verificación de la cuenta",
  },
  {
    key: "请输入我们发送给您的验证码:",
    value:
      "Por favor, introduce el código de verificación que te hemos enviado a: ",
  },
  {
    key: "您输入的验证码不正确。请检查代码或点击接收另一个。",
    value:
      "El código de verificación que has introducido es incorrecto. Por favor revise el código, o bien haz click para recibir otro.",
  },
  {
    key: "校验",
    value: "Verificación",
  },
  {
    key: "检查我的验证码",
    value: "Verificar mi cuenta",
  },
  {
    key: "当订单金额为0时,无法付款",
    value: "Cuando la cantidad del pedido es 0,no se puede realizar el pago.",
  },
  {
    key: "超级工厂",
    value: "Superfábrica",
  },
  {
    key: "实力商家",
    value: "Proveedor TOP",
  },
  {
    key: "质量评分",
    value: "Calidad",
  },
  {
    key: "物流评分",
    value: "Gestión de envío",
  },
  {
    key: "问题解决评分",
    value: "Resolución de incidencias",
  },
  {
    key: "咨询评分",
    value: "Comunicación",
  },
  // -----------------
  {
    key: "显赫",
    value: "Destacados",
  },
  {
    key: "1688严选商品",
    value: "Productos destacados de 1688",
  },
  {
    key: "1688严选工厂",
    value: "Proveedores destacados de 1688",
  },
  {
    key: "严选工厂",
    value: "Proveedores destacados",
  },
  {
    key: "店铺评分",
    value: "Valoración del proveedor",
  },
  {
    key: "条件",
    value: "Condiciones",
  },
  {
    key: "七天无理由退换",
    value: "Devolución sin condiciones durante 7 días",
  },
  {
    key: "一件起发",
    value: "Pedido de muestra de una unidad",
  },
  {
    key: "分类",
    value: "Ordenar por",
  },
  {
    key: "月销售总额",
    value: "Total de ventas mensuales",
  },
  {
    key: "每月销售",
    value: "Ventas mensuales",
  },
  {
    key: "回购率",
    value: "Tasa de recompra",
  },
  {
    key: "单价排序",
    value: "Precio por unidad",
  },
  {
    key: "最低价格",
    value: "Precio más bajo",
  },
  {
    key: "最高价格",
    value: "Precio más alto",
  },
  {
    key: "看到商店",
    value: "Ver tienda",
  },
  {
    key: "品质保证",
    value: "Puntuación global",
  },
  {
    key: "清理",
    value: "Limpiar",
  },
  {
    key: "产品名称",
    value: "Nombre del producto",
  },
  {
    key: "下载图片",
    value: "Descargar imagenes",
  },
  {
    key: "下载所有的产品图片",
    value: "Descarga todas las imagenes del producto.",
  },
  {
    key: "你的一些产品无法上市",
    value: "Alguno de tus productos no se han podido cotizar",
  },
  {
    key: "订单号",
    value: "Número del pedido",
  },
  {
    key: "产品名",
    value: "Producto",
  },
  {
    key: "原因的影响",
    value: "Motivo incidencia",
  },
  {
    key: "取代",
    value: "Sustituir",
  },
  {
    key: "非替代",
    value: "No sustituir",
  },
  {
    key: "如果您不更换产品，产品成本将自动从您的订单总成本中扣除。",
    value:
      "Si no sustituyes el producto, el coste del producto se restará del coste total de tu pedido de forma automática. ",
  },
  {
    key: "如果您不确定，请与您的代理联系。",
    value: "Por favor, si no estás seguro, contacta con tu agente.",
  },
  {
    key: "联系代理",
    value: "Contactar con un agente",
  },
  {
    key: "Sustitución同样",
    value: "Sustitución en curso",
  },
  {
    key: "否",
    value: "No",
  },
  {
    key: "是",
    value: "Si",
  },
  {
    key: "价格范围",
    value: "Rango de precio",
  },
  {
    key: "CE认证",
    value: "Con certificado CE",
  },
  {
    key: "与证书",
    value: "Con certificado",
  },
  {
    key: "查看",
    value: "Ver todos",
  },
  {
    key: "您的产品已成功更换，感谢您信任Rakumart!",
    value:
      "Tú producto se ha sustituido con éxito ¡Gracias por confiar en Rakumart! ",
  },
  {
    key: "你的产品没有被替换。请检查您的订单。",
    value: "Tú producto no se ha sustituido. Por favor, revise su pedido.",
  },
  {
    key: "估价gobal",
    value: "Valoración gobal",
  },
  {
    key: "不可替代",
    value: "Sustitución no disponible",
  },
  {
    key: "我们很抱歉。我们还没有发现类似的产品。请联系我们的代理解决问题。",
    value:
      "Lo sentimos. No hemos encontrado ningún producto similar. Por favor, contacta con nuestros agentes para resolver la incidencia.",
  },
  {
    key: "更换产品说明",
    value: "Sustitución de un producto",
  },
  {
    key: "用功能和价格相似的产品替换你的侵权产品。",
    value:
      "Sustituye tu producto infractor con otro producto de características y precio similar.",
  },
  {
    key:
      "如果您取消更换产品，它将从您的购物车中删除，并自动从您的商品总额中扣除。",
    value:
      "Si cancelas la sustitución del producto, se eliminará de tu cesta y se restará automaticamente del importe total de tu mercancía.",
  },

  {
    key: "取消替换您的产品将从购物车中删除，并自动从您的订单中删除。",
    value:
      "Tu producto se eliminará de la cesta y se restará automáticamente de tu pedido.",
  },
  {
    key: "你的产品将被替换。",
    value: "Tu producto se sustituirá.",
  },
  {
    key: "您的订单价格将自动更新。",
    value: "El precio de tu pedido se actualizará automáticamente.",
  },
  {
    key: "未替代产品",
    value: "Producto no sustituido",
  },
  {
    key: "产品没有更换，您的订单总额已经扣除。",
    value:
      "El producto no se ha sustituido y se ha restado el importe del total de tu pedido.",
  },
  {
    key: "产品丢弃",
    value: "Productos descartados",
  },
  {
    key: "消除产品",
    value: "Eliminar producto",
  },
  {
    key: "将产品从购物车中移除。产品的金额将从订单的总成本中扣除。",
    value:
      "Elimina el producto de tu cesta. Se restará el importe del producto en el coste total del pedido.",
  },
  {
    key: "图像搜索",
    value: "Buscar por imagen",
  },
  {
    key: "介意聪明",
    value: "Importa inteligente",
  },
  {
    key: "从中国进口最简单、最安全的方式",
    value: "La manera más fácil y segura de importar desde China",
  },
  {
    key: "或者你可以搜索超过1亿种产品:",
    value: "o también puedes buscar entre más de 100 millones de productos:",
  },
  {
    key: "收藏(简短)",
    value: "Seguir",
  },
  {
    key: "你准备好开始导入了吗?",
    value: "¿Estás listo para empezar a importar desde China?",
  },
  {
    key: "加入我们由数千名进口商组成的社区，并开始使用Rakumart智能进口!",
    value:
      "¡Regístrate a nuestra comunidad de miles de importadores y empieza a importar inteligente con Rakumart! ",
  },
  {
    key: "按图片搜索产品(推荐)",
    value: "Busca el producto por imagen (Recomendado)",
  },
  {
    key: "在这里发布图片",
    value: "Suelta la imagen aquí",
  },
  {
    key: "我们正在寻找您的产品，并为您选择最好的供应商。",
    value:
      "Estamos buscando tu producto y seleccionando a los mejores proveedores para ti.",
  },
  {
    key: "请稍等。",
    value: "Un momento por favor...",
  },
  {
    key: "*我们只从中国进口到西班牙",
    value: "*Solo realizamos importaciones de China a España",
  },
  {
    key: "事故",
    value: "Incidencia",
  },
  {
    key: "你在哪里认识我们的?",
    value: "¿Dónde nos has conocido?",
  },
  {
    key: "你能告诉我们在哪里?",
    value: "¿Puedes decirnos dónde?",
  },
  {
    key: "登录Rakumart",
    value: "Inicia sesión en Rakumart",
  },
  {
    key: "输入您的登录凭证",
    value: "Introduce tus credenciales para iniciar sesión",
  },
  {
    key: "写你的电子邮件",
    value: "Escribe tu e-mail",
  },
  {
    key: "输入您的密码",
    value: "Escribe tu contraseña",
  },
  {
    key: "你忘记密码了吗?",
    value: "¿Has olvidado tú contraseña?",
  },
  {
    key: "保持会话启动",
    value: "Mantener la sesión iniciada",
  },
  {
    key: "你还没有得到一个帐户吗?",
    value: "¿Aún no tienes cuenta? ",
  },
  {
    key: "创建您的Rakumart帐户",
    value: "Crea tu cuenta Rakumart",
  },
  {
    key: "个人详细信息",
    value: "Detalles personales",
  },
  {
    key: "我们很高兴能在一起!",
    value: "?Estamos encantados de concerte!",
  },
  {
    key: "输入您的个人信息",
    value: "Introduce tus datos personales",
  },
  {
    key: "皇后的细节",
    value: "Detalles de la empresa",
  },
  {
    key: "输入您公司的详细信息",
    value: "Introduce los datos de tu empresa",
  },
  {
    key: "填写一个简短的问卷，以个性化您的Rakumart体验",
    value:
      "Responde un breve cuestionario para personalizar tu experiencia con Rakumart",
  },
  {
    key: "电子邮件，contraseña y telsamufo",
    value: "E-mail, contraseña y teléfono",
  },
  {
    key: "已下架",
    value: "Retirado del mercado",
  },
  {
    key: "您的公司/业务名称",
    value: "Nombre de tu empresa/negocio",
  },
  {
    key: "您的公司或企业名称",
    value: "Nombre de tu empresa/negocio",
  },
  {
    key: "网站",
    value: "Sitio web",
  },
  {
    key: "如果你",
    value: "Si tienes",
  },
  {
    key: "输入您的企业网站",
    value: "Introduce la web de tu negocio",
  },
  {
    key: "您的主要业务部门",
    value: "Sector principal de tu negeocio",
  },
  {
    key: "选择您公司的行业",
    value: "Selecciona el sector de tu empresa",
  },
  {
    key: "No fiscal CIF/NIF",
    value: "No fiscal CIF/NIF",
  },
  {
    key: "Introduce tu CIF/NIF",
    value: "Introduce tu CIF/NIF",
  },
  {
    key: "登录详细信息",
    value: "Detalles de inicio de sesión",
  },
  {
    key: "我们几乎完成了!填写您的详细信息以登录Rakumart。",
    value:
      "¡Ya casi hemos terminado! Completa tus datos para iniciar sesión en Rakumart.",
  },
  {
    key: "密码",
    value: "Contraseña",
  },
  {
    key: "输入安全密码",
    value: "Introduce una contraseña segura",
  },
  {
    key: "重新输入密码",
    value: "Vuelve a escribir una contraseña",
  },
  {
    key: "*至少使用8个字符。它包括:",
    value: "* Usa al menos 8 caracteres. Incluye una mezcla de: ",
  },
  {
    key: "大写和小写字母。",
    value: "Letras mayúsculas y minúsculas.",
  },
  {
    key: "数字",
    value: "Números",
  },
  {
    key: "特殊符号@ # $ % & * !？ _ - + = / | ~ ^ ()",
    value: "Símbolos especiales @ # $ % & * ! ? _ - + = / | ~ ^ ( )",
  },
  {
    key: "您必须接受隐私政策",
    value: "Debes aceptar la política de privacidad",
  },
  {
    key: "欢迎来到Rakumart!",
    value: "¡Bienvenido/a a Rakumart!",
  },
  {
    key: "足不出户就开始从中国进口",
    value: "Empieza a importar desde China sin salir de casa",
  },
  {
    key: "开始",
    value: "Empezar",
  },
  {
    key: "输入密码吗?包含至少8个大写、小写、数字和至少一个特殊符号的字符。",
    value:
      "Introduce una contrase?a que contenga al menos 8 caracteres con mayúsculas, minúsculas, números y almenos un símbolo especial.",
  },
  {
    key: "已下架",
    value: "Retirado del mercado",
  },
  {
    key: "免费注册",
    value: "Registrate gratis",
  },
  {
    key: "我们是你通往中国的桥梁",
    value: "Somos tu puente a China",
  },
  {
    key: "触手可及的世界上最大的时尚市场",
    value: "El mercado de moda más grande del mundo, a tu alcance",
  },
  {
    key:
      "Rakumart是B2B一体化导入平台。我们为您管理从中国采购、物流和进口的整个过程。我们广泛的合作伙伴网络为您提供了广泛的产品选择和超过100,000家供应商，我们每天与他们合作。一项旨在帮助您推动业务发展的服务。",
    value:
      "Rakumart es la plataforma B2B de importación todo en uno. Gestionamos por ti todo el proceso de compra, logística e importación de China. Nuestra amplia red de colaboradores permite que puedas acceder a una inmensa oferta de productos y más de 100.000 proveedores con los que trabajamos a diario. Un servicio pensado para ayudarte a impulsar tu negocio. ",
  },
  {
    key: "看视频",
    value: "Ver video",
  },
  {
    key: "便利从中国进口",
    value: "Facilitando la importación desde China",
  },
  {
    key: "找到你想要的一切",
    value: "Encuentra todo lo que buscas",
  },
  {
    key: "它受益于从中国进口的好处，而没有随之而来的复杂性。",
    value:
      "Aprovecha los beneficios de importar desde China sin las complicaciones que comporta. ",
  },
  {
    key: "具有经过验证的供应商的无限目录",
    value: "Catálogo infinito con proveedores verificados",
  },

  {
    key: "进入世界上最大的批发市场，发现它提供的所有机会。",
    value:
      "Accede al mercado mayorista más grande del mundo y descubre todas las oportunidades que este ofrece. ",
  },
  {
    key: "与中国直接联系",
    value: "Conexión directa con China",
  },
  {
    key:
      "在这个过程的每个阶段控制你的货物。我们在中国和西班牙的基础设施使进口的实时跟踪成为可能。",
    value:
      "Controla tu mercancía en cada etapa del proceso. Nuestra infraestructura en China y España hace posible un seguimiento en tiempo real de la importación. ",
  },
  {
    key: "个性化客户服务",
    value: "Atención al cliente personalizada",
  },
  {
    key:
      "我们的专家团队将用西班牙语回答您的所有问题，并指导您完成进口的每一步。",
    value:
      "Nuestro equipo de expertos te atenderá en español para resolver todas tus dudas y guiarte en cada paso de tu importación.",
  },
  {
    key: "定制的商业体验",
    value: "Experiencia comercial a medida",
  },
  {
    key:
      "定制您从中国进口的产品。选择您需要的产品，并根据您的具体需求定制服务。",
    value:
      "Personaliza tus importaciones desde China. Elige los productos que necesitas y adapta los servicios a tus necesidades específicas.",
  },
  {
    key: "质量和信心",
    value: "Calidad y confianza",
  },
  {
    key: "推荐的时尚供应商",
    value: "Proveedores de moda recomendados",
  },
  {
    key: "通过你的屏幕，就像你在中国一样重要！",
    value: "¡Importa como si estuvieras en China a través de tu pantalla!",
  },
  {
    key: "Streetwear",
    value: "Streetwear",
  },
  {
    key: "优化导入流程",
    value: "Optimizamos el proceso de importación",
  },
  {
    key:
      "使用Rakumart导入，并在5个简单的步骤中简化导入过程。我们负责进口，你负责你的生意。",
    value:
      "Importa con Rakumart y simplifica el proceso de importación en 5 sencillos pasos. Nosotros nos encargamos de importar, tú encárgate de tu negocio.",
  },
  {
    key: "图像搜索",
    value: "Búsqueda por imagen",
  },
  {
    key:
      "在Rakumart中，您可以通过图像搜索产品，优化您的搜索，以找到您想要进口的产品。",
    value:
      "En Rakumart puedes buscar productos por imagen, optimizando tu búsqueda para encontrar exactamente lo que deseas importar.",
  },
  {
    key: "开始新订单",
    value: "Empieza un nuevo pedido",
  },
  {
    key:
      "找到您想要导入的产品，对其进行定制，并添加其他服务。然后得到一个报价，包括从中国的运费。",
    value:
      "Encuentra el producto que quieres importar, personalízalo y añade servicios adicionales.Luego, obtén una cotización que incluye el costo del envío desde China.",
  },
  {
    key: "定制你的产品",
    value: "Personaliza tus productos",
  },
  {
    key:
      "当您开始订购时，个性化您的产品并添加额外的服务。你有一个标志吗？自定义图像，使您的产品独特。",
    value:
      "Al iniciar tu pedido, personaliza tus productos y añade servicios adicionales. ¿Tienes una marca? Personaliza la imagen para que tus productos sean únicos.",
  },
  {
    key: "货物的报价和付款",
    value: "Cotización y pago de la mercancía",
  },
  {
    key:
      "我们将在48小时内报价您的订单和运输费用。Rakumart确保安全可靠的支付，保护您的数据在每一笔交易。",
    value:
      "En 48 horas cotizaremos tu pedido y los costes de envío.Rakumart asegura un pago seguro y confiable, protegiendo tus datos en cada transacción.",
  },
  {
    key: "物流、运输和接收订单",
    value: "Logística, envío y recepción del pedido",
  },
  {
    key:
      "付款后，我们将按照您选择的运输方式发送您的订单。Rakumart控制和跟踪您的货物从中国直到收到。",
    value:
      "Tras pagar tu mercancía, te enviaremos tu pedido con el método de envío elegido.Rakumart controla y sigue tu envío desde China hasta su recepción.",
  },
  {
    key: "好进口商俱乐部",
    value: "El Club de los Buenos Importadores",
  },
  {
    key: "超过10,000个注册用户",
    value: "Más de 10.000 usuarios registrados",
  },
  {
    key: "我们的社区每天都在增长。加入它，享受与Rakumart进口的好处。",
    value:
      "Nuestra comunidad crece cada día.Únete a ella y disfruta de las ventajas de importar con Rakumart.",
  },
  {
    key:
      "“Rakumart为你提供了很多便利，你可以把所有东西都放在家门口，而不必担心任何你不知道如何管理的事情。”",
    value:
      "“Rakumart te da muchas facilidades y te lo pone todo en la puerta de tu casa sin que te tengas que preocupar por nada de lo que no sabes gestionar.”",
  },
  {
    key: "亚马逊卖家",
    value: "Vendedora de Amazon",
  },
  {
    key:
      "“你省去了很多麻烦，可以把时间花在你真正感兴趣的业务上，而不是花在官僚程序上。”",
    value:
      "“Te ahorras muchos dolores de cabeza y te permite dedicar tiempo a tu negocio que realmente es lo que interesa en vez de dedicarle tiempo a procesos burocráticos  .”",
  },
  {
    key: "“我对你们的客户服务和待遇非常满意。可取的。”",
    value:
      "“Estoy muy satisfecho con la atención al cliente y el trato que me habéis dado. Recomendable.”",
  },
  {
    key:
      "“Rakumart将整个导入过程打包在一个包中，整个过程都是西班牙语的，这使得沟通更加流畅和容易。”",
    value:
      "“Rakumart te hace todo el proceso de importación en un pack, todo el proceso es en Español y eso permite una comunicación mucho más fluida y mucho más fácil.”",
  },
  {
    key: "“它大大简化了从中国进口到这里的过程，也简化了商品的标签和包装。”",
    value:
      "“Facilita mucho el proceso de importar desde China hasta aquí también facilita el etiquetado y el empaquetado de la mercancía.”",
  },
  {
    key:
      "“他们从头到尾都在指导你。这让事情变得更容易。它让你更容易专注于你的业务。”",
    value:
      "“Te guían durante el proceso desde el inicio hasta el final. Esto facilita muchísimo las cosas. Te facilita centrarte en tu negocio.”",
  },
  {
    key: "用Rakumart推动你的业务",
    value: "Impulsa tu negocio con Rakumart",
  },
  {
    key: "现在注册，成为重要社区的一部分。",
    value:
      "Regístrate ahora y sé parte de la comunidad que importa de forma inteligente.",
  },
  {
    key: "查看Rakumart进口常见问题，并发现所有的好处和保证在您的范围内。",
    value:
      "Consulta las preguntas frecuentes sobre importar con Rakumart y descubre todos los beneficios y garantías a tu alcance.",
  },
  {
    key: "什么是Rakumart？",
    value: "¿Qué es Rakumart?",
  },
  {
    key:
      "Rakumart是一个“一体化”的进口平台，将西班牙用户与数千家中国供应商连接起来，以具有竞争力的价格提供产品。我们管理整个过程：采购，订单，质量检查，运输和售后服务，客户服务100%用西班牙语。",
    value:
      'Rakumart es una plataforma de importación "todo en uno" que conecta a usuarios esp a ñoles con miles de proveedores chinos, ofreciendo productos a precios competitivos.Gestionamos todo el proceso: compra, pedidos, inspección de calidad, envío y posventa, con un servicio de atención al cliente 100% en español.',
  },
  {
    key: "我们在哪里进口？",
    value: "¿Dónde realizamos nuestras importaciones?",
  },
  {
    key: "在Rakumart.es，我们只从中国进口到西班牙。",
    value:
      "En<a href = 'https://www.rakumart.es' target = '_blank'>Rakumart.es</a> solo realizamos importaciones desde China a España.",
  },
  {
    key: "我需要一个帐户才能下订单吗？",
    value: "¿Necesito una cuenta para hacer un pedido?",
  },
  {
    key:
      "是的，你需要一个帐户。在Rakumart.es免费注册，开始订购。有了您的帐户，您将能够访问有用的工具来管理和监控您的购买。此外，您的个人信息将被安全存储，使未来的订单更容易和快速。",
    value:
      "Sí, necesitas una cuenta.Regístrate gratis en Rakumart.es para empezar a hacer pedidos.Con tu cuenta, tendrás acceso a herramientas útiles para gestionar y supervisar tus compras.Además, tu información personal se almacenará de forma segura, facilitando futuros pedidos rápidos y sencillos.",
  },
  {
    key: "我们的服务是针对谁？",
    value: "¿A quién va dirigido nuestro servicio?",
  },
  {
    key:
      "Rakumart针对的是企业、公司和自由职业者，他们希望以具有竞争力的价格购买批发产品。我们提供个性化的关注，以满足独特的需求，并支持客户的成长和扩张。我们不提供零售服务。",
    value:
      "Rakumart está dirigido a negocios, empresas y profesionales autónomos que buscan abastecerse de productos mayoristas a precios competitivos.Ofrecemos atención personalizada para satisfacer necesidades únicas y apoyamos el crecimiento y expansión de nuestros clientes.No proporcionamos servicios de venta al por menor.",
  },
  {
    key: "Rakumart有什么优势？",
    value: "¿Qué ventajas ofrece Rakumart?",
  },
  {
    key:
      "Rakumart以具有竞争力的价格提供产品，在一次运输中从多个验证供应商购买，并优化进口成本。我们包括西班牙的物流，质量控制，海关管理和服务，所有这些都由我们在西班牙的保修支持。",
    value:
      "Rakumart ofrece productos a precios competitivos, compra a múltiples proveedores verificados en un solo envío, y optimización de costes de importación.Incluimos logística, control de calidad, gestión aduanera y atención en español, todo respaldado por nuestra garantía en España.",
  },
  {
    key: "你如何检查供应商？",
    value: "¿Cómo verificáis a los proveedores?",
  },
  {
    key:
      "在Rakumart，我们通过严格的选择过程来保证我们供应商的质量和可靠性。我们评估产品、交货时间和沟通。凭借在中国、日本、韩国和巴西的经验，我们了解全球需求，并保持可靠供应商的最新数据库。此外，我们保持持续的沟通，以满足我们的质量期望。",
    value:
      "En Rakumart, garantizamos la calidad y confiabilidad de nuestros proveedores mediante un riguroso proceso de selección.Evaluamos productos, plazos de entrega y comunicación.Con experiencia en China, Japón, Corea y Brasil, entendemos las necesidades globales y mantenemos una base de datos actualizada de proveedores confiables.Además, mantenemos una comunicación constante para cumplir con nuestras expectativas de calidad.",
  },
  {
    key: "在Rakumart注册",
    value: "Registrate en Rakumart",
  },
  {
    key: "输入您的电子邮件开始在Rakumart Espana注册。",
    value: "Introduce tu e-mail para empezar el registro en Rakumart España.",
  },
  {
    key: "你有一个帐户吗？",
    value: "¿Ya tienes una cuenta? ",
  },
  {
    key: "¡多谢了!",
    value: "¡Muchas gracias! ",
  },
  {
    key: "表单已成功发送。现在我们可以根据您的需要定制您的导入体验。",
    value:
      "El formulario se ha enviado con éxito. Ahora podremos personalizar tu experiencia de importación a tus necesidades.",
  },
  {
    key: "去Rakumart",
    value: "Ir a Rakumart",
  },
  {
    key: "退后一步",
    value: "Volver atrás",
  },
  {
    key: "已验证的供应商数千",
    value: "Miles de proveedores verificados",
  },
  {
    key: "专家团队可让您接触到市场上最好的供应商",
    value:
      "Un equipo experto que te da acceso a los mejores proveedores del mercado",
  },
  {
    key: "从中国，不离开你的办公室",
    value: "De China, sin moverte de tu oficina",
  },
  {
    key: "与中国的贸易民主化",
    value: "Democratizando el comercio con China",
  },
  {
    key: "从中国进口的好处，但没有它的复杂性。",
    value:
      "Los beneficios de importar desde China, pero sin sus complicaciones.",
  },
  {
    key:
      "在Rakumart，你可以找到数以百万计的产品，从亚洲巨人最好的供应商的手，最好的质量和价格保证！",
    value:
      "En Rakumart podrás encontrar millones de productos, de la mano de los mejores proveedores del gigante asiático ?Mejor calidad y precio garantizado!",
  },
  {
    key:
      "没有更多的头痛！我们负责从工厂到您家门口的整个物流过程。把时间花在真正重要的事情上，你的业务。",
    value:
      "¡No más dolores de cabeza! Nos encargamos de todo el proceso logístico, desde la fábrica hasta tu puerta. Dedícale tiempo a lo que de verdad importa, tu negocio.",
  },
  {
    key: "单一平台上的全部流程",
    value: "Todo el proceso desde una misma plataforma",
  },
  {
    key:
      "从一个地方管理你的商品。所有在一起！无需离开我们的平台即可享受导入过程。",
    value:
      "Gestiona tu mercancía desde un mismo sitio. ¡Todo en uno! Disfruta del proceso de importación sin salir de nuestra plataforma.",
  },
  {
    key: "的未来",
    value: "Proximamente",
  },
  {
    key: "优化产品搜索",
    value: "Optimizamos la búsqueda de productos",
  },
  {
    key: "简化流程，找到你想要的东西",
    value: "Agiliza el proceso y encuentra lo que quieres",
  },
  {
    key:
      "通过我们的图片或链接搜索1688，节省您的时间专注于您的业务。你是我们的首要任务。",
    value:
      "Con nuestra búsqueda por imagen o enlace de 1688, ahorra tiempo para enfocarte en tu negocio.  Tú eres nuestra prioridad.",
  },
  {
    key: "无限目录",
    value: "Catálogo infinito",
  },
  {
    key: "数以百万计的认证产品",
    value: "Millones de productos certificados",
  },
  {
    key:
      "找到你的下一个商业机会！通过我们与1688.com的联盟，访问世界上最大的制造商目录。和一切，从你的处置！",
    value:
      "¡Encuentra tu próxima oportunidad de negocio! Accede al mayor catálogo de fabricantes del mundo gracias a nuestra alianza con 1688.com. ¡Y todo, desde tu dispositvo! ",
  },
  {
    key:
      "我们的社区每天都在增长。加入更大的进口商社区，享受与Rakumart进口的好处。",
    value:
      "Nuestra comunidad crece cada día. Únete a la comunidad de importadores más grande y disfruta de las ventajas de importar con Rakumart.",
  },
  {
    key: "现在注册，成为重要社区的一部分。",
    value:
      "Regístrate ahora y sé parte de la comunidad que importa de forma inteligente. ",
  },
  {
    key: "购物车添加失败",
    value: "Artículo a?adido al carrito falló",
  },
  {
    key: "增加",
    value: "Aumento",
  },
  {
    key: "Rakumart的后勤和运输",
    value: "Logística y envío con Rakumart",
  },
  {
    key: "物流价格",
    value: "Precios de logística",
  },
  {
    key: "扫描手机上的QR码，开始聊天",
    value: "Escanea el código QR en tu teléfono para empezar a chatear",
  },
  {
    key: "发起聊天",
    value: "Chatear con WhatsApp",
  },
  {
    key: "下载订购交货",
    value: "Descargar Pedido de entrega",
  },
  {
    key: "国际运输费用",
    value: "Costos de transporte internacional",
  },
  {
    key: "汇率",
    value: "Tasa de cambio",
  },
  {
    key: "总费用",
    value: "Costo total",
  },
  {
    key: "进口商信息",
    value: "Información del importador",
  },
  {
    key: "信息接收者",
    value: "Recipiente de información",
  },
  {
    key: "每包数量",
    value: "Cantidad por empaque",
  },
  {
    key: "包装清单详细信息",
    value: "Detalles de la lista de empaque",
  },
  {
    key: "警告：点击这里展开或收缩所有框",
    value: "Aviso: Haga clic aquí para expandir o contraer todas las cajas",
  },
  {
    key: "长度",
    value: "Longitud",
  },
  {
    key: "宽度",
    value: "Anchura",
  },
  {
    key: "高度",
    value: "Altura",
  },
  {
    key: "单一产品成本估算",
    value: "Costo estimado de un solo producto【Door to Door】",
  },
  {
    key: "内容订购详细信息",
    value: "Contenido de Detalles del pedido",
  },
  {
    key: "订购内容",
    value: "Contenido de Pedido de entrega",
  },
  {
    key: "时间",
    value: "Tiempo",
  },
  {
    key: "国家后勤",
    value: "Estado logístico",
  },
  {
    key: "数量2",
    value: "Cajas",
  },
  {
    key: "确认付款",
    value: "Pago de confirmación",
  },
  {
    key: "确定签收",
    value: "Confirmar recibo",
  },
  {
    key: "观察",
    value: "Observación",
  },
  {
    key: "代理响应",
    value: "Respuesta del agente",
  },
  {
    key: "在中国的总成本",
    value: "Costo total en China",
  },
];

let fanyi = (value) => {
  if (store.state.fanyi == true) {
    return value;
  }
  // if (false && ['http://localhost:8080', 'http://localhost:8081', 'http://localhost:8082'].includes(location.origin)) {
  //     return value;
  // }

  for (let i = 0; i < langs.length; i++) {
    if (langs[i].key == value) {
      return langs[i].value;
    }
  }
  return value;
};
export default fanyi;
