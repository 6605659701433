<template>
  <!-- 登录页面显示 -->
  <div v-if="!isDia" :class="{ loginDiaCss: isDia }">
    <img
      class="backgroundImage"
      v-if="!isDia"
      src="../../assets/login/bg.jpg"
      alt=""
    />
    <div class="loginBody">
      <div class="right">
        <div class="login">
          <div class="logo">
            <a href="javascript:;" @click="$fun.routerToPage('/')">
              <img src="../../assets/logo2.png" alt="" />
            </a>
          </div>
          <el-form
            :model="loginData"
            :rules="loginRules"
            ref="loginForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="login_name">
              <div class="fromName">
                <!-- 用户名 -->
                <div class="placeholder">{{ $fanyi("电子邮件") }}</div>
              </div>
              <el-input
                v-model="loginData.login_name"
                :placeholder="$fanyi('电子邮件') + '/' + $fanyi('手机')"
              ></el-input>
            </el-form-item>

            <el-form-item prop="password">
              <div class="fromName">
                <!-- 密码 -->
                <div class="placeholder">{{ $fanyi("密码") }}</div>
              </div>
              <el-input v-model="loginData.password" type="password"></el-input>
            </el-form-item>
          </el-form>
          <div class="btn">
            <!-- 登录 -->
            <button @click="login">Acceder</button>
          </div>
          <!-- 忘记密码 -->
          <div class="forgetPassword">
            <div class="fl">
              <el-checkbox v-model="Save_the_password"></el-checkbox>
              <span>{{ $fanyi("记住密码") }}</span>
            </div>
            <div class="fr">
              <span
                class="wangJiMiMa"
                @click="$fun.routerToPage('/ForgetThePassword')"
                >{{ $fanyi("忘记密码") }}
              </span>
            </div>
          </div>
          <!-- 注册 -->
          <div class="register">
            <div class="fl zhuCe">
              <a href="javascript:;" @click="$fun.routerToPage('/register')">{{
                $fanyi("新会员注册")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 登录弹窗显示 -->
  <loginDia ref="loginDia" v-else />
</template>

<script>
import loginDia from "./components/loginDia.vue";
export default {
  props: {
    isDia: {
      //是否是在弹窗里显示
      default: false,
    },
  },
  data() {
    return {
      Save_the_password: false,
      loginData: {
        login_name: localStorage.getItem("username")
          ? localStorage.getItem("username")
          : "",

        password: localStorage.getItem("password")
          ? localStorage.getItem("password")
          : "",
      },
      loginRules: {
        login_name: [
          {
            required: true,
            message: this.$fanyi("请输入邮箱/手机号"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$fanyi("请输入密码"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    loginDia,
  },
  created() {
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.login(); // 登录方法名
        return false;
      }
    };
    localStorage.getItem("keep") == 1
      ? (this.Save_the_password = true)
      : (this.Save_the_password = false);
  },
  methods: {
    login() {
      if (this.isDia) {
        this.$refs.loginDia.$refs.loginForm.validate((valid) => {
          if (valid) {
            // 记住密码
            if (this.Save_the_password == true) {
              localStorage.setItem("username", this.loginData.login_name);
              localStorage.setItem("password", this.loginData.password);
              localStorage.setItem("keep", 1);
            } else {
              localStorage.removeItem("username");
              localStorage.removeItem("password");
              localStorage.removeItem("keep");
            }
            this.$api
              .logins({
                login_account: this.loginData.login_name,
                password: this.loginData.password,
                client_tool: 1,
              })
              .then((res) => {
                if (!res.success)
                  return this.$message.error(
                    this.$fanyi("邮箱、手机号或密码输入错误")
                  );

                localStorage.setItem("user_token", res.data.user_token);
                this.$api.EuropegetUserInfo().then((res) => {
                  this.$store.commit("userData", res.data);
                  this.$forceUpdate();
                  if (typeof this.$store.state.showLoginDialog == "function") {
                    this.$store.state.showLoginDialog();
                  }
                  // 关闭弹窗
                  this.$store.commit("getshowLoginDialog", false);

                  // this.$router.go(0);
                  // this.$fun.routerToPage(
                  //   this.$store.state.activePage == ""
                  //     ? "/"
                  //     : this.$store.state.activePage
                  // );
                });
              });
          }
        });
      } else {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            // 记住密码
            if (this.Save_the_password == true) {
              localStorage.setItem("username", this.loginData.login_name);
              localStorage.setItem("password", this.loginData.password);
              localStorage.setItem("keep", 1);
            } else {
              localStorage.removeItem("username");
              localStorage.removeItem("password");
              localStorage.removeItem("keep");
            }
            this.$api
              .logins({
                login_account: this.loginData.login_name,
                password: this.loginData.password,
                client_tool: 1,
              })
              .then((res) => {
                if (!res.success)
                  return this.$message.error(
                    this.$fanyi("邮箱、手机号或密码输入错误")
                  );

                localStorage.setItem("user_token", res.data.user_token);
                this.$api.EuropegetUserInfo().then((res) => {
                  this.$store.commit("userData", res.data);
                  this.$fun.routerToPage(
                    this.$store.state.activePage == ""
                      ? "/"
                      : this.$store.state.activePage
                  );
                });
              });
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin.scss";
.loginPage {
  width: 100%;
  min-width: 1400px;
  //   max-width: 1920px;

  margin: 0 auto;
  height: 100%;
  min-height: 744px;
  background-color: #fff;
  display: flex;
  .left {
    flex: 1.5;
    min-width: 816px;
    height: 100%;

    max-width: 1120px;
    background-color: #000;
    position: relative;
    .img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 100%;
      //   height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      color: #fff;
      position: absolute;
      top: 20%;
      left: 10%;
      font-size: 30px;
      p {
        font-size: 48px;
        font-weight: 700;
      }
    }
  }
  .right {
    flex: 1;

    position: relative;
    .login {
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translate(0, -50%);
      width: 370px;
    }
  }
}
/deep/.el-input__inner {
  height: 48px;
}
/deep/ .el-form-item {
  margin-bottom: 20px;
}
/deep/.el-form-item__error {
}
.backgroundImage {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.loginBody {
  .right {
    height: 100%;
    flex: 1;
    position: relative;
    .login {
      border-radius: 6px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 480px;
      height: 588px;
      background-color: white;
      padding: 60px 50px 0px;
      .logo {
        text-align: center;
        margin-bottom: 43px;
        img {
          width: 218px;
          height: 56px;
        }
      }

      .btn {
        margin-bottom: 43px;
        button {
          width: 370px;
          height: 54px;
          cursor: pointer;
          background-color: $subjectColor;
          color: #fff;
          border-radius: 4px;
          font-size: 18px;
        }
      }
      .forgetPassword {
        line-height: 14px;
        height: 14px;
        color: #999;
        font-size: 12px;
        margin-bottom: 46px;
        /deep/.el-checkbox {
          margin: 0 15px 0 0px;

          .el-checkbox__input {
            height: 16px;
          }
        }
        span {
          cursor: pointer;
          vertical-align: middle;
        }
        .wangJiMiMa {
          color: #1a289d;
        }
      }
      .register {
        font-size: 12px;
        display: flex;
        justify-content: center;
        a {
          color: $subjectColor;
          text-decoration: underline;
          white-space: nowrap;
        }
      }
    }
  }
}
.fromName {
  .placeholder {
    height: 12px;
    line-height: 12px;
    margin-bottom: 15px;
  }
}
</style>
